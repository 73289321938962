import React from "react"

// External Libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Label, Spinner } from "reactstrap"

// Helpers
import GeneralTools, { getUrlAWSManagerFile } from "../../helpers/GeneralTools"
import { getPriceOptional } from "../../helpers/OrderTools"

// Components
import NumberControl from "../NumberControl/NumberControl"
import CheckSelector from "../CheckSelector/CheckSelector"
import RadioButton from "../RadioButton/RadioButton"

// Style / Images
import noImage from "../../assets/img/builder/no-image.png"
import "./OptionCollapseItem.scss"

export default function OptionCollapseItem(props) {
  const {
    item,
    type,
    size,
    selectedFlavour,
    selectedFlavourNumber,
    selectedComplement,
    optionType,
    onClick,
    onChangeAmount,
    onChangeIngredients,
    selectedOptionals,
    isCombo = false,
  } = props

  const getItemPrice = () => {
    let itemValue = 0
    if (isCombo) {
      switch (type) {
        case "flavour": {
          itemValue = item.val_price
          break
        }
        case "beverage": {
          itemValue = item.val_price
          break
        }
        case "adicional": {
          break
        }
        case "complement": {
          if (!!item.sizes && !!size) {
            itemValue = item.sizes.find((eachSize) => eachSize.uuid_size === size.uuid_size).additional_size.val_price
          } else if (!!item.size) {
            if (!!item.size.complement_size) itemValue = item.size.complement_size.val_price
            if (!!item.size.additional_size) itemValue = item.size.additional_size.val_price
          } else {
            if (!!item.val_price) itemValue = item.val_price
          }
          if (!!size) {
            if (
              `${item.nm_additional}`.toLowerCase().indexOf("sem", 0) === -1 && // true
              `${item.nm_additional}`.toLowerCase().indexOf("normal", 0) === -1 && // false
              `${item.nm_additional}`.toLowerCase().indexOf("tradicional", 0) === -1 // false
            ) {
              itemValue = itemValue + (!!size.complement_size ? size.complement_size.val_price : 0)
            } else {
              itemValue = 0
            }
          }
          break
        }
        case "size": {
          return `${GeneralTools.formatMoney(item.val_price)}`
        }
        case "ingredient": {
          itemValue = item.size.ingredient_size.val_price
          break
        }
        case "optional": {
          if (!!item.sizes) {
            itemValue = item.sizes.find((eachSize) => eachSize.uuid_size === size.uuid_size)?.ingredient_size?.val_price / selectedFlavourNumber || 0
          } else {
            if (!!item.size.additional_size) itemValue = item.size.additional_size.val_price / selectedFlavourNumber
            if (!!item.size.ingredient_size) itemValue = item.size.ingredient_size.val_price / selectedFlavourNumber
          }
          break
        }
        case "group": {
          itemValue = item.title
          break
        }
        default: {
          itemValue = item.size.additional_size.val_price
          break
        }
      }

      if (itemValue > 0) {
        return `+ ${GeneralTools.formatMoney(itemValue)}`
      }
    } else {
      switch (type) {
        case "flavour": {
          if (!!item.size) {
            //Caso tenha uma propriedade size dentro do item
          } else if (!!item.sizes) {
            //Caso tenha um array sizes dentro do item
            if (!!size) {
              const selectedFlavourSize = item.sizes.find(({ uuid_size }) => uuid_size === size?.uuid_size)
              if (!!selectedFlavourSize) {
                if (!!selectedFlavourSize.flavour_size) {
                  itemValue = selectedFlavourSize.flavour_size.val_price
                } else {
                  itemValue = selectedFlavourSize.val_price
                }
              }
            }
          }

          break
        }
        case "beverage": {
          itemValue = item.val_price
          break
        }
        case "adicional": {
          break
        }
        case "complement": {
          if (!!item.sizes && !!size) {
            itemValue = item.sizes.find((eachSize) => eachSize.uuid_size === size.uuid_size).additional_size.val_price
          } else if (!!item.size) {
            if (!!item.size.complement_size) itemValue = item.size.complement_size.val_price
            if (!!item.size.additional_size) itemValue = item.size.additional_size.val_price
          } else {
            if (!!item.val_price) itemValue = item.val_price
          }
          if (!!size) {
            if (
              `${item.nm_additional}`.toLowerCase().indexOf("sem", 0) === -1 && // true
              `${item.nm_additional}`.toLowerCase().indexOf("normal", 0) === -1 && // false
              `${item.nm_additional}`.toLowerCase().indexOf("tradicional", 0) === -1 // false
            ) {
              itemValue = itemValue + (!!size.complement_size ? size.complement_size.val_price : 0)
            } else {
              itemValue = 0
            }
          }
          break
        }
        case "size": {
          return `${GeneralTools.formatMoney(item.val_price)}`
        }
        case "ingredient": {
          itemValue = item.size.ingredient_size.val_price
          break
        }
        case "optional": {
          const optionalSize = item.sizes.find(({ id_size }) => id_size === size.id_size)
          if (!!optionalSize) {
            itemValue = getPriceOptional(size, item, optionalSize, selectedFlavourNumber, selectedFlavourNumber)
          }
          break
        }
        case "group": {
          itemValue = item.title
          break
        }
        default: {
          itemValue = item.size.additional_size.val_price
          break
        }
      }

      if (itemValue > 0) {
        return `+ ${GeneralTools.formatMoney(itemValue)}`
      }
    }
    return itemValue === 0 ? "Grátis" : itemValue
  }

  const getItemLabel = () => {
    switch (type) {
      case "flavour": {
        return item.lb_flavour || item.nm_flavour
      }
      case "beverage": {
        return item.lb_beverage || item.nm_beverage
      }
      case "size": {
        return item.lb_size || item.nm_size
      }
      case "ingredient": {
        return item.nm_ingredient || "Nome não disponível"
      }
      case "optional": {
        return item.nm_ingredient || "Nome não disponível"
      }
      case "complement": {
        return !!item.lb_additional ? item.lb_additional : item.nm_additional || "Nome não disponível"
      }
      // case "group": {
      //   return "teste"
      // }
      default: {
        return item.lb_additional !== null ? item.lb_additional : item.nm_additional
        // return item.complement_additional.lb_additional !== null ? item.complement_additional.lb_additional : item.nm_additional
      }
    }
  }

  const getLimitOption = () => {
    let limitValue = 1

    switch (type) {
      case "flavour": {
        return !!size ? size.qty_max_flavours : 1
      }
      case "ingredient": {
        limitValue = !!item.size ? item.size.ingredient_size.qty_max_allowed : 1
        break
      }
      case "optional": {
        const selectedFlavourSize = !!selectedFlavour.size
          ? selectedFlavour.size
          : selectedFlavour.sizes.find(({ id_size }) => id_size === size.id_size)
        if (!!selectedFlavourSize) {
          limitValue = selectedFlavourSize.qty_optionals || selectedFlavourSize?.flavour_size?.qty_optionals
        }
        break
      }
      case "complement": {
        const complementSize = !!selectedComplement.size
          ? selectedComplement.size
          : selectedComplement.sizes.find(({ id_size }) => id_size === size.id_size)
        if (!!complementSize) {
          limitValue = complementSize.complement_size.qty_max_allowed || 1
        }
        break
      }
      default: {
        break
      }
    }
    return limitValue
  }

  const getMaxOptionsByType = () => {
    switch (type) {
      case "flavour":
        return size.qty_max_flavours

      case "beverage":
        return size.qty_max_beverages
      case "complement":
        const additionalSize = !!item.size ? item.size : item.sizes.find(({ id_size }) => id_size === size.id_size)
        if (!!additionalSize) {
          return additionalSize.additional_size.qty_max_allowed
        }
        return 1
      case "optional":
        const optionalSize = item?.sizes?.find(({ id_size }) => id_size === size.id_size)
        if (!!optionalSize) {
          return optionalSize.ingredient_size.qty_max_allowed
        }
        return 1
      default:
        return size.qty_max_complements
    }
  }

  return (
    <div className={`collapse-card-item ${["optional", "ingredient"].includes(type) ? "ingredient-type" : ""}`}>
      <div className="collapse-card-item--content">
        {!["optional", "ingredient", "group"].includes(type) ? (
          <div className="collapse-card-item--content--left">
            <img
              src={getUrlAWSManagerFile(item?.url_main_image || item?.url_image, noImage)}
              alt={!!item.lb_flavour ? item.lb_flavour : item.nm_flavour}
            />
          </div>
        ) : (
          ""
        )}
        <div className="collapse-card-item--content--center">
          <h5 className={`collapse-card-item--content--center--text ${type === "ingredient" && item.selected ? "ingredient-removed" : ""}`}>
            {getItemLabel()}
          </h5>
          {(() => {
            switch (type) {
              case "flavour": {
                if (!!item.ds_flavour) {
                  return <Label className={`collapse-card-item--content--center--text--description`}>{item.ds_flavour}</Label>
                }
                break
              }
              default: {
                break
              }
            }
          })()}
          {!["ingredient", "optionals"].includes(type) && <p className="card-price">{getItemPrice()}</p>}
          {/*Rotina para remover um ingrediente ou opcional caso seja sabor*/}
          {type === "flavour" && item?.selected ? (
            <div className="collapse-card-item--content--center--ingredients">
              <span
                onClick={() => {
                  if (!item.loadingOptional && !item.loadingIngredient) {
                    onChangeIngredients(item)
                  }
                }}
              >
                {!!item.loadingOptional || !!item.loadingIngredient ? (
                  <Spinner
                    color="secondary"
                    style={{
                      height: "0.8rem",
                      width: "0.8rem",
                    }}
                  />
                ) : (
                  <FontAwesomeIcon icon={"circle-info"} />
                )}
                <p className={"collapse-card-item--content--center--ingredients--desc"}>Alterar ingredientes</p>
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="collapse-card-item--right">
          {(() => {
            switch (optionType) {
              case "selector": {
                return (
                  <CheckSelector
                    selected={item.selected}
                    onClick={() => {
                      onClick(item)
                    }}
                  />
                )
              }
              case "radio": {
                return <RadioButton checked={item.selected} onClick={() => onClick(item)} />
              }
              case "numbercontrol": {
                return (
                  <NumberControl
                    selectedOptionals={selectedOptionals}
                    maxOptions={getMaxOptionsByType()} //Quantidade máxima daquele ingrediente/opcional/sabor/adicional
                    number={!!item.amount ? item.amount : 0}
                    onChangeNumber={(amount, direction) => onChangeAmount(amount, direction)}
                    maximum={true}
                    minium={0}
                    limit={getLimitOption()} //Limite máximo de ingrediente/opcional/sabor/complemento
                    direction="horizontal"
                  />
                )
              }
              default: {
                break
              }
            }
          })()}
        </div>
      </div>

      <div className="collapse-card-item--divider" />
    </div>
  )
}
