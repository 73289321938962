import { useState, useContext, useEffect, useRef } from "react"
import ReactPixel from "react-facebook-pixel"
//External Libs
import Chart from "react-apexcharts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, CardBody, CardImg, CardText, CardTitle, Label, Modal, ModalBody, ModalFooter, Button, Spinner } from "reactstrap"
//Helpers
import GeneralTools, { formatBoolean, isAppleDevice, onMessageDialog, getUrlAWSManagerFile } from "../../helpers/GeneralTools"
import { getLocalItem, setLocalItem } from "../../helpers/StorageTools"
import { getTotalFlavours, getTotalComplements } from "../../helpers/OrderTools"
//Components
import Message from "../../components/Message"
import CollapseItem from "../../components/CollapseItem"
import ModalFlavours from "./ModalFlavours"
import ModalAdditional from "./ModalAdditional"
import ModalIngredients from "./ModalIngredients"
import Skeleton from "react-loading-skeleton"

//Contexts
import OrderContext from "../../contexts/OrderContext"
import CompanyContext from "../../contexts/CompanyContext"
//Controllers
import ComboController from "../../controllers/ComboController"
import ProductController from "../../controllers/ProductController"
import FlavourController from "../../controllers/FlavourController"
import noImage from "../../assets/img/builder/no-image.png"
import noFlavour from "../../assets/img/builder/no-flavour.png"
import pizzaStandard from "../../assets/img/builder/pizza-standard.png"
import cursor from "../../assets/img/icons/cursor.png"
import "./ModalBuilder.scss"

const ModalBuilder = ({ status, type, content, onChangeOrder, onClose }) => {
  const optionsChart = {
    chart: {
      type: "pie",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          onClickSlice(config)
        },
      },
    },
    theme: {
      mode: "light",
    },
    labels: ["Selecione um Sabor"],
    tooltip: {
      enabled: true,
      custom: ({ seriesIndex, w }) => {
        return `<div class="legend"><span>${w.globals.labels[seriesIndex]}</span></div>`
      },
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: "12px",
      },
      items: {
        display: "flex",
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
    fill: {
      type: "image",
      opacity: 1,
      image: {
        src: [pizzaStandard],
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: ["#623a2f"],
      width: 2,
      dashArray: 2,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 500,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    legend: {
      show: false,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "right",
      horizontalAlign: "center",
      floating: false,
      fontSize: "28px",
      fontWeight: 400,
      offsetY: 50,
      labels: {
        useSeriesColors: false,
      },
    },
  }
  const { company } = useContext(CompanyContext)
  const { currentOrderCombo, setCurrentOrderCombo } = useContext(OrderContext)
  const [loading, setLoading] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [loadingSizes, setLoadingSizes] = useState(false)
  const [loadingFlavours, setLoadingFlavours] = useState(false)
  const [loadingComplements, setLoadingComplements] = useState(false)

  const [graph, setGraph] = useState(false)

  const [combo, setCombo] = useState(null)
  const [product, setProduct] = useState(null)

  const [modalIngredients, setModalIngredients] = useState({ status: false, content: null })
  const [modalFlavours, setModalFlavours] = useState({
    status: false,
    content: null,
    onClick: null,
    nro_item: null,
    comboProductId: null,
  })
  const [modalAdditional, setModalAdditional] = useState({ state: false, content: null })

  const [selectedCombo, setSelectedCombo] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [validations, setValidations] = useState([])
  const [selectedFlavourNumber, setSelectedFlavourNumber] = useState(0)
  const [selectedComboFlavourNumber, setSelectedComboFlavourNumber] = useState([])
  const [indexChart, setIndexChart] = useState(null)
  const [isDefaultCard, setIsDefaulCard] = useState(false)

  const productByFlavourRef = useRef({ sizes: [], flavours: [], complements: [] })
  const selectedProductRef = useRef(null)
  const selectedComboRef = useRef(null)
  const loadingOptionals = useRef({})
  const loadingIngredients = useRef({})

  useEffect(() => {
    if (!!content) {
      const { uuid_product = null, size = {} } = content
      // let initialValidations = []
      switch (type) {
        case "combo":
          selectedComboRef.current = null
          setLoading(true)
          setIsDefaulCard(false)

          const newCurrentOrderCombo = currentOrderCombo + 1
          setCurrentOrderCombo(newCurrentOrderCombo)
          const newCombo = {
            ...content.combo,
            finished: false,
            nro_combo: newCurrentOrderCombo,
          }

          //Método para carregar os itens do combo.
          getComboProducts(newCombo).then()

          // setLoading(false)
          break

        case "size":
          setLoading(true)
          setIsDefaulCard(false)

          const { nm_product } = content

          const productBySizeStorage = getLocalItem(`product-${uuid_product}${size.uuid_size}`, true)
          const selectedProductBySizeStorage = getLocalItem(`selected-${uuid_product}${size.uuid_size}`, true)

          if (productBySizeStorage) {
            setProduct(productBySizeStorage)
            setSelectedProduct(selectedProductBySizeStorage)
            selectedProductRef.current = selectedProductBySizeStorage
            setLoading(false)
          } else {
            setLoading(true)
          }

          setLoadingProducts(true)
          ProductController.fetchOne(uuid_product)
            .then((responseProduct) => {
              if (responseProduct.status === 200) {
                const {
                  id_product,
                  nm_product,
                  uuid_product,
                  company_association: { ds_product },
                } = responseProduct.data

                let productBySize = { ...responseProduct.data, sizes: [], flavours: [], complements: [] }
                let selectedProductBySize = {
                  id_product,
                  uuid_product,
                  nm_product,
                  ds_product,
                  amount: 1,
                  size: {},
                  flavours: [],
                  complements: [],
                }

                //Buscando os tamanhos disponíveis para o produto
                setLoadingSizes(true)
                getProductSizes(uuid_product)
                  .then((sizes) => {
                    productBySize = { ...productBySize, sizes }
                    selectedProductBySize = {
                      ...selectedProductBySize,
                      size: { ...size, show: sizes.length > 1 },
                    }

                    setProduct(productBySize)
                    setSelectedProduct(selectedProductBySize)
                    selectedProductRef.current = selectedProductBySize

                    // setValidations(initialValidations)

                    setLocalItem(`product-${uuid_product}${size.uuid_size}`, productBySize, true)
                    setLocalItem(`selected-${uuid_product}${size.uuid_size}`, selectedProductBySize, true)

                    //Buscando os complementos disponíveis para o produto
                    setLoadingComplements(true)
                    getProductComplements(uuid_product)
                      .then((complements) => {
                        productBySize = { ...productBySize, complements }
                        selectedProductBySize = { ...selectedProductBySize, complements }

                        setProduct(productBySize)
                        setLocalItem(`product-${uuid_product}${size.uuid_size}`, productBySize, true)
                      })
                      .finally(() => {
                        setLoadingComplements(false)
                      })
                  })
                  .finally(() => {
                    setLoadingSizes(false)
                  })

                if (productBySizeStorage) {
                  const { sizes, flavours, complements } = productBySizeStorage
                  productBySize = { ...productBySize, sizes, flavours, complements }
                  setProduct(productBySize)
                } else {
                  setProduct(productBySize)
                }

                // Caso o produto seja por tamanho e não seja pizza
                // É necessário buscar os sabores deste sabor
                if (`${productBySize.nm_product}`.toLowerCase() !== "pizza") {
                  setLoadingFlavours(true)
                  ProductController.fetchFlavours({
                    uuid_product,
                    withSizes: true,
                    withIngredients: false,
                  })
                    .then((responseFlavours) => {
                      let { flavours } = responseFlavours.data
                      const newFlavours = flavours
                        .filter(({ product_flavour: { st_website } }) => st_website)
                        .map((flavour) => {
                          const { id_flavour, nm_flavour, product_flavour, uuid_flavour, sizes } = flavour
                          return {
                            id_flavour,
                            nm_flavour,
                            uuid_flavour,
                            ...product_flavour,
                            sizes: sizes.filter(({ flavour_size: { st_website } }) => st_website),
                          }
                        })

                      productBySize = { ...productBySize, flavours: newFlavours }

                      setProduct(productBySize)
                      setSelectedProduct(selectedProductBySize)
                      selectedProductRef.current = selectedProductBySize

                      setLocalItem(`product-${uuid_product}${size.uuid_size}`, productBySize, true)
                      setLocalItem(`selected-${uuid_product}${size.uuid_size}`, selectedProductBySize, true)
                    })
                    .finally(() => {
                      setLoadingFlavours(false)
                    })
                }

                if (selectedProductBySizeStorage) {
                  selectedProductBySize = { ...selectedProductBySize, size: selectedProductBySizeStorage.size }
                  setSelectedProduct(selectedProductBySize)
                  selectedProductRef.current = selectedProductBySize
                } else {
                  setSelectedProduct(selectedProductBySize)
                  selectedProductRef.current = selectedProductBySize
                }

                setLocalItem(`product-${uuid_product}${size.uuid_size}`, productBySize, true)
                setLocalItem(`selected-${uuid_product}${size.uuid_size}`, selectedProductBySize, true)
              }
            })
            .finally(() => {
              setLoading(false)
              setLoadingProducts(false)
            })

          const graphStatus = `${nm_product}`.toLowerCase().indexOf("pizza") !== -1
          setGraph(graphStatus)
          if (graphStatus) {
            setSelectedFlavourNumber(size.qty_max_flavours - 1)
          }

          break

        case "flavour":
          setLoading(true)
          setIsDefaulCard(false)
          const { flavour } = content
          //Busca os dados que estão no LocalStorage

          let productByFlavourStorage = getLocalItem(`product-${uuid_product}${flavour.uuid_flavour}`, true)
          let selectedProductByFlavourStorage = getLocalItem(`selected-${uuid_product}${flavour.uuid_flavour}`, true)

          // Caso exista os dados no localStorage
          // seta o produto como o que estava na sessão para então iniciar a carregar os demais dados.
          if (productByFlavourStorage) {
            setProduct(productByFlavourStorage)
            setSelectedProduct(selectedProductByFlavourStorage)
            selectedProductRef.current = selectedProductByFlavourStorage
            setLoading(false)
          } else {
            setLoading(true)
          }

          setLoadingProducts(true)
          ProductController.fetchOne(uuid_product)
            .then((responseProduct) => {
              if (responseProduct.status === 200) {
                const {
                  id_product,
                  nm_product,
                  uuid_product,
                  company_association: { ds_product },
                } = responseProduct.data

                // let productByFlavour = { ...responseProduct.data, sizes: [], flavours: [], complements: [] }
                productByFlavourRef.current = { ...responseProduct.data, sizes: [], flavours: [], complements: [] }
                let selectedProductByFlavour = {
                  id_product,
                  uuid_product,
                  nm_product,
                  ds_product,
                  amount: 1,
                  size,
                  flavours: [{ ...flavour, ingredients: [], optionals: [], restrictions: [], additions: [] }],
                  complements: [],
                }

                // Busca os dados do SABOR selecionado, necessário para saber os valores de acordo com o tamanho do produto.
                setLoadingFlavours(true)

                Promise.all([
                  ProductController.fetchOneFlavour({
                    productUid: uuid_product,
                    flavourUid: flavour.uuid_flavour,
                    withIngredients: true,
                    withOptionals: false,
                    withUtensilsSizes: false,
                  }),
                  ProductController.fetchOneFlavour({
                    productUid: uuid_product,
                    flavourUid: flavour.uuid_flavour,
                    withIngredients: false,
                    withOptionals: true,
                    withUtensilsSizes: false,
                  }),
                ])
                  .then(([responseFlavourIngredients, responseFlavourOptionals]) => {
                    if (responseFlavourIngredients.status === 200 && responseFlavourOptionals.status === 200) {
                      let { id_flavour, ingredients, nm_flavour, product_association, sizes, uuid_flavour } = responseFlavourIngredients.data
                      let { optionals } = responseFlavourOptionals.data
                      sizes = sizes
                        .filter(({ flavour_size }) => flavour_size.st_website)
                        .map((size) => {
                          const { flavour_size, id_size, nm_size, uuid_size, product_association } = size
                          return { ...flavour_size, ...product_association, id_size, nm_size, uuid_size }
                        })

                      selectedProductByFlavour = {
                        ...selectedProductByFlavour,
                        flavours: [
                          {
                            id_flavour,
                            uuid_flavour,
                            nm_flavour,
                            sizes,
                            ingredients,
                            restrictions: [],
                            optionals,
                            additions: [],
                            ...product_association,
                          },
                        ],
                      }

                      // setProduct(productByFlavour)
                      setSelectedProduct(selectedProductByFlavour)
                      selectedProductRef.current = selectedProductByFlavour
                      // setLocalItem(`product-${uuid_product}${flavour.uuid_flavour}`, productByFlavour, true)
                      setLocalItem(`selected-${uuid_product}${flavour.uuid_flavour}`, selectedProductByFlavour, true)
                    }
                  })
                  .finally(() => {
                    setLoadingFlavours(false)
                  })

                // Busca os tamanhos do PRODUTO
                setLoadingSizes(true)
                getProductSizes(uuid_product)
                  .then((sizes) => {
                    // Adicionar os tamanhos do produto ao product, e se tiver mais de um tamanho
                    // Altera no selectedProduct que é para mostrar os tamanhos na modalBuilder
                    productByFlavourRef.current = {
                      ...productByFlavourRef.current,
                      sizes: sizes.sort((a, b) => a.nro_priority - b.nro_priority),
                    }
                    selectedProductByFlavour = {
                      ...selectedProductByFlavour,
                      size: { ...size, show: sizes.length > 1 },
                    }
                    setProduct({ ...productByFlavourRef.current, sizes })
                    // setSelectedProduct(selectedProductByFlavour)
                  })
                  .finally(() => {
                    setLoadingSizes(false)
                  })

                // Busca os complementos do PRODUTO
                setLoadingComplements(true)
                getProductComplements(uuid_product)
                  .then((complements) => {
                    // productByFlavour = { ...productByFlavour, complements }
                    productByFlavourRef.current = { ...productByFlavourRef.current, complements }
                    // selectedProductByFlavour = { ...selectedProductByFlavour, complements }

                    setProduct(productByFlavourRef.current)
                    // setSelectedProduct(selectedProductByFlavour)
                    setLocalItem(`product-${uuid_product}${flavour.uuid_flavour}`, productByFlavourRef.current, true)
                    // setLocalItem(`selected-${uuid_product}${flavour.uuid_flavour}`, selectedProductByFlavour, true)
                  })
                  .finally(() => {
                    setLoadingComplements(false)
                  })
                setLocalItem(`product-${uuid_product}${flavour.uuid_flavour}`, productByFlavourRef.current, true)
                setLocalItem(`selected-${uuid_product}${flavour.uuid_flavour}`, selectedProductByFlavour, true)
              }
            })
            .finally(() => {
              setLoading(false)
              setLoadingProducts(false)
            })

          break

        default:
          break
      }
    }
  }, [content])

  const getImagePositionGraphic = () => {
    const svgElement = document.getElementsByClassName("apexcharts-graphical")
    if (!!svgElement) {
      for (let element of svgElement) {
        if (!!element) {
          const patterns = element.getElementsByTagName("pattern")
          for (let pattern of patterns) {
            const patternImage = document.getElementById(pattern.attributes[0].nodeValue).children[0]
            patternImage.setAttribute("x", "-10")
            patternImage.setAttribute("y", "-4")
          }
        }
      }
    }
  }

  useEffect(() => {
    const onPageLoad = () => {
      getImagePositionGraphic()
    }
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad()
    } else {
      window.addEventListener("load", onPageLoad)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad)
    }
  }, [graph, selectedProduct])

  // Método para consultar os produtos do combo disponíveis
  const getComboProducts = async (comboProps) => {
    let toContinue = true
    let newCombo = comboProps

    let productsSelected = []
    let beveragesSelected = []

    let comboProductItems = []
    let comboBeverageItems = []
    let newBeverageItems = []
    let itensNumber = []

    let localStorageComboProductItems = getLocalItem(`comboProductItems-${newCombo.id_combo}`, true)

    if (!!localStorageComboProductItems && localStorageComboProductItems.length > 0) {
      // Verificando se todos os itens da lista localStorageComboProductItems possuem o campo "product"
      comboProductItems = localStorageComboProductItems.filter((item) => item && "product" in item)
    }

    let localStorageComboBeveragesItems = getLocalItem(`comboBeveragesItems-${newCombo.id_combo}`, true)

    if (!!localStorageComboBeveragesItems && localStorageComboBeveragesItems.length > 0) {
      // Verificando se todos os itens da lista localStorageComboBeveragesItems possuem o campo "beverage_types"
      comboBeverageItems = localStorageComboBeveragesItems.filter((item) => item && "beverage_types" in item)
    }

    setLoadingProducts(true)
    ComboController.fetchComboProducts(newCombo.uuid_combo)
      .then((responseProduct) => {
        setLoading(false)
        if (responseProduct.status === 200 && responseProduct.data.rows.length > 0) {
          // Verificando os items do combo

          for (const [index, comboItem] of responseProduct.data.rows.entries()) {
            const isLastProduct = index + 1 === responseProduct.data.rows.length
            //Identifica qual o item do localStorage que referente ao item do combo

            let comboProductItem = {}
            let productRequired = null

            //Se existir o dados dos produtos do combo no localStorage, vai pegar qual o index do item
            //para fazer as tratativas com o valor já selecionado.

            comboProductItem = { ...comboItem }

            const {
              id_combo_product,
              nro_item,
              product,
              qty_max_beverage,
              qty_min_beverage,
              st_percentage_calc,
              st_required_beverage,
              st_required_product,
              // title_complement,
              // title_flavour,
              // title_size,
            } = comboItem

            productRequired = {
              id_product: product.id_product,
              id_combo_product: id_combo_product,
              uuid_product: product.uuid_product,
              nm_product: product.nm_product,
              id_combo: newCombo.id_combo,
              nro_item: nro_item,
              nro_combo: newCombo.nro_combo,
              qty_max_beverage,
              qty_min_beverage,
              st_percentage_calc,
              st_required_beverage,
              st_required_product,
              qty_flavours: 1,
              size: null,
              flavours: [],
              beverages: [],
              complements: [],
            }

            // Tamanhos do produto do combo
            setLoadingSizes(true)
            ComboController.fetchComboSizesProduct(id_combo_product, product.id_product)
              // eslint-disable-next-line no-loop-func
              .then((responseComboSizes) => {
                let comboSizesProduct = []

                if (responseComboSizes.status === 200) {
                  for (const [index, comboSize] of responseComboSizes.data.combo_sizes.entries()) {
                    const {
                      val_price,
                      size: { product_association, id_size, nm_size, uuid_size },
                    } = comboSize
                    // Ratirada a validação de escoonder tamanho no combo.
                    // if (!product_association.st_hide_in_builder || (isLastSize && comboSizesProduct.length === 0)) {
                    comboSizesProduct.push({ id_size, nm_size, uuid_size, ...product_association, val_price })
                    // }
                  }
                }

                if (!!productRequired && comboSizesProduct.length === 1) {
                  productRequired = {
                    ...productRequired,
                    qty_flavours: comboSizesProduct[0].qty_max_flavours,
                    size: { ...comboSizesProduct[0], disabled: true, show: true },
                  }
                }

                const productSelectedIndex = productsSelected.findIndex(
                  ({ id_product, nro_item }) => id_product === comboItem.product.id_product && comboItem.nro_item === nro_item
                )

                if (productSelectedIndex !== -1) {
                  productsSelected[productSelectedIndex] = { ...productsSelected[productSelectedIndex], ...productRequired }
                } else {
                  productsSelected.push({ ...productRequired })
                }

                const comboItemIndex = comboProductItems.findIndex(
                  ({ product: { id_product }, nro_item }) => id_product === comboItem.product.id_product && comboItem.nro_item === nro_item
                )

                if (comboItemIndex !== -1) {
                  comboProductItems[comboItemIndex] = { ...comboProductItems[comboItemIndex], combo_sizes: comboSizesProduct }
                } else {
                  comboProductItems.push({ ...comboProductItem, combo_sizes: comboSizesProduct })
                }

                selectedComboRef.current = { ...comboProps, products: productsSelected, beverages: beveragesSelected, amount: 1 }
                setSelectedCombo(selectedComboRef.current)

                setLocalItem(`comboProductItems-${newCombo.id_combo}`, comboProductItems, true)
                setCombo({ ...newCombo, product_items: comboProductItems })

                itensNumber = productsSelected.map((productSelected) => {
                  const amountFlavours = !!productSelected.size ? productSelected.size.qty_max_flavours : 1
                  return { nro_item: productSelected.nro_item, number: amountFlavours - 1 }
                })
                setSelectedComboFlavourNumber(itensNumber)
              })
              .finally(() => {
                setLoadingSizes(!!isLastProduct ? false : loadingSizes)
              })

            // Sabores do produto
            setLoadingFlavours(true)
            ComboController.fetchComboFlavoursProduct(id_combo_product, product.id_product, {
              orderField: "name",
              orderKeyword: "asc",
              pageNumber: 1,
              pageSize: 100,
              withSizes: true,
              withIngredients: false,
            })
              .then((responseComboFlavours) => {
                const comboFlavoursProduct =
                  responseComboFlavours.status === 200
                    ? responseComboFlavours.data.rows.map(
                        ({
                          id_flavour,
                          uuid_flavour,
                          ref_flavour,
                          nm_flavour,
                          sizes = [],
                          ingredients = [],
                          optionals = [],
                          product_association = {},
                          combo_flavour_association = {},
                        }) => {
                          return {
                            id_flavour,
                            uuid_flavour,
                            ref_flavour,
                            nm_flavour,
                            sizes,
                            ingredients,
                            optionals,
                            additions: [],
                            restrictions: [],
                            ...product_association,
                            ...combo_flavour_association,
                          }
                        }
                      )
                    : []

                const productSelectedIndex = productsSelected.findIndex(
                  ({ id_product, nro_item }) => id_product === comboItem.product.id_product && comboItem.nro_item === nro_item
                )

                if (productSelectedIndex !== -1) {
                  productsSelected[productSelectedIndex] = { ...productsSelected[productSelectedIndex], ...productRequired }
                } else {
                  productsSelected.push({ ...productRequired })
                }

                const comboItemIndex = comboProductItems.findIndex(
                  ({ product: { id_product }, nro_item }) => id_product === comboItem.product.id_product && comboItem.nro_item === nro_item
                )

                if (comboItemIndex !== -1) {
                  comboProductItems[comboItemIndex] = { ...comboProductItems[comboItemIndex], combo_flavours: comboFlavoursProduct }
                } else {
                  comboProductItems.push({ ...comboProductItem, combo_flavours: comboFlavoursProduct })
                }

                setLocalItem(`comboProductItems-${newCombo.id_combo}`, comboProductItems, true)
                setCombo({ ...newCombo, product_items: comboProductItems })
              })
              .finally(() => {
                setLoadingFlavours(!!isLastProduct ? false : loadingFlavours)
              })

            // Complementos do produto do combo
            setLoadingComplements(true)
            ComboController.fetchComboComplementsProduct(id_combo_product, product.id_product)
              .then((responseComboComplements) => {
                let comboComplementsProduct =
                  responseComboComplements.status === 200
                    ? responseComboComplements.data.combo_complements.map((eachComplement) => {
                        const { val_price, title_complement, complement } = eachComplement
                        const { id_complement, uuid_complement, nm_complement, product_association, sizes } = complement
                        return {
                          val_price,
                          title_complement,
                          id_complement,
                          uuid_complement,
                          nm_complement,
                          ...product_association,
                          sizes,
                        }
                      })
                    : []

                // const indexItem = comboProductItems.findIndex(({ id_combo_product }) => id_combo_product === comboItem.id_combo_product)
                const indexItem = comboProductItems.findIndex(
                  ({ product: { id_product }, nro_item }) => id_product === comboItem.product.id_product && comboItem.nro_item === nro_item
                )
                if (indexItem !== -1) {
                  comboProductItems[indexItem] = {
                    ...comboProductItems[indexItem],
                    combo_complements: comboComplementsProduct,
                  }
                } else {
                  comboProductItems.push({ ...comboProductItem, combo_complements: comboComplementsProduct })
                }
                // setLocalItem("comboProductItems", comboProductItems, true)

                //Se tiver complementos no combo, buscar todos os adicionais deste complemento
                if (comboComplementsProduct.length > 0) {
                  // Removendo complementos duplicados.
                  // const setComplement = new Set()
                  // comboComplementsProduct = comboComplementsProduct.filter((complementProduct) => {
                  //   const duplicatedComplement = setComplement.has(complementProduct.id_complement)
                  //   setComplement.add(complementProduct.id_complement)
                  //   return !duplicatedComplement
                  // })

                  let combo_complements = comboComplementsProduct
                  for (let index = 0; index < combo_complements.length; index++) {
                    //Buscando os adicionais dos complementos
                    ComboController.fetchComboAdditionalsProduct(id_combo_product, product.id_product, combo_complements[index].id_complement).then(
                      (responseComboAdditionals) => {
                        combo_complements[index] = {
                          ...combo_complements[index],
                          combo_additionals: responseComboAdditionals.data.combo_additionals.map((eachAdd) => {
                            const { additional, val_price } = eachAdd
                            const { complement_association, uuid_additional, id_additional, nm_additional, sizes } = additional
                            return {
                              ...complement_association,
                              uuid_additional,
                              id_additional,
                              nm_additional,
                              sizes,
                              val_price,
                            }
                          }),
                        }
                        // const indexItem = comboProductItems.findIndex(({ id_combo_product }) => id_combo_product === comboItem.id_combo_product)
                        const indexItem = comboProductItems.findIndex(
                          ({ product: { id_product }, nro_item }) => id_product === comboItem.product.id_product && comboItem.nro_item === nro_item
                        )
                        if (indexItem !== -1) {
                          comboProductItems[indexItem] = { ...comboProductItems[indexItem], combo_complements }
                        } else {
                          comboProductItems.push({ ...comboProductItem, combo_complements })
                        }
                        setLocalItem(`comboProductItems-${newCombo.id_combo}`, comboProductItems, true)
                        setCombo({ ...newCombo, product_items: comboProductItems })
                      }
                    )
                  }
                }
              })
              .finally(() => {
                setLoadingComplements(!!isLastProduct ? false : loadingComplements)
              })

            // Bebidas do produto do combo
            ComboController.fetchComboBeveragesProduct(id_combo_product).then((responseBeverages) => {
              const comboBeverages =
                responseBeverages.status === 200
                  ? responseBeverages.data.combo_beverages.map((eachBeverage) => {
                      const { val_price, beverage } = eachBeverage
                      const { company_association, uuid_beverage, id_beverage, nm_beverage } = beverage
                      return { ...company_association, uuid_beverage, id_beverage, nm_beverage, val_price }
                    })
                  : []

              // const indexItem = comboProductItems.findIndex(({ id_combo_product }) => id_combo_product === comboItem.id_combo_product)
              const indexItem = comboProductItems.findIndex(
                ({ product: { id_product }, nro_item }) => id_product === comboItem.product.id_product && comboItem.nro_item === nro_item
              )
              if (indexItem !== -1) {
                comboProductItems[indexItem] = { ...comboProductItems[indexItem], combo_beverages: comboBeverages }
              } else {
                comboProductItems.push({ ...comboProductItem, combo_beverages: comboBeverages })
              }
              setLocalItem(`comboProductItems-${newCombo.id_combo}`, comboProductItems, true)
              setCombo({ ...newCombo, combo_beverages: comboBeverages })
            })
          }
        }

        if (toContinue) {
          ComboController.fetchComboBeverages(newCombo.uuid_combo).then((responseBeverages) => {
            if (responseBeverages.status === 200) {
              // Verificando os items do tipo bebida
              for (const beverageItem of responseBeverages.data.rows) {
                const { nro_item, st_required_beverage, beverage_types } = beverageItem

                if (st_required_beverage && beverage_types.length === 1) {
                  const beverageRequired = {
                    ...beverage_types[0].beverage,
                    val_price: beverage_types[0].val_price,
                    id_combo: newCombo.id_combo,
                    nro_item: nro_item,
                    nro_combo: newCombo.nro_combo,
                    disabled: true,
                    amount: 1,
                  }
                  beveragesSelected.push(beverageRequired)
                }

                newBeverageItems.push({
                  ...beverageItem,
                  beverage_types: beverage_types.map((eachBeverage) => {
                    const { beverage, val_price } = eachBeverage
                    const { company_association, id_beverage, uuid_beverage, nm_beverage } = beverage
                    return { ...company_association, id_beverage, uuid_beverage, nm_beverage, val_price: val_price || 0 }
                  }),
                })
                // }

                // localStorageComboBeveragesItems = [...newBeverageItems]
                setLocalItem(`comboBeveragesItems-${newCombo.id_combo}`, [...newBeverageItems], true)
                newCombo = { ...newCombo, beverage_items: [...newBeverageItems] }
                setCombo({ ...newCombo })
              }
            }
          })
        }
      })
      .finally(() => {
        setLoadingProducts(false)
      })
    newCombo = { ...newCombo, product_items: comboProductItems, beverage_items: comboBeverageItems }
    setCombo({ ...newCombo })
    selectedComboRef.current = { ...comboProps, products: productsSelected, beverages: beveragesSelected, amount: 1 }
    setSelectedCombo(selectedComboRef.current)

    setLocalItem(`comboProductItems-${newCombo.id_combo}`, comboProductItems, true)
    setLocalItem(`comboBeveragesItems-${newCombo.id_combo}`, comboBeverageItems, true)
    setSelectedComboFlavourNumber(itensNumber)
  }

  const onClear = () => {
    setCombo(null)
    setProduct(null)
    setSelectedCombo(null)
    selectedComboRef.current = null
    setSelectedProduct(null)
    selectedProductRef.current = null
    setSelectedFlavourNumber(0)
    setSelectedComboFlavourNumber([])
    // setFlavourPage({ currentPage: 0, hasNextPage: true })
    setModalFlavours({ ...modalFlavours, status: false, content: null })
  }

  const onCloseModal = () => {
    onClear()
    onClose()
  }

  const onCloseModalFlavour = () => {
    setModalFlavours({ ...modalFlavours, status: false })
  }

  const onClickSlice = async (config, nro_item = null, flavours = []) => {
    if (!!selectedComboRef.current && !!nro_item) {
      const indexProduct = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)
      if (indexProduct !== -1) {
        // Verificando se foi preenchido sabor na fatia selecionada
        const flavourIndex = selectedComboRef.current.products[indexProduct].flavours.findIndex((item) => item.index === config.dataPointIndex)

        if (flavourIndex !== -1) {
          let flavour = selectedComboRef.current.products[indexProduct].flavours[flavourIndex]

          setIndexChart(config.dataPointIndex)
          setModalIngredients({
            status: true,
            content: {
              size: selectedComboRef.current.products[indexProduct].size,
              flavour: flavour,
              nro_item,
              itemIndex: config.dataPointIndex,
            },
          })
        } else {
          setIndexChart(config.dataPointIndex)
          setModalFlavours({
            status: true,
            content: flavours,
            nro_item,
            onClick: (flavour) => onChangeFlavour(flavour, [], nro_item, null, null, config.dataPointIndex),
          })
        }
      }
    } else {
      // Verificando se foi preenchido sabor na fatia selecionada
      let flavour = selectedProduct.flavours.find((item) => item.index === config.dataPointIndex)

      if (!!flavour) {
        if (!flavour.loadingIngredient && !flavour.loadingOptional) {
          setModalIngredients({
            status: true,
            content: {
              flavour,
              size: !!selectedProduct.size ? selectedProduct.size : null,
              itemIndex: config.dataPointIndex,
            },
          })
        }
      } else {
        const flavoursEnabledOnSize = product.flavours.filter((flavour) => {
          return flavour.sizes.find((size) => size.id_size === selectedProduct.size.id_size)
        })
        setModalFlavours({ status: true, content: flavoursEnabledOnSize })
        setIndexChart(config.dataPointIndex)
      }
    }
  }

  const onChangeSizeByFlavour = (newSize) => {
    const newSelectedSize = product.sizes.find(({ uuid_size }) => uuid_size === newSize.uuid_size)
    setSelectedProduct({
      ...selectedProduct,
      size: { ...newSelectedSize, show: product.sizes.length > 1 },
      complements: [],
    })
    selectedProductRef.current = {
      ...selectedProduct,
      size: { ...newSelectedSize, show: product.sizes.length > 1 },
      complements: [],
    }
  }

  const onChangeComboSize = (size, nroItem) => {
    if (!!selectedComboRef.current) {
      // procurando um produto no combo selecionado
      const productIndex = selectedComboRef.current.products.findIndex((comboProduct) => nroItem === comboProduct.nro_item)
      // Verificando se já existe um produto deste item
      if (productIndex !== -1) {
        selectedComboRef.current.products[productIndex].size = { ...size }

        const comboFlavourNumberIndex = selectedComboFlavourNumber.findIndex(({ nro_item }) => nro_item === nroItem)
        selectedComboFlavourNumber[comboFlavourNumberIndex].number = size.qty_max_flavours - 1

        //Caso o tamanho escolhido tenha uma quantidade de sabores menor que a anterior, remove o último sabor
        if (selectedComboRef.current.products[productIndex].flavours.length > size.qty_max_flavours) {
          const value = selectedComboRef.current.products[productIndex].flavours.length - size.qty_max_flavours
          selectedComboRef.current.products[productIndex].flavours.splice(-value, value)
        }
      } else {
        // TODO: Em casos que o produto não seja localizado dentro do combo selecionado (Embora possa ser pouco provavel que o produto não exista anteriormente.)
        // const newProduct = {
        //   ...productItem.product,
        //   id_combo: selectedComboRef.current.id_combo,
        //   nro_item: productItem.nro_item,
        //   nro_combo: selectedComboRef.current.nro_combo,
        //   qty_beverage: productItem.qty_beverage,
        //   qty_additional: productItem.qty_beverage,
        //   size: { ...size },
        //   flavours: [],
        //   beverages: [],
        //   additionals: [],
        // }
        // selectedComboRef.current.products.push(newProduct)
      }
      setSelectedCombo({ ...selectedComboRef.current })
      selectedComboRef.current = { ...selectedComboRef.current }
      setSelectedComboFlavourNumber([...selectedComboFlavourNumber])
      checkValidation("size", nroItem, selectedComboRef.current)
    }
  }

  const onChangeFlavour = async (flavour, selected, nro_item, complement, optionType, index) => {
    if (!!selectedComboRef.current) {
      const productIndex = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)
      // Verificando se achou indice do produto dentro dos items do combo
      if (productIndex !== -1) {
        //Verificar se o tipo de produto é pizza para montar o gráfico.
        // const graph = `${selectedComboRef.current.products[productIndex].nm_product}`.toLowerCase() === "pizza"
        const graph = `${selectedComboRef.current.products[productIndex].nm_product}`.toLowerCase().indexOf("pizza") !== -1

        if (graph && index !== null) {
          const indexFlavour = selectedComboRef.current.products[productIndex].flavours.findIndex((flavour) => flavour.index === index)

          let newFlavour = {
            ...flavour,
            loadingIngredient: true,
            loadingOptional: true,
            restrictions: [],
            additions: [],
            amount: 1,
            index,
          }

          //Consultando os ingredientes do sabor
          FlavourController.fetchOne(flavour.uuid_flavour, selectedComboRef.current.products[productIndex].uuid_product, true, false).then(
            ({ data, status }) => {
              if (status === 200) {
                const { ingredients = [] } = data
                newFlavour = {
                  ...newFlavour,
                  loadingIngredient: false,
                  ingredients,
                }
                const indexFlavour = selectedComboRef.current?.products[productIndex]?.flavours.findIndex((flavour) => flavour.index === index)
                if (indexFlavour !== -1) {
                  selectedComboRef.current.products[productIndex].flavours[indexFlavour] = newFlavour
                } else {
                  selectedComboRef.current.products[productIndex].flavours.push(newFlavour)
                }
                setSelectedCombo({ ...selectedComboRef.current })
                selectedComboRef.current = { ...selectedComboRef.current }
              }
            }
          )

          //Consultando os opcionais do sabor
          FlavourController.fetchOne(flavour.uuid_flavour, selectedComboRef.current.products[productIndex].uuid_product, false, true).then(
            ({ data, status }) => {
              if (status === 200) {
                const { optionals = [] } = data
                newFlavour = {
                  ...newFlavour,
                  loadingOptional: false,
                  optionals,
                }
                const indexFlavour = selectedComboRef.current?.products[productIndex].flavours?.findIndex((flavour) => flavour.index === index)

                if (indexFlavour !== -1) {
                  selectedComboRef.current.products[productIndex].flavours[indexFlavour] = newFlavour
                } else {
                  selectedComboRef.current.products[productIndex].flavours.push(newFlavour)
                }
                setSelectedCombo({ ...selectedComboRef.current })
                selectedComboRef.current = { ...selectedComboRef.current }
              }
            }
          )

          if (indexFlavour !== -1) {
            selectedComboRef.current.products[productIndex].flavours[indexFlavour] = newFlavour
          } else {
            selectedComboRef.current.products[productIndex].flavours.push(newFlavour)
          }
          onCloseModalFlavour()
        } else {
          let newFlavour = {
            ...flavour,
            amount: 1,
            indexProduct: productIndex,
            restrictions: [],
            additions: [],
            loadingIngredient: true,
            loadingOptional: true,
          }

          // Verificando se já foi selecionado algum sabor
          if (selectedComboRef.current.products[productIndex].flavours.length > 0) {
            const { size, sizes = null, flavours } = selectedComboRef.current.products[productIndex]

            // Verificando se o sabor já foi selecionado
            const flavourIndex = flavours.findIndex(({ id_flavour }) => id_flavour === flavour.id_flavour)

            if (flavourIndex !== -1) {
              if (optionType !== "radio") {
                selectedComboRef.current.products[productIndex].flavours.splice(flavourIndex, 1)
              }
            } else {
              let amountFlavoursBySize = 1
              if (!!sizes) {
                //Caso no sabor tenha um array de sabores e não apenas um sabor selecionado.
              } else if (!!size) {
                amountFlavoursBySize = size.qty_max_flavours
              }

              // Atualizando o sabor selecionado na tela
              if (optionType === "radio") {
                if (amountFlavoursBySize === 1) {
                  selectedComboRef.current.products[productIndex].flavours = [newFlavour]
                  setSelectedCombo({ ...selectedComboRef.current })
                  selectedComboRef.current = { ...selectedComboRef.current }
                }
              } else {
                if (flavours.length < amountFlavoursBySize) {
                  selectedComboRef.current.products[productIndex].flavours.push(newFlavour)
                  setSelectedCombo({ ...selectedComboRef.current })
                  selectedComboRef.current = { ...selectedComboRef.current }
                } else {
                  Message.info("Número máximo de sabores para este produto atingido!")
                }
              }

              // Carregando os ingredientes e opcionais
              const responseFlavoursIngredients = await FlavourController.fetchOne(
                flavour.uuid_flavour,
                selectedComboRef.current.products[productIndex].uuid_product,
                true,
                false
              )
              const responseFlavoursOptionals = await FlavourController.fetchOne(
                flavour.uuid_flavour,
                selectedComboRef.current.products[productIndex].uuid_product,
                false,
                true
              )
              newFlavour = {
                ...newFlavour,
                ingredients: responseFlavoursIngredients.status === 200 ? responseFlavoursIngredients.data.ingredients : [],
                optionals: responseFlavoursOptionals.status === 200 ? responseFlavoursOptionals.data.optionals : [],
                loadingIngredient: false,
                loadingOptional: false,
              }

              // Atualizando os dados após carregamento dos ingredientes e opcionais
              if (optionType === "radio") {
                if (amountFlavoursBySize === 1) {
                  selectedComboRef.current.products[productIndex].flavours = [newFlavour]
                  // Retirada a parte que abria a modal automaticamente
                  // if (newFlavour.optionals.length > 0 || newFlavour.ingredients.length > 0) {
                  //   setModalIngredients({
                  //     status: true,
                  //     content: {
                  //       flavour: newFlavour,
                  //       size: selectedComboRef.current.products[productIndex].size,
                  //       itemIndex: productIndex,
                  //       nro_item,
                  //     },
                  //   })
                  // }
                }
              } else {
                if (flavours.length < amountFlavoursBySize) {
                  selectedComboRef.current.products[productIndex].flavours.push(newFlavour)
                  // Retirada a parte que abria a modal automaticamente
                  // if (newFlavour.optionals.length > 0 || newFlavour.ingredients.length > 0) {
                  //   setModalIngredients({
                  //     status: true,
                  //     content: {
                  //       flavour: newFlavour,
                  //       size: selectedComboRef.current.products[productIndex].size,
                  //       itemIndex: productIndex,
                  //       nro_item,
                  //     },
                  //   })
                  // }
                } else {
                  Message.info("Número máximo de sabores para este produto atingido!")
                }
              }
            }
          } else {
            selectedComboRef.current.products[productIndex].flavours = [newFlavour]
            // Atualizando o sabor selecionado na tela
            setSelectedCombo({ ...selectedComboRef.current })
            selectedComboRef.current = { ...selectedComboRef.current }

            // Carregando os ingredientes e opcionais
            if (!!selectedComboRef.current) {
              const responseFlavoursIngredients = await FlavourController.fetchOne(
                flavour.uuid_flavour,
                selectedComboRef.current.products[productIndex].uuid_product,
                true,
                false
              )

              const responseFlavoursOptionals = await FlavourController.fetchOne(
                flavour.uuid_flavour,
                selectedComboRef?.current?.products[productIndex]?.uuid_product,
                false,
                true
              )
              newFlavour = {
                ...newFlavour,
                ingredients: responseFlavoursIngredients.status === 200 ? responseFlavoursIngredients.data.ingredients : [],
                optionals: responseFlavoursOptionals.status === 200 ? responseFlavoursOptionals.data.optionals : [],
                loadingIngredient: false,
                loadingOptional: false,
              }

              // Atualizando os dados após carregamento dos ingredientes e opcionais
              if (!!selectedComboRef.current.products[productIndex]) {
                selectedComboRef.current.products[productIndex].flavours = [newFlavour]
              }
            }

            // Retirada a parte que abria a modal automaticamente
            // if (newFlavour.optionals.length > 0 || newFlavour.ingredients.length > 0) {
            //   setModalIngredients({
            //     status: true,
            //     content: {
            //       flavour: newFlavour,
            //       itemIndex: productIndex,
            //       size: selectedComboRef.current.products[productIndex].size,
            //       nro_item,
            //     },
            //   })
            // }
          }
        }
        setSelectedCombo({ ...selectedComboRef.current })
        selectedComboRef.current = { ...selectedComboRef.current }
        checkValidation("flavour", nro_item, selectedComboRef.current)
      } else {
        GeneralTools.swalMessage("info")
          .fire({
            title: `Erro ao selecionar o sabor, entre em contato com o estabelecimento (ocf-01)`,
            icon: "info",
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
      }
    } else {
      setOnFlavour({ ...flavour, loadingIngredient: true, loadingOptional: true })
    }
  }

  const onChangeAdditional = (additional, maxAmount = null, nro_item, complement, optionType) => {
    if (!!selectedComboRef.current) {
      const indexProduct = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)
      if (indexProduct !== -1) {
        const {
          additionals = [],
          complements = [],
          size: { qty_max_complements },
        } = selectedComboRef.current.products[indexProduct]
        const qtyMaxSelectedComplement = !!complement.sizes
          ? complement.sizes.find(({ uuid_size }) => uuid_size === selectedComboRef.current?.products[indexProduct]?.size?.uuid_size)?.complement_size
              ?.qty_max_allowed
          : !!complement.size
          ? complement.size?.complement_size?.qty_max_allowed
          : qty_max_complements

        const indexComplement = complements.findIndex(({ uuid_complement }) => uuid_complement === complement.uuid_complement)
        if (indexComplement !== -1) {
          // Achou o complemento dentro do produto: Verifica se o adicional já está inserido, e valida os limites:
          // Se o limite for maior que 1, pode selecionar só ir inserindo dentro do array de adicionais do complemento
          // Se o limite for igual a 1, remove o adicional antigo e adiciona o novamente.
          const indexAdditional = additionals.findIndex(({ uuid_additional }) => uuid_additional === additional.uuid_additional)

          //Achou o adicional dentro do produto
          // Verifica se o limite de complementos é maior que 1 e se a quantidade de complementos adicionados está igual ao limite máximo.
          if (
            qty_max_complements > 1 &&
            selectedComboRef.current.products[indexProduct].complements[indexComplement].additionals.length <= qty_max_complements &&
            qtyMaxSelectedComplement > 1 &&
            selectedComboRef.current.products[indexProduct].complements[indexComplement].additionals.length <= qtyMaxSelectedComplement
          ) {
            selectedComboRef.current.products[indexProduct].complements[indexComplement].additionals.push({
              nm_additional: additional.nm_additional,
              id_additional: additional.id_additional,
              uuid_additional: additional.uuid_additional,
              val_price: additional.val_price,
            })
          } else if (qty_max_complements === 1 || qtyMaxSelectedComplement === 1) {
            selectedComboRef.current.products[indexProduct].complements[indexComplement].additionals.splice(indexAdditional, 1)
            selectedComboRef.current.products[indexProduct].complements[indexComplement].additionals.push({
              nm_additional: additional.nm_additional,
              id_additional: additional.id_additional,
              uuid_additional: additional.uuid_additional,
              val_price: additional.val_price,
            })
          }
        } else {
          //Não achou o complemento dentro do produto: Insere o complemento + adicional ao produto.
          selectedComboRef.current.products[indexProduct].complements = [
            ...selectedComboRef.current.products[indexProduct].complements,
            {
              nm_complement: complement.nm_complement,
              lb_complement: complement.lb_complement,
              id_complement: complement.id_complement,
              uuid_complement: complement.uuid_complement,
              val_price: complement.val_price,
              additionals: [
                {
                  nm_additional: additional.nm_additional,
                  id_additional: additional.id_additional,
                  uuid_additional: additional.uuid_additional,
                  val_price: additional.val_price,
                },
              ],
            },
          ]
        }

        setSelectedCombo({ ...selectedComboRef.current })
        selectedComboRef.current = { ...selectedComboRef.current }
        checkValidation("additional", nro_item, selectedComboRef.current)
      } else {
        Message.info("Faltou selecionar o produto!")
      }
    } else {
      //Verifica se aquele complemento já foi selecionado.
      const indexComplement = selectedProduct.complements.findIndex(({ id_complement }) => id_complement === complement.id_complement)
      if (indexComplement !== -1) {
        //Caso o complemento já tenha sido adicionado

        //Verificando se o adicional já existe
        const indexAdditional = selectedProduct.complements[indexComplement].additionals.findIndex(
          ({ id_additional }) => id_additional === additional.id_additional
        )

        if (indexAdditional !== -1) {
          //Adicional localizado
          if (optionType === "selector") {
            if (selectedProduct.complements[indexComplement].additionals.length === 1) {
              selectedProduct.complements.splice(indexComplement, 1)
            } else {
              selectedProduct.complements[indexComplement].additionals.splice(indexAdditional, 1)
            }
          }
        } else {
          //Verificar o total de adicionais já adicionados.
          let totalSelected = 0
          selectedProduct.complements.forEach(({ additionals }) => {
            additionals.forEach((eachadditional) => {
              totalSelected = totalSelected + eachadditional.amount
            })
          })
          //Identificar o limite de adicionais do complemento.
          let totalComplementLimit = 0
          if (!!complement.sizes) {
            totalComplementLimit = complement.sizes.find(({ id_size }) => id_size === selectedProduct.size.id_size).complement_size.qty_max_allowed
          } else if (!!complement.size) {
            totalComplementLimit = complement.size.complement_size.qty_max_allowed
          }

          //Verificando se o total de adicionais já selecionados é menor do que a quantidade que o complemento permite
          if (totalSelected < totalComplementLimit) {
            //Verificar se o total de adicionais do tamanho já foi atingido.
            if (totalSelected < selectedProduct.size.qty_max_complements) {
              if (optionType === "radio") {
                selectedProduct.complements[indexComplement].additionals = []
              }
              selectedProduct.complements[indexComplement].additionals.push({ ...additional, amount: 1 })
            } else {
              Message.info("Número máximo de adicionais para este item atingido!")
            }
          } else {
            Message.info("Número máximo de adicionais para este item atingido!")
          }
        }
      } else {
        //Caso o complemento não tenha sido adicionado anteriormente
        //Verifica o total de adicionais que foram selecionados
        let totalSelected = 0
        selectedProduct.complements.forEach(({ additionals }) => {
          additionals.forEach((eachadditional) => {
            totalSelected = totalSelected + eachadditional.amount
          })
        })

        if (totalSelected <= selectedProduct.size.qty_max_complements) {
          selectedProduct.complements = [
            ...selectedProduct.complements,
            {
              id_complement: complement.id_complement,
              uuid_complement: complement.uuid_complement,
              nm_complement: complement.nm_complement,
              lb_complement: complement.lb_complement,
              sizes: complement.sizes,
              additionals: [
                {
                  ...additional,
                  amount: 1,
                },
              ],
            },
          ]
        } else {
          Message.info("Número máximo de adicionais para este item atingido!")
        }
      }
      //Ao final de tudo, salva o produto selecionado,
      setSelectedProduct({ ...selectedProduct })
      selectedProductRef.current = { ...selectedProduct }
    }
  }

  const onChangeIngredient = (ingredient, nro_item = null, id_flavour, itemIndex = null) => {
    if (!!selectedComboRef.current) {
      const indexProduct = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)
      if (indexProduct !== -1) {
        const indexFlavour = selectedComboRef.current.products[indexProduct].flavours.findIndex(
          (flavour) => id_flavour === flavour.id_flavour && (itemIndex === flavour.index || itemIndex === flavour.indexProduct || itemIndex !== null)
        )
        if (indexFlavour !== -1) {
          const indexRestriction = selectedComboRef.current.products[indexProduct].flavours[indexFlavour].restrictions.findIndex(
            ({ id_ingredient }) => id_ingredient === ingredient.id_ingredient
          )

          if (indexRestriction !== -1) {
            selectedComboRef.current.products[indexProduct].flavours[indexFlavour].restrictions.splice(indexRestriction, 1)
          } else {
            selectedComboRef.current.products[indexProduct].flavours[indexFlavour].restrictions.push(ingredient)
          }

          setSelectedCombo({ ...selectedComboRef.current })
          selectedComboRef.current = { ...selectedComboRef.current }
          setModalIngredients({
            ...modalIngredients,
            content: {
              flavour: selectedComboRef.current.products[indexProduct].flavours[indexFlavour],
              size: selectedComboRef.current.products[indexProduct].size,
              nro_item,
              itemIndex: itemIndex,
            },
          })
        }
      }
    } else {
      let indexFlavour = -1
      const typeOfFlavour = typeof id_flavour === "object" && id_flavour !== null
      if (!typeOfFlavour) {
        indexFlavour = selectedProduct.flavours.findIndex((flavour) => {
          const hasFlavourIndex = itemIndex !== null ? itemIndex === flavour.index : true
          return id_flavour === flavour.id_flavour && hasFlavourIndex
        })
      } else {
        indexFlavour = selectedProduct.flavours.findIndex((flavour) => {
          const hasFlavourIndex = itemIndex !== null ? itemIndex === flavour.index : true
          return id_flavour.id_flavour === flavour.id_flavour && hasFlavourIndex
        })
      }
      // Achou o sabor equivalente
      if (indexFlavour !== -1) {
        const indexRestriction = selectedProduct.flavours[indexFlavour].restrictions.findIndex(
          ({ id_ingredient }) => id_ingredient === ingredient.id_ingredient
        )
        if (indexRestriction !== -1) {
          selectedProduct.flavours[indexFlavour].restrictions.splice(indexRestriction, 1)
          setSelectedProduct({ ...selectedProduct })
          selectedProductRef.current = { ...selectedProduct }
        } else {
          selectedProduct.flavours[indexFlavour].restrictions.push(ingredient)
          setSelectedProduct({ ...selectedProduct })
          selectedProductRef.current = { ...selectedProduct }
        }
      }
    }
  }

  const onChangeComboBeverage = (beverage, nro_item, qty_beverage = null, typeBeverage) => {
    //Verificar se é uma bebida que está inclusa num produto ou o item é uma bebida de combo
    if (typeBeverage === "product") {
      //Verificar se a bebida já foi selecionada.
      const indexProduct = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)

      const indexProductBeverage = selectedComboRef.current.products[indexProduct].beverages.findIndex(
        ({ uuid_beverage }) => uuid_beverage === beverage.uuid_beverage
      )

      if (indexProductBeverage !== -1) {
        //A bebida já existe, remover a bebida
        selectedComboRef.current.products[indexProduct].beverages.splice(indexProductBeverage, 1)
      } else {
        //A bebida ainda não existe, verificar se permite mais de uma bebida, se sim, adiciona, se não, verifica se já existe alguma bebida, remove a anterior e adiciona a nova.
        //Remover sempre a primeira bebida do array, pois caso existam 2 bebidas, removerá sempre a primeira.
        if (qty_beverage.qty_max_beverage > 1) {
          if (selectedComboRef.current.products[indexProduct].beverages.length < qty_beverage.qty_max_beverage) {
            selectedComboRef.current.products[indexProduct].beverages.push({ ...beverage, nro_item })
          } else {
            selectedComboRef.current.products[indexProduct].beverages.shift()
            selectedComboRef.current.products[indexProduct].beverages.push({ ...beverage, nro_item })
          }
        } else {
          if (selectedComboRef.current.products[indexProduct].beverages.length !== 0) {
            selectedComboRef.current.products[indexProduct].beverages = []
          }
          selectedComboRef.current.products[indexProduct].beverages.push({ ...beverage, nro_item })
        }
      }
      //
      //
      //
      //
    } else if (typeBeverage === "beverages") {
      const comboBeverageNroItemSelected = selectedComboRef.current.beverages.findIndex((comboBeverage) => comboBeverage.nro_item === nro_item)

      if (comboBeverageNroItemSelected !== -1) {
        const indexBeverages = selectedComboRef.current.beverages.findIndex(
          (comboBeverage) => comboBeverage.uuid_beverage === beverage.uuid_beverage && comboBeverage.nro_item === nro_item
        )

        if (indexBeverages !== -1) {
          // já existe a bebida, remover a bebida selecionada
          //TODO: Em alguns casos a bebida vai ser por number control, e nesse caso precisa adicionar uma nova bebida.
          // selectedComboRef.current.beverages.splice(indexBeverages, 1)

          //A bebida ainda não existe, verificar se permite mais de uma bebida, se sim, adiciona, se não, verifica se já existe alguma bebida, remove a anterior e adiciona a nova.
          //Remover sempre a primeira bebida do array, pois caso existam 2 bebidas, removerá sempre a primeira.

          selectedComboRef.current.beverages.splice(indexBeverages, 1)
        } else {
          // selectedComboRef.current.beverages.push({ ...beverage, nro_item })
          if (qty_beverage.qty_max_beverage > 1) {
            if (selectedComboRef.current.beverages.length < qty_beverage.qty_max_beverage) {
              selectedComboRef.current.beverages.push({ ...beverage, nro_item })
            } else {
              selectedComboRef.current.beverages.splice(indexBeverages, 1)
              selectedComboRef.current.beverages.push({ ...beverage, nro_item })
            }
          } else {
            selectedComboRef.current.beverages.splice(comboBeverageNroItemSelected, 1)
            selectedComboRef.current.beverages.push({ ...beverage, nro_item })
          }
        }
      } else {
        selectedComboRef.current.beverages.push({ ...beverage, nro_item })
      }
    }

    setSelectedCombo({ ...selectedComboRef.current })
    selectedComboRef.current = { ...selectedComboRef.current }
    // checkValidation("beverage", nro_item, selectedComboRef.current)
  }

  const onChangeOptional = (optional, nro_item = null, id_flavour, itemIndex = null) => {
    if (!!selectedComboRef.current) {
      const indexProduct = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)
      if (indexProduct !== -1) {
        const indexFlavour = selectedComboRef.current.products[indexProduct].flavours.findIndex(
          (flavour) => id_flavour === flavour.id_flavour && (itemIndex === flavour.index || itemIndex === flavour.indexProduct || itemIndex !== null)
        )
        if (indexFlavour !== -1) {
          const indexOptional = selectedComboRef.current.products[indexProduct].flavours[indexFlavour].additions.findIndex(
            ({ id_ingredient }) => id_ingredient === optional.id_ingredient
          )
          if (indexOptional !== -1) {
            selectedComboRef.current.products[indexProduct].flavours[indexFlavour].additions.splice(indexOptional, 1)
          } else {
            const selectedFlavourSize =
              selectedComboRef.current.products[indexProduct].flavours[indexFlavour]?.size ||
              selectedComboRef.current.products[indexProduct].flavours[indexFlavour]?.sizes.find(
                ({ id_size }) => id_size === selectedComboRef.current?.products[indexProduct]?.size.id_size
              )
            const totalAmountAdditions = selectedComboRef.current.products[indexProduct].flavours[indexFlavour].additions.reduce(
              (tot, current) => tot + current.amount,
              0
            )
            if (
              !selectedFlavourSize.qty_optionals ||
              selectedFlavourSize?.qty_optionals > totalAmountAdditions ||
              selectedFlavourSize?.qty_optionals === 0
            ) {
              selectedComboRef.current.products[indexProduct].flavours[indexFlavour].additions.push({
                ...optional,
                amount: 1,
              })
            } else {
              GeneralTools.swalMessage("info")
                .fire({
                  title: `Limite de opcionais atingido!`,
                  icon: "info",
                  timer: 2000,
                  showCancelButton: false,
                  showConfirmButton: false,
                })
                .then()
            }
          }

          setSelectedCombo({ ...selectedComboRef.current })
          selectedComboRef.current = { ...selectedComboRef.current }
          setModalIngredients({
            ...modalIngredients,
            content: {
              ...modalIngredients.content,
              flavour: selectedComboRef.current.products[indexProduct].flavours[indexFlavour],
              size: selectedComboRef.current.products[indexProduct].size,
              nro_item,
              itemIndex: itemIndex,
            },
          })
        }
      }
    } else {
      if (typeof id_flavour !== "number") {
        if ("id_flavour" in id_flavour) {
          id_flavour = id_flavour.id_flavour
        }
      }
      const indexFlavour = selectedProduct.flavours.findIndex((flavour) => {
        const hasItemIndex = itemIndex ? itemIndex === flavour.index : true
        return flavour.id_flavour === id_flavour && hasItemIndex
      })

      // Achou o sabor no qual estamos tentando adicionar um adicional
      if (indexFlavour !== -1) {
        //Procurando saber se já foi adicionado o sabor
        const indexOptional = selectedProduct.flavours[indexFlavour].additions.findIndex(
          ({ id_ingredient }) => id_ingredient === optional.id_ingredient
        )

        if (indexOptional !== -1) {
          selectedProduct.flavours[indexFlavour].additions.splice(indexOptional, 1)
          setSelectedProduct({ ...selectedProduct })
          selectedProductRef.current = { ...selectedProduct }
        } else {
          const selectedFlavourSize =
            selectedProduct.flavours[indexFlavour]?.size ||
            selectedProduct.flavours[indexFlavour]?.sizes.find(({ id_size }) => id_size === selectedProduct.size.id_size)
          const totalAmountAdditions = selectedProduct.flavours[indexFlavour].additions.reduce((tot, current) => tot + current.amount, 0)
          if (
            !selectedFlavourSize.qty_optionals ||
            selectedFlavourSize?.qty_optionals > totalAmountAdditions ||
            selectedFlavourSize?.qty_optionals === 0
          ) {
            selectedProduct.flavours[indexFlavour].additions.push({ ...optional, amount: 1 })
            setSelectedProduct({ ...selectedProduct })
            selectedProductRef.current = { ...selectedProduct }
          } else {
            GeneralTools.swalMessage("info")
              .fire({
                title: `Limite de opcionais atingido!`,
                icon: "info",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          }
        }

        if (modalIngredients.status === true) {
          setModalIngredients({
            ...modalIngredients,
            content: {
              flavour: {
                ...modalIngredients.content.flavour,
                ...selectedProduct.flavours[indexFlavour],
              },
              size: !!selectedProduct ? selectedProduct.size : null,
              itemIndex: itemIndex,
            },
          })
        }
      }
    }
  }

  const onChangeSelectedComboFlavourNumber = (number, nro_item) => {
    if (!!selectedComboRef.current) {
      //Altera o valor da quantidade de sabores selecionada por padrão.

      const indexComboFlavourNumber = selectedComboFlavourNumber.findIndex((item) => item.nro_item === nro_item)
      selectedComboFlavourNumber[indexComboFlavourNumber].number = number

      //Verificar se naquele produto, a quantidade de sabores selecionados é maior que o número de sabores
      //Caso seja maior, é necessário remover os sabores até chegar a quantidade de acordo.
      const indexSelectedProductOnCombo = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)
      selectedComboRef.current.products[indexSelectedProductOnCombo].qty_flavours = number + 1
      if (selectedComboRef.current.products[indexSelectedProductOnCombo].flavours.length - 1 > number) {
        const value = selectedComboRef.current.products[indexSelectedProductOnCombo].flavours.length - number - 1
        selectedComboRef.current.products[indexSelectedProductOnCombo].flavours.splice(-value, value)
        setSelectedCombo({ ...selectedComboRef.current })
        selectedComboRef.current = { ...selectedComboRef.current }
      }

      // const indexNumber = selectedComboFlavourNumber.findIndex((item) => item.nro_item === nro_item)
      // if (indexNumber !== -1) {
      //   selectedComboFlavourNumber[indexNumber] = { nro_item, number: number }
      // } else {
      //   selectedComboFlavourNumber.push({ nro_item, number: number })
      // }

      setSelectedComboFlavourNumber([...selectedComboFlavourNumber])
    }
  }

  const onChangeAmountProduct = (qntProduct) => {
    if (qntProduct >= 1) {
      setSelectedProduct({ ...selectedProduct, amount: qntProduct })
      selectedProductRef.current = { ...selectedProduct, amount: qntProduct }
    } else {
      Message.info("Quantidade mínima possível")
    }
  }

  const onChangeAmountCombo = (qntProduct) => {
    if (qntProduct >= 1) {
      setSelectedCombo({ ...selectedComboRef.current, amount: qntProduct })
      selectedComboRef.current = { ...selectedComboRef.current, amount: qntProduct }
    } else {
      Message.info("Quantidade mínima possível")
    }
  }

  const onChangeAmountFlavour = async (amount, direction, type, flavour, nroItem) => {
    if (!!selectedComboRef.current) {
      const comboProductIndex = selectedComboRef.current.products.findIndex(
        (product) => product.nro_item === nroItem
        //&& product.id_combo_product === comboProductId
      )

      if (comboProductIndex !== -1) {
        let comboQntTotalFlavoursAdded = selectedComboRef.current.products[comboProductIndex].flavours.reduce((tot, current) => {
          return tot + current.amount
        }, 0)
        //
        if (comboQntTotalFlavoursAdded >= selectedComboRef.current.products[comboProductIndex].size.qty_max_flavours && direction === "up") {
          onMessageDialog()
            .fire({
              title: "Quantidade máxima de sabores já selecionado",
              icon: "warning",
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          return null
        }
        //
        const comboNewFlavourIndex = selectedComboRef.current.products[comboProductIndex].flavours.findIndex(
          ({ id_flavour }) => id_flavour === flavour.id_flavour
        )

        if (comboNewFlavourIndex !== -1) {
          if (amount === 0) {
            selectedComboRef.current.products[comboProductIndex].flavours.splice(comboNewFlavourIndex, 1)
          } else {
            selectedComboRef.current.products[comboProductIndex].flavours[comboNewFlavourIndex] = {
              ...selectedComboRef.current.products[comboProductIndex].flavours[comboNewFlavourIndex],
              amount,
            }
          }
          setSelectedCombo({ ...selectedComboRef.current })
          selectedComboRef.current = { ...selectedComboRef.current }
        } else {
          selectedComboRef.current.products[comboProductIndex].flavours.push({
            ...flavour,
            amount,
            additions: [],
            restrictions: [],
            loadingIngredient: true,
            loadingOptional: true,
          })
          setSelectedCombo({ ...selectedComboRef.current })
          selectedComboRef.current = { ...selectedComboRef.current }
          // Carregando os ingredientes e opcionais
          const responseFlavoursIngredients = await FlavourController.fetchOne(
            flavour.uuid_flavour,
            selectedComboRef.current.products[comboProductIndex].uuid_product,
            true,
            false
          )
          const responseFlavoursOptionals = await FlavourController.fetchOne(
            flavour.uuid_flavour,
            selectedComboRef.current.products[comboProductIndex].uuid_product,
            false,
            true
          )
          const newFlavour = {
            ...flavour,
            additions: [],
            restrictions: [],
            ingredients: responseFlavoursIngredients.status === 200 ? responseFlavoursIngredients.data.ingredients : [],
            optionals: responseFlavoursOptionals.status === 200 ? responseFlavoursOptionals.data.optionals : [],
            loadingIngredient: false,
            loadingOptional: false,
          }

          const comboNewFlavourIndex = selectedComboRef.current.products[comboProductIndex].flavours.findIndex(
            ({ id_flavour }) => id_flavour === flavour.id_flavour
          )

          if (comboNewFlavourIndex !== -1) {
            selectedComboRef.current.products[comboProductIndex].flavours[comboNewFlavourIndex] = {
              ...newFlavour,
              amount: selectedComboRef.current.products[comboProductIndex].flavours[comboNewFlavourIndex].amount,
            }

            setSelectedCombo({ ...selectedComboRef.current })
          }
        }
      } else {
        //
      }
    } else {
      let qntTotalFlavoursAdded = selectedProduct.flavours.reduce((tot, current) => {
        return tot + current.amount
      }, 0)

      if (qntTotalFlavoursAdded >= selectedProduct.size.qty_max_flavours && direction === "up") {
        onMessageDialog()
          .fire({
            title: "Quantidade máxima de sabores já selecionado",
            icon: "warning",
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
        return null
      }

      const newFlavourIndex = selectedProduct.flavours.findIndex(({ id_flavour }) => id_flavour === flavour.id_flavour)
      if (newFlavourIndex !== -1) {
        if (amount === 0) {
          selectedProduct.flavours.splice(newFlavourIndex, 1)
        } else {
          selectedProduct.flavours[newFlavourIndex] = {
            ...selectedProduct.flavours[newFlavourIndex],
            amount,
          }
        }
        setSelectedProduct({ ...selectedProduct })
        selectedProductRef.current = { ...selectedProduct }
      } else {
        selectedProduct.flavours.push({ ...flavour, amount, additions: [], restrictions: [], loadingOptional: true, loadingIngredient: true })
        setSelectedProduct({ ...selectedProduct })
        selectedProductRef.current = { ...selectedProduct }
        // Carregando os ingredientes e opcionais
        const responseFlavoursIngredients = await FlavourController.fetchOne(flavour.uuid_flavour, selectedProduct.uuid_product, true, false)
        const responseFlavoursOptionals = await FlavourController.fetchOne(flavour.uuid_flavour, selectedProduct.uuid_product, false, true)
        const newFlavour = {
          ...flavour,
          amount,
          additions: [],
          restrictions: [],
          ingredients: responseFlavoursIngredients.status === 200 ? responseFlavoursIngredients.data.ingredients : [],
          optionals: responseFlavoursOptionals.status === 200 ? responseFlavoursOptionals.data.optionals : [],
          loadingIngredient: false,
          loadingOptional: false,
        }
        const newFlavourIndex = selectedProductRef?.current?.flavours.findIndex(({ id_flavour }) => id_flavour === flavour.id_flavour)
        if (newFlavourIndex !== -1) {
          if (amount === 0) {
            selectedProductRef.current.flavours.splice(newFlavourIndex, 1)
          } else {
            selectedProductRef.current.flavours[newFlavourIndex] = {
              ...newFlavour,
              amount: selectedProductRef.current.flavours[newFlavourIndex].amount,
            }
          }
          setSelectedProduct({ ...selectedProduct, flavours: selectedProductRef.current.flavours })
        }
      }
    }
  }

  const onChangeAmountOptional = (amount, direction, type, optional, id_flavour, nro_item = null, itemIndex = null) => {
    if (!!selectedComboRef.current) {
      const indexProduct = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)
      if (indexProduct !== -1) {
        const comboProduct = selectedComboRef.current.products[indexProduct]
        const indexFlavour = comboProduct.flavours.findIndex((flavour) => flavour.id_flavour === id_flavour && flavour.index === itemIndex)

        // Procurando o index do adicional dentro do sabor para tentar aumentar a quantidade
        const indexOptional = comboProduct.flavours[indexFlavour].additions.findIndex(({ id_ingredient }) => id_ingredient === optional.id_ingredient)
        const totalAdditionalSelected = comboProduct.flavours[indexFlavour].additions.reduce((tot, { amount = 1 }) => tot + amount, 0)

        const selectedFlavourSize = !!comboProduct.flavours[indexFlavour].size
          ? comboProduct.flavours[indexFlavour].size
          : comboProduct.flavours[indexFlavour].sizes.find(({ id_size }) => id_size === comboProduct?.size?.id_size)

        if (
          selectedFlavourSize.flavour_size.qty_optionals > 0 &&
          selectedFlavourSize.flavour_size.qty_optionals <= totalAdditionalSelected &&
          direction === "up"
        ) {
          onMessageDialog()
            .fire({
              title: "Quantidade máxima de opcionais do sabor atingida",
              icon: "warning",
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()

          return null
        }

        if (indexOptional !== -1) {
          const optionalSize = optional.sizes.find(({ id_size }) => id_size === comboProduct.size.id_size)
          if (!optionalSize) {
            onMessageDialog()
              .fire({
                title: "Ocorreu um problema ao selecinoar o opcional, favor entrar em contato com a loja",
                icon: "warning",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          }
          if (amount > optionalSize.ingredient_size.qty_max_allowed) {
            onMessageDialog()
              .fire({
                title: "Quantidade máxima deste opcional atingida",
                icon: "warning",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          } else if (amount < optionalSize.ingredient_size.qty_min_allowed) {
            onMessageDialog()
              .fire({
                title: "Quantidade miníma deste opcional atingida",
                icon: "warning",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          } else {
            if (amount === 0) {
              selectedComboRef.current.products[indexProduct].flavours[indexFlavour].additions.splice(indexOptional, 1)
            } else {
              selectedComboRef.current.products[indexProduct].flavours[indexFlavour].additions[indexOptional] = {
                ...selectedComboRef.current.products[indexProduct].flavours[indexFlavour].additions[indexOptional],
                amount,
              }
            }
          }
        } else {
          selectedComboRef.current.products[indexProduct].flavours[indexFlavour].additions.push({ ...optional, amount })
        }
        setSelectedCombo({ ...selectedComboRef.current })
        selectedComboRef.current = { ...selectedComboRef.current }

        setModalIngredients({
          ...modalIngredients,
          content: {
            ...modalIngredients.content,
            flavour: selectedComboRef.current.products[indexProduct].flavours[indexFlavour],
            size: !!selectedComboRef.current.products[indexProduct] ? selectedComboRef.current.products[indexProduct].size : null,
            itemIndex,
          },
        })
      }
    } else {
      const indexFlavour = selectedProduct.flavours.findIndex((flavour) => {
        //Antes estava inline, porém por algum motivo estava se perdendo para identificar
        const flavourId = flavour.id_flavour === id_flavour
        const flavourIndex = !!flavour.index ? flavour.index === itemIndex : true // No gráfico de pizza, o flavour vai junto com index para controlar corretamente qual sabor está sendo alterado.
        return flavourId && flavourIndex
      })
      // Achou o sabor equivalente para aumentar a quantidade
      if (indexFlavour !== -1) {
        // Procurando o index do adicional dentro do sabor para tentar aumentar a quantidade
        const indexOptional = selectedProduct.flavours[indexFlavour].additions.findIndex(
          ({ id_ingredient }) => id_ingredient === optional.id_ingredient
        )
        const totalAdditionalSelected = selectedProduct.flavours[indexFlavour].additions.reduce((tot, { amount = 1 }) => tot + amount, 0)

        const selectedFlavourSize = !!selectedProduct.flavours[indexFlavour].size
          ? selectedProduct.flavours[indexFlavour].size
          : selectedProduct.flavours[indexFlavour].sizes.find(({ id_size }) => id_size === selectedProduct?.size?.id_size)
        if (selectedFlavourSize.qty_optionals > 0 && selectedFlavourSize.qty_optionals <= totalAdditionalSelected && direction === "up") {
          onMessageDialog()
            .fire({
              title: "Quantidade máxima de opcionais do sabor atingida",
              icon: "warning",
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()

          return null
        }

        if (indexOptional !== -1) {
          const optionalSize = optional.sizes.find(({ id_size }) => id_size === selectedProduct.size.id_size)
          if (!optionalSize) {
            onMessageDialog()
              .fire({
                title: "Ocorreu um problema ao selecinoar o opcional, favor entrar em contato com a loja",
                icon: "warning",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          }
          if (amount > optionalSize.ingredient_size.qty_max_allowed) {
            onMessageDialog()
              .fire({
                title: "Quantidade máxima deste opcional atingida",
                icon: "warning",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          } else if (amount < optionalSize.ingredient_size.qty_min_allowed) {
            onMessageDialog()
              .fire({
                title: "Quantidade miníma deste opcional atingida",
                icon: "warning",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          } else {
            if (amount === 0) {
              selectedProduct.flavours[indexFlavour].additions.splice(indexOptional, 1)
            } else {
              selectedProduct.flavours[indexFlavour].additions[indexOptional] = {
                ...selectedProduct.flavours[indexFlavour].additions[indexOptional],
                amount,
              }
            }
          }
        } else {
          selectedProduct.flavours[indexFlavour].additions.push({ ...optional, amount })
        }
        setSelectedProduct({ ...selectedProduct })
        selectedProductRef.current = { ...selectedProduct }

        setModalIngredients({
          ...modalIngredients,
          content: {
            // ...modalIngredients.content,
            flavour: selectedProduct.flavours[indexFlavour],
            size: !!selectedProduct ? selectedProduct.size : null,
            itemIndex,
          },
        })
      }
    }
  }

  const onChangeAmountAdditional = (amount, type, additional, nro_item, qty_additional) => {
    if (!!selectedComboRef.current) {
      const indexProduct = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)

      if (indexProduct !== -1) {
        const { additionals } = selectedComboRef.current.products[indexProduct]
        const indexNoAdditional = additionals.findIndex(({ nm_additional }) => `${nm_additional}`.toLowerCase() === "sem borda")

        if (qty_additional === 1 || indexNoAdditional !== -1) {
          if (type === "up") {
            if (amount === 1) {
              selectedComboRef.current.products[indexProduct].additionals = [{ ...additional, amount }]
            } else {
              Message.info("Número máximo de adicionais para este item atingido!")
            }
          } else {
            selectedComboRef.current.products[indexProduct].additionals = []
          }
        } else {
          const indexAdditional = selectedComboRef.current.products[indexProduct].additionals.findIndex(
            ({ id_additional }) => id_additional === additional.id_additional
          )

          if (indexAdditional !== -1) {
            selectedComboRef.current.products[indexProduct].additionals.splice(indexAdditional, 1)
          } else if (`${additional.nm_additional}`.toLowerCase() === "sem borda") {
            selectedComboRef.current.products[indexProduct].additionals = [{ ...additional, amount }]
          } else if (additionals.length < qty_additional) {
            selectedComboRef.current.products[indexProduct].additionals.push({ ...additional, amount })
          } else {
            Message.info("Número máximo de adicionais para este item atingido!")
          }
        }
      }

      setSelectedCombo({ ...selectedComboRef.current })
      selectedComboRef.current = { ...selectedComboRef.current }
      checkValidation("additional", nro_item, selectedComboRef.current)
    } else {
      const additionalSize = additional.prices.find(({ fk_id_size }) => fk_id_size === selectedProduct.size.id_size)
      if (!!additionalSize) {
        const indexAdditional = selectedProduct.additionals.findIndex(({ id_additional }) => id_additional === additional.id_additional)
        if (indexAdditional !== -1) {
          if (type === "up" && amount > additionalSize.qty_max_defined) {
            Message.info("Quantidade máxima deste adicional atingida")
          } else if (type === "down" && amount === 0) {
            Message.info("Quantidade miníma deste adicional atingida")
          } else {
            if (amount === 0) {
              selectedProduct.additionals.splice(indexAdditional, 1)
            } else {
              selectedProduct.additionals[indexAdditional] = {
                ...selectedProduct.additionals[indexAdditional],
                amount,
              }
            }
          }

          setSelectedProduct({ ...selectedProduct })
          selectedProductRef.current = { ...selectedProduct }
        } else {
          selectedProduct.additionals.push({ ...additional, amount })
          setSelectedProduct({ ...selectedProduct })
          selectedProductRef.current = { ...selectedProduct }
        }
      }
    }
  }

  const onChangeAmountBeverage = (amount, direction, beverage, type, nro_item) => {
    if (!!selectedComboRef.current) {
      let beveragesAlreadyAdded = 0
      let beveragesMaxAllowedOnComboItem = 0
      let beverageInProductIndex = -1
      let comboBeverageIndex = -1
      let comboProductIndex = -1

      if (type === "beverage") {
        //Verificando quantidade máxima de bebidas dos itens BEBIDAS do combo.
        const beveragesOfBeveragesAllowedOnComboItem = combo.beverage_items.find((beverageItem) => beverageItem.nro_item === nro_item)

        //Identificandoa  bebida  do combo
        comboBeverageIndex = selectedComboRef.current.beverages.findIndex(
          (comboBeverage) => comboBeverage.id_beverage === beverage.id_beverage && comboBeverage.nro_item === nro_item
        )
        if (!beveragesOfBeveragesAllowedOnComboItem) {
          onMessageDialog()
            .fire({
              title: "Falha ao adicionar bebida, favor entrar em contato com a loja",
              icon: "warning",
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          return null
        }
        //Contabilizando quantas bebidas daquele nro_item já existem
        beveragesAlreadyAdded = selectedComboRef.current.beverages.reduce((tot, sum) => {
          return sum.nro_item === nro_item ? sum.amount + tot : 0
        }, 0)

        //Identificando a quantidade máxima de bebida possível para esse item.
        beveragesMaxAllowedOnComboItem = beveragesOfBeveragesAllowedOnComboItem?.qty_max_beverage
      } else if (type === "product") {
        //Verificando a quantidade máxima de bebidas das bebidas que são de um PRODUTO.
        const beveragesOfProductsAllowedOnComboItem = combo.product_items.find((productItem) => productItem.nro_item === nro_item)

        //Identificando o produto do combo
        comboProductIndex = selectedComboRef.current.products.findIndex((comboProduct) => {
          if (comboProduct.nro_item === nro_item) {
            //Separando já a bebida caso selecione ela novamente.
            beverageInProductIndex = comboProduct.beverages.findIndex(({ id_beverage }) => id_beverage === beverage.id_beverage)
            return typeof beverageInProductIndex === "number"
          }
          return false
        })

        if (!beveragesOfProductsAllowedOnComboItem) {
          onMessageDialog()
            .fire({
              title: "Falha ao adicionar bebida, favor entrar em contato com a loja",
              icon: "warning",
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          return null
        }
        //Contabilizando quantas bebidas daquele nro_item já existem
        beveragesAlreadyAdded = selectedComboRef.current.products[comboProductIndex].beverages.reduce((tot, sum) => {
          return sum.nro_item === nro_item ? sum.amount + tot : 0
        }, 0)
        //Identificando a quantidade máxima de bebida possível para esse item.
        beveragesMaxAllowedOnComboItem = beveragesOfProductsAllowedOnComboItem?.qty_max_beverage
      }

      if (beveragesAlreadyAdded >= beveragesMaxAllowedOnComboItem && direction === "up") {
        onMessageDialog()
          .fire({
            title: "Limite de máximo de babidas atingido!",
            icon: "warning",
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Entendido",
          })
          .then()
        return null
      }

      if (type === "beverage") {
        if (comboBeverageIndex !== -1) {
          selectedComboRef.current.beverages[comboBeverageIndex] = { ...selectedComboRef.current.beverages[comboBeverageIndex], amount }
        } else {
          selectedComboRef.current.beverages.push({
            ...beverage,
            amount: 1,
            nro_item,
            id_combo: selectedComboRef.current.id_combo,
            nro_combo: selectedComboRef.current.nro_combo,
          })
        }
        selectedComboRef.current.beverages = selectedComboRef.current.beverages.filter(({ amount }) => amount > 0)
      } else if (type === "product") {
        if (comboProductIndex !== -1 && beverageInProductIndex !== -1) {
          selectedComboRef.current.products[comboProductIndex].beverages[beverageInProductIndex] = {
            ...selectedComboRef.current.products[comboProductIndex].beverages[beverageInProductIndex],
            amount,
          }
        } else {
          selectedComboRef.current.products[comboProductIndex].beverages.push({
            ...beverage,
            amount: 1,
            nro_item,
            id_combo: selectedComboRef.current.id_combo,
            nro_combo: selectedComboRef.current.nro_combo,
          })
        }
        selectedComboRef.current.products[comboProductIndex].beverages = selectedComboRef.current.products[comboProductIndex].beverages.filter(
          ({ amount }) => amount > 0
        )
      }
    } else {
      // Rotina de numbercontrol para quando o produto possui uma bebida.
    }

    setSelectedCombo({ ...selectedComboRef.current })
    selectedComboRef.current = { ...selectedComboRef.current }
    checkValidation("beverage", nro_item, selectedComboRef.current)
  }

  const onDeleteFlavour = (event, order_flavour = null, id_flavour = null, nro_item = null) => {
    event.preventDefault()

    if (!!selectedComboRef.current) {
      const indexProduct = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)
      if (indexProduct !== -1) {
        const indexFlavour = selectedComboRef.current.products[indexProduct].flavours.findIndex((flavour) => flavour.id_flavour === id_flavour)

        if (indexFlavour !== -1) {
          selectedComboRef.current.products[indexProduct].flavours.splice(indexFlavour, 1)

          setSelectedCombo({ ...selectedComboRef.current })
          selectedComboRef.current = { ...selectedComboRef.current }
          checkValidation("flavour", nro_item, selectedComboRef.current)
        }
      }
    } else if (id_flavour === null) {
      const flavoursFiltered = selectedProduct.flavours.filter((item, key) => key !== order_flavour)
      setSelectedProduct({ ...selectedProduct, flavours: flavoursFiltered })
      selectedProductRef.current = { ...selectedProduct, flavours: flavoursFiltered }
    } else {
      const flavoursFiltered = selectedProduct.flavours.filter((item) => item.id_flavour !== id_flavour)
      setSelectedProduct({ ...selectedProduct, flavours: flavoursFiltered })
      selectedProductRef.current = { ...selectedProduct, flavours: flavoursFiltered }
    }
  }

  const setOnFlavour = async (flavour) => {
    let newProduct = { ...selectedProduct }
    // Significa que está utilizando o gráfico de pizza
    if (graph && indexChart !== null) {
      const indexFlavour = newProduct.flavours.findIndex(({ index }) => index === indexChart)

      if (indexFlavour !== -1) {
        newProduct.flavours[indexFlavour] = {
          ...flavour,
          restrictions: [],
          additions: [],
          amount: 1,
          index: indexChart,
        }
      } else {
        const newFlavour = {
          ...flavour,
          restrictions: [],
          additions: [],
          amount: 1,
          index: indexChart,
        }
        newProduct.flavours.push(newFlavour)
      }

      // setSelectedProduct({ ...selectedProduct })

      onCloseModalFlavour()
    } else {
      const { qty_max_flavours } = newProduct.size

      const indexFlavour = newProduct.flavours.findIndex(({ uuid_flavour }) => uuid_flavour === flavour.uuid_flavour)
      if (indexFlavour !== -1) {
        // selectedProduct.flavours.splice(indexFlavour, 1)
        // setSelectedProduct({ ...selectedProduct })
        let hasIngredients = false
        if (!!newProduct.flavours[indexFlavour].ingredients) {
          hasIngredients = newProduct.flavours[indexFlavour].ingredients.length > 0
        }

        let hasOptionals = false
        if (!!newProduct.flavours[indexFlavour].optionals) {
          hasOptionals = newProduct.flavours[indexFlavour].optionals.length > 0
        }

        if (hasIngredients || hasOptionals) {
          setModalIngredients({
            status: true,
            content: {
              flavour: newProduct.flavours[indexFlavour],
              size: !!newProduct.size ? newProduct.size : null,
            },
          })
        }

        if (graph) onCloseModalFlavour()
      } else {
        const flavoursCount = newProduct.flavours.reduce((a, b) => +a + +b.amount, 0)
        const filteredFlavour = product.flavours.find(({ uuid_flavour }) => uuid_flavour === flavour.uuid_flavour)

        let hasIngredients = false
        if (!!filteredFlavour.ingredients) {
          hasIngredients = filteredFlavour.ingredients.length > 0
        }

        let hasOptionals = false
        if (!!filteredFlavour.optionals) {
          hasOptionals = filteredFlavour.optionals.length > 0
        }

        const newFlavour = {
          ...flavour,
          restrictions: [],
          additions: [],
          amount: 1,
          index: indexChart,
          loadingIngredient: true,
          loadingOptional: true,
        }

        if (qty_max_flavours > flavoursCount) {
          newProduct.flavours.push(newFlavour)

          if (graph) {
            onCloseModalFlavour()
          } else if (hasIngredients || hasOptionals) {
            setModalIngredients({
              status: true,
              content: {
                flavour: filteredFlavour,
                size: !!newProduct.size ? newProduct.size : null,
              },
            })
          }
        } else if (qty_max_flavours === 1) {
          newProduct.flavours = [newFlavour]
          // setSelectedProduct({ ...newProduct })
          if (graph) {
            onCloseModalFlavour()
          } else if (hasIngredients.length > 0 || hasOptionals.length > 0) {
            setModalIngredients({
              status: true,
              content: {
                flavour: filteredFlavour,
                size: !!newProduct.size ? newProduct.size : null,
              },
            })
          }
        } else {
          GeneralTools.swalMessage("info")
            .fire({
              title: `Este tamanho permite apenas ${qty_max_flavours} sabores`,
              icon: "info",
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
        }
      }
    }
    setSelectedProduct({ ...newProduct })
    selectedProductRef.current = { ...newProduct }
    //Consulta os ingredientes do sabor

    Promise.all([
      getFlavourProduct(flavour.uuid_flavour, selectedProduct.uuid_product, true, false),
      getFlavourProduct(flavour.uuid_flavour, selectedProduct.uuid_product, false, true),
    ]).then((response) => {
      const [responseFlavoursIngredients, responseFlavoursOptionals] = response

      const ingredientsEnabled = responseFlavoursIngredients.ingredients.filter(({ ingredient_flavour: { st_website } }) => st_website)

      const optionalsEnabled = responseFlavoursOptionals.optionals.filter(({ optional_flavour: { st_website } }) => st_website)
      const flavourIndex = newProduct.flavours.findIndex(({ index }) => index === indexChart)

      newProduct.flavours[flavourIndex] = {
        ...newProduct.flavours[flavourIndex],
        loadingIngredient: false,
        ingredients: ingredientsEnabled,
        loadingOptional: false,
        optionals: optionalsEnabled,
      }

      setSelectedProduct({ ...newProduct })
      selectedProductRef.current = { ...newProduct }
    })

    // getFlavourProduct(flavour.uuid_flavour, selectedProduct.uuid_product, true, false).then((responseFlavoursIngredients) => {
    //   const ingredientsEnabled = responseFlavoursIngredients.ingredients.filter(({ ingredient_flavour: { st_website } }) => st_website)
    //   getFlavourProduct(flavour.uuid_flavour, selectedProduct.uuid_product, false, true).then((responseFlavoursOptionals) => {
    //     const optionalsEnabled = responseFlavoursOptionals.optionals.filter(({ optional_flavour: { st_website } }) => st_website)

    //     const flavourIndex = newProduct.flavours.findIndex(({ index }) => index === indexChart)

    //     newProduct.flavours[flavourIndex] = {
    //       ...newProduct.flavours[flavourIndex],
    //       loadingIngredient: false,
    //       ingredients: ingredientsEnabled,
    //       loadingOptional: false,
    //       optionals: optionalsEnabled,
    //     }

    //     setSelectedProduct({ ...newProduct })
    //     selectedProductRef.current = { ...newProduct }
    //   })
    // })
  }

  const getProductSizes = async (uuid_product) => {
    let sizes = []
    const response = await ProductController.fetchSizes(uuid_product)
    if (response.status === 200) {
      sizes = response.data.sizes
        .filter(({ product_size: { st_website, st_hide_in_builder } }) => {
          return st_website === true && !st_hide_in_builder
        })
        .map(({ beverages, id_size, nm_size, ref_size, uuid_size, product_size }) => {
          return { beverages, id_size, nm_size, ref_size, uuid_size, ...product_size }
        })
        .sort((a, b) => {
          return a.nro_priority < b.nro_priority ? -1 : a.nro_priority > b.nro_priority ? 1 : 0
        })
    }
    return sizes
  }

  const getProductComplements = async (uuid_product) => {
    const response = await ProductController.fetchComplements(uuid_product)
    const localComplements = getLocalItem(`${uuid_product}-complements`, true)
    let complements = []
    if (!!localComplements) {
      complements = localComplements
    }
    if (response.status === 200) {
      // verifica se o complemento está habilitado para o site no escopo geral e ordenar pela prioridade dele.
      complements = response.data.complements
        .filter((complement) => complement.product_complement.st_website)
        .map((complement) => {
          let tempComplement = {
            ...complement,
            ...complement.product_complement,
          }
          delete tempComplement.product_complement

          if (type === "size") {
            tempComplement.size = tempComplement.sizes.find(
              (size) => size.id_size === content.size.id_size && size.complement_size.st_website === true
            )
            if (tempComplement.size === undefined) {
              tempComplement.size = { additional_size: { val_price: 0 } }
            }
            delete tempComplement.sizes
          }

          tempComplement.additionals = tempComplement.additionals.map((eachAdditional) => {
            let tempAdditional = {
              ...eachAdditional,
              ...eachAdditional.complement_additional,
            }
            delete tempAdditional.complement_additional
            if (type === "size") {
              tempAdditional.size = eachAdditional.sizes.find((size) => {
                return size.id_size === content.size.id_size && size.additional_size.st_website === true
              }) || { additional_size: { val_price: 0 } }
              delete tempAdditional.sizes
            }
            return tempAdditional
          })
          return tempComplement
        })
    }

    setLocalItem(`${uuid_product}-complements`, complements, true)
    return complements
  }

  const getIngredients = (ingredients) => {
    return !!ingredients && ingredients.length > 0 ? ingredients.map((item) => item.nm_ingredient).join(", ") : "Sem descrição"
  }

  const getFlavourProduct = async (flavourUid, productUid, withIngredients = false, withOptionals = false) => {
    const response = await FlavourController.fetchOne(flavourUid, productUid, withIngredients, withOptionals)

    if (response.status === 200) {
      const { id_flavour, uuid_flavour, nm_flavour, product_association, sizes, ingredients = [], optionals = [] } = response.data

      return {
        id_flavour,
        uuid_flavour,
        nm_flavour,
        ingredients,
        optionals,
        ...product_association,
        sizes: sizes.map(({ id_size, uuid_size, nm_size, flavour_size }) => {
          return { id_size, uuid_size, nm_size, ...flavour_size }
        }),
      }
    } else {
      return {
        ingredients: [],
        optionals: [],
      }
    }
  }

  const onChangeAmountComplements = async (amount, direction, type, additional, complement) => {
    const findComplementIndex = selectedProduct?.complements?.findIndex(({ id_complement }) => id_complement === complement.id_complement)
    if (direction === "up") {
      let withAmount = false
      let qntComplementsAdded = selectedProduct?.complements[findComplementIndex]?.additionals.reduce((tot, current) => {
        if (current.id_additional === additional.id_additional) {
          withAmount = true
          return tot + amount
        }
        return tot + current.amount
      }, 0)

      if (!withAmount) qntComplementsAdded += amount
      const qntComplementsAllowed = selectedProduct?.complements[findComplementIndex]?.size.complement_size.qty_max_allowed
      //VERIFICAR SE JÁ ATINGIU O LIMITE DOS COMPLEMENTOS
      if (qntComplementsAllowed < qntComplementsAdded) {
        onMessageDialog()
          .fire({
            title: `Quantidade máxima de ${selectedProduct?.complements[findComplementIndex]?.nm_complement} já selecionado`,
            icon: "warning",
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
        return null
      } else {
        if (findComplementIndex !== -1) {
          const additionalOfComplementIndex = selectedProduct?.complements[findComplementIndex]?.additionals.findIndex(
            ({ id_additional }) => id_additional === additional.id_additional
          )
          if (additionalOfComplementIndex !== -1) {
            const selectedAdditionalSize = !!selectedProduct.complements[findComplementIndex].additionals[additionalOfComplementIndex].size
              ? selectedProduct.complements[findComplementIndex].additionals[additionalOfComplementIndex].size
              : selectedProduct?.complements[findComplementIndex]?.additionals[additionalOfComplementIndex]?.sizes?.find(
                  ({ id_size }) => id_size === selectedProduct?.size?.id_size
                )
            //Verificando se o adicional permite mais do mesmo para ser selecionado.
            if (!!selectedAdditionalSize) {
              if (selectedAdditionalSize?.additional_size?.qty_max_allowed < amount) {
                onMessageDialog()
                  .fire({
                    title: `Quantidade máxima de ${selectedProduct.complements[findComplementIndex].additionals[additionalOfComplementIndex].nm_additional} já selecionado`,
                    icon: "warning",
                    timer: 3000,
                    showCancelButton: false,
                    showConfirmButton: false,
                  })
                  .then()
                return null
              } else {
                selectedProduct.complements[findComplementIndex].additionals[additionalOfComplementIndex] = {
                  ...selectedProduct.complements[findComplementIndex].additionals[additionalOfComplementIndex],
                  amount: amount,
                }
              }
            }
          } else {
            selectedProduct.complements[findComplementIndex].additionals = [
              ...selectedProduct.complements[findComplementIndex].additionals,
              {
                ...additional,
                amount: 1,
              },
            ]
          }
        } else {
          //Verificando se a quantidade de complementos do tamanho foram atingidos.
          if (selectedProduct?.complements?.length >= selectedProduct.size.qty_max_complements) {
            onMessageDialog()
              .fire({
                title: `Quantidade máxima de complementos selecionada!`,
                icon: "warning",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
            return null
          }
          const { id_complement, nm_complement, lb_complement, uuid_complement, size = null, sizes = null } = complement
          selectedProduct.complements.push({
            id_complement,
            nm_complement,
            lb_complement,
            uuid_complement,
            size: !!size ? size : sizes.find(({ uuid_size }) => uuid_size === selectedProduct.size.uuid_size),
            additionals: [
              {
                ...additional,
                amount: 1,
              },
            ],
          })
        }
      }
    } else {
      const additionalOfComplementIndex = selectedProduct?.complements[findComplementIndex]?.additionals.findIndex(
        ({ id_additional }) => id_additional === additional.id_additional
      )
      if (additionalOfComplementIndex !== -1) {
        if (amount === 0) {
          selectedProduct?.complements[findComplementIndex]?.additionals?.splice(additionalOfComplementIndex, 1)
        } else {
          selectedProduct.complements[findComplementIndex].additionals[additionalOfComplementIndex].amount = amount
        }
      }
    }
    checkValidation("complement")

    // Limpando os complementos que não possuem adicionais
    selectedProduct.complements = selectedProduct.complements.filter(({ additionals }) => additionals.length > 0)

    setSelectedProduct({ ...selectedProduct })
    selectedProductRef.current = { ...selectedProduct }
  }

  const onChangeComplements = async (selectedAdditional, selectedComplemets, nro_item, selectedComplement) => {
    const { id_complement, nm_complement, lb_complement, uuid_complement, size = null, sizes = null } = selectedComplement

    const selectedComplementIndex = selectedProduct.complements.findIndex(({ id_complement }) => id_complement === selectedComplement.id_complement)
    if (selectedComplementIndex !== -1) {
      //VERIFICANDO SE O COMPLEMENTO PERMITE MAIS DE UM ADICIONAL
      const qntMaxComplements = selectedProduct.complements[selectedComplementIndex]?.size?.complement_size?.qty_max_allowed

      let quantyTotalAdditionalsAdded = selectedProduct.complements[selectedComplementIndex].additionals.reduce((tot, current) => {
        return tot + current.amount
      }, 0)

      if (!!qntMaxComplements) {
        if (qntMaxComplements > 1 && qntMaxComplements > quantyTotalAdditionalsAdded) {
          const selectedAdditionalIndex = selectedProduct.complements[selectedComplementIndex]?.additionals.findIndex(
            ({ id_additional }) => id_additional === selectedAdditional.id_additional
          )

          if (selectedAdditionalIndex !== -1) {
            if (!!selectedProduct.complements[selectedComplementIndex].additionals[selectedAdditionalIndex].size) {
              //SE PERMITIR MAIS DE UM, VERIFICA SE JÁ EXISTE OUTRO ADICIONAL E FOR IGUAL,
              // VERIFICA SE PODE MAIS DE 1 ADICIONAL DO MESMO TIPO, SE PUDER, AUMENTA A QUANTIDADE
              if (
                selectedProduct.complements[selectedComplementIndex].additionals[selectedAdditionalIndex]?.size.additional_size?.qty_max_allowed > 1
              ) {
                selectedProduct.complements[selectedComplementIndex].additionals[selectedAdditionalIndex].amount++
              } else if (
                selectedProduct.complements[selectedComplementIndex].additionals[selectedAdditionalIndex]?.size?.additional_size.qty_max_allowed === 1
              ) {
                if (selectedAdditionalIndex !== -1) {
                  selectedProduct.complements[selectedComplementIndex].additionals.splice(selectedAdditionalIndex, 1)
                }
              }
            } else if (!!selectedProduct.complements[selectedComplementIndex].additionals[selectedAdditionalIndex].sizes) {
              //SE PERMITIR MAIS DE UM, VERIFICA SE JÁ EXISTE OUTRO ADICIONAL E FOR IGUAL, VERIFICA SE PODE MAIS DE 1 ADICIONAL DO MESMO TIPO, SE PUDER, AUMENTA A QUANTIDADE
              if (
                selectedProduct.complements[selectedComplementIndex].additionals[selectedAdditionalIndex]?.sizes.find(
                  ({ id_size }) => id_size === selectedProduct.size.id_size
                )?.additional_size?.qty_max_allowed > 1
              ) {
                selectedProduct.complements[selectedComplementIndex].additionals[selectedAdditionalIndex].amount++
              } else if (
                selectedProduct.complements[selectedComplementIndex].additionals[selectedAdditionalIndex]?.sizes.find(
                  ({ id_size }) => id_size === selectedProduct.size.id_size
                )?.additional_size.qty_max_allowed === 1
              ) {
                if (selectedAdditionalIndex !== -1) {
                  selectedProduct.complements[selectedComplementIndex].additionals.splice(selectedAdditionalIndex, 1)
                }
              }
            }
          } else {
            //SE PERMITIR MAIS DE UM, VERIFICA SE JÁ EXISTE OUTRO ADICIONAL E FOR DIFERENTE, ADICIONA JUNTO AO COMPLEMENTO
            selectedProduct.complements[selectedComplementIndex].additionals = [
              ...selectedProduct.complements[selectedComplementIndex].additionals,
              {
                ...selectedAdditional,
                amount: 1,
              },
            ]
            //Não identificou o adicional no complemento
          }
        } else {
          //VERIFICA SE É O MESMO ADICIONAL, SE FOR, REMOVE ELE.
          const selectedAdditionalIndex = selectedProduct.complements[selectedComplementIndex]?.additionals.findIndex(
            ({ id_additional }) => id_additional === selectedAdditional.id_additional
          )
          if (selectedAdditionalIndex !== -1) {
            selectedProduct.complements[selectedComplementIndex].additionals.splice(selectedAdditionalIndex, 1)
            //Caso o complemento não tenha mais adicionais, então o complemento vai ser removido também do selectedProduct
          } else {
            if (qntMaxComplements === 1) {
              //SE SÓ PERMITIR 1, REMOVE O ANTERIOR, E ADICIONA NO NOVO
              selectedProduct.complements[selectedComplementIndex].additionals = [
                {
                  ...selectedAdditional,
                  amount: 1,
                },
              ]
            } else {
              onMessageDialog()
                .fire({
                  title: `Quantidade máxima de ${selectedProduct.complements[selectedComplementIndex].nm_complement} selecionada!`,
                  icon: "warning",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                })
                .then()
              return null
            }
          }
        }
      } else {
        //não achou o complemento
      }
    } else {
      if (selectedProduct?.complements?.length >= selectedProduct.size.qty_max_complements) {
        onMessageDialog()
          .fire({
            title: `Quantidade máxima de complementos selecionada!`,
            icon: "warning",
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
        return null
      } else {
        selectedProduct.complements.push({
          id_complement,
          nm_complement,
          lb_complement,
          uuid_complement,
          size: !!size ? size : sizes.find(({ uuid_size }) => uuid_size === selectedProduct.size.uuid_size),
          additionals: [
            {
              ...selectedAdditional,
              amount: 1,
            },
          ],
        })
      }
    }
    checkValidation("complement")

    // Limpando os complementos que não possuem adicionais
    selectedProduct.complements = selectedProduct.complements.filter(({ additionals }) => additionals.length > 0)

    setSelectedProduct({ ...selectedProduct })
    selectedProductRef.current = { ...selectedProduct }
  }
  //==================================================
  //Monta os elementos para serem mostrados na tela
  const getNestedItems = (type, haystack) => {
    const result = []
    switch (type) {
      case "combo":
        if (!!selectedComboRef.current) {
          for (const productItem of haystack.product_items) {
            const {
              combo_beverages = [],
              combo_complements = [],
              combo_flavours = [],
              combo_sizes = [],
              id_combo_product,
              nro_item,
              product,
              qty_max_beverage,
              qty_min_beverage,
              st_required_product,
              // st_percentage_calc,
              st_required_beverage,
              // title_complement,
              // title_flavour,
              // title_size,
            } = productItem
            // Ordem dos itens para serem inseridos no nested
            // 0: tamanho
            // 1: sabor
            // 2: complemento
            // 3: bebida

            // Verificando se produto já foi tratado como obrigatório ? Identificando o produto selecionado
            const productCombo = selectedComboRef.current.products.find(
              ({ id_product, nro_item }) => id_product === product.id_product && nro_item === productItem.nro_item
            )
            if (!!productCombo) {
              // Caso tenha apenas um tamanho vinculado ao combo, definirá este tamanho como padrão
              const sizeSelected = !!productCombo && !!productCombo.size ? productCombo.size : combo_sizes.length === 1 ? combo_sizes[0] : null

              //Se existir tamanhos e no mínimo 1 tamanho, monta o nested para os tamanhos.
              if (!!combo_sizes && combo_sizes.length > 1) {
                result.push({
                  nro_item,
                  type: "size",
                  title: "Escolha o(s) tamanho(s)",
                  option: !!st_required_product && st_required_product ? "Obrigatório" : "Opcional",
                  content: combo_sizes,
                  selected: sizeSelected,
                  onClick: (size) => onChangeComboSize(size, nro_item),
                })
                // if (!!sizeSelected) {
                // const newSelectedComboFlavourNumber = selectedComboFlavourNumber.map(({ nro_item, number }) => {
                //   return {
                //     nro_item: nro_item,
                //     number: !!sizeSelected ? sizeSelected.qty_max_flavours - 1 : number,
                //   }
                // })
                // setSelectedComboFlavourNumber(newSelectedComboFlavourNumber)
                // }
              }

              if (!!sizeSelected) {
                const contentGraph =
                  `${productCombo.nm_product}`.toLowerCase().indexOf("pizza") !== -1
                    ? {
                        nro_item,
                        optionsChart,
                        getWidthChart,
                        setModalFlavours,
                        setModalIngredients,
                        onDeleteFlavour: (event, id_flavour) => {
                          onDeleteFlavour(event, null, id_flavour, nro_item)
                        },
                        selectedComboFlavourNumber: selectedComboFlavourNumber.find((item) => item.nro_item === nro_item) || [],
                        onChangeSelectedComboFlavourNumber: (number) => {
                          onChangeSelectedComboFlavourNumber(number, nro_item)
                        },
                        onClickComboFlavour: (event, chartContext, config, nroItem) => {
                          onClickSlice(config, nroItem, combo_flavours)
                        },
                      }
                    : null
                const comboFlavours = combo_flavours.filter(({ sizes = [] }) => {
                  if (sizes.length === 0) return false
                  return !!sizes.find(({ id_size, flavour_size: { st_website } }) => st_website && id_size === sizeSelected.id_size)
                })

                result.push({
                  nro_item,
                  type: "flavour",
                  title: `Escolha o(s) sabore(s)`,
                  option: st_required_product ? "Obrigatório" : "Opcional",
                  content: comboFlavours,
                  selectFlavour: comboFlavours.length === 1,
                  size: sizeSelected,
                  id_combo_product,
                  id_product: product.id_product,
                  selected: !!productCombo ? (productCombo.flavours.length === 1 ? [{ ...productCombo.flavours[0] }] : productCombo.flavours) : [],
                  contentGraph,
                  onClick: (flavour, selected, nro_item, complement, optionType) => {
                    onChangeFlavour(flavour, selected, nro_item, complement, optionType)
                  },
                  onChangeAmount: (amount, direction, type, flavour) => {
                    onChangeAmountFlavour(amount, direction, type, flavour, nro_item, null)
                  },
                  onChangeAmountFlavour: (amount, direction, type, flavour, nroItem, comboProductId) => {
                    onChangeAmountFlavour(amount, direction, type, flavour, nroItem, comboProductId)
                  },
                  onChangeIngredients: (flavour, nroItem) => {
                    const productIndex = selectedComboRef.current.products.findIndex((product) => product.nro_item === nroItem)
                    if (productIndex !== -1) {
                      const productCombo = selectedComboRef.current.products[productIndex]

                      const productComboFlavour = productCombo.flavours.findIndex(({ id_flavour }) => flavour.id_flavour === id_flavour)
                      if (productComboFlavour !== -1) {
                        setModalIngredients({
                          status: true,
                          content: {
                            flavour: productCombo.flavours[productComboFlavour],
                            itemIndex: productIndex,
                            size: selectedComboRef.current.products[productIndex].size,
                            nro_item: nroItem,
                          },
                        })
                      }
                    }
                  },
                })
                // }

                // Se existir complementos e tiver no mínimo 1 complemento, monta o nested para os complementos.
                if (!!combo_complements && combo_complements.length >= 1) {
                  combo_complements.forEach((eachComplement) => {
                    result.push({
                      nro_item,
                      type: "complement",
                      title: !!eachComplement.title_complement ? eachComplement.title_complement : "Escolha o(s) complemento(s)",
                      option: !!st_required_product && st_required_product ? "Obrigatório" : "Opcional",
                      // option: !!sizeSelected ? (sizeSelected.qty_min_complements > 0 ? "Obrigatório" : "Opcional") : "Opcional",
                      content: eachComplement.combo_additionals || [],
                      selected: productCombo.complements,
                      onClick: (additional) =>
                        onChangeAdditional(additional, sizeSelected.qty_max_complements - sizeSelected.qty_min_complements, nro_item, eachComplement),
                      onChangeAmount: (amount, type, additional) => onChangeAmountAdditional(amount, type, additional, nro_item),
                    })
                  })
                }

                // Se existir bebidas e tiver no mínimo 1 bebida, monta o nested para as bebidas
                if (!!combo_beverages && combo_beverages.length > 0) {
                  result.push({
                    nro_item,
                    type: "beverage",
                    title: `Escolha a(s) bebida(s)`,
                    option: (!!st_required_beverage && st_required_beverage) || qty_min_beverage > 0 ? "Obrigatório" : "Opcional",
                    // option: (!!st_required_product && st_required_product) || qty_min_beverage > 0 ? "Obrigatório" : "Opcional",
                    content: combo_beverages,
                    selected: productCombo.beverages,
                    qtyItems: { qty_max_beverage, qty_min_beverage },
                    onClick: (beverage) => {
                      onChangeComboBeverage(beverage, nro_item, { qty_max_beverage, qty_min_beverage }, "product")
                    },
                    onChangeAmountBeverage: (amount, direction, type, beverage) =>
                      onChangeAmountBeverage(amount, direction, beverage, "product", nro_item),
                    onChangeAmount: (amount, type, beverage) => {
                      onChangeAmountBeverage(amount, type, beverage, nro_item, !!sizeSelected ? sizeSelected.qty_beverages : null)
                    },
                  })
                }
              }
            }
          }

          for (const beverageItem of haystack.beverage_items) {
            const { nro_item, qty_max_beverage, qty_min_beverage, st_required_beverage, title_beverage, beverage_types } = beverageItem
            if (!!beverage_types && beverage_types.length >= 1) {
              result.push({
                nro_item,
                type: "beverage",
                title: !!title_beverage ? title_beverage : "Escolha a(s) bebida(s)",
                option: !!st_required_beverage || qty_min_beverage > 0 ? "Obrigatório" : "Opcional",
                content: beverage_types,
                selected: selectedComboRef.current.beverages,
                qtyItems: { qty_max_beverage, qty_min_beverage },
                onClick: (beverage) =>
                  onChangeComboBeverage(
                    beverage,
                    nro_item,
                    {
                      qty_max_beverage,
                      qty_min_beverage,
                    },
                    "beverages"
                  ),
                onChangeAmount: (amount, type, beverage) => onChangeAmountBeverage(amount, type, beverage, "beverage", nro_item),
                onChangeAmountBeverage: (amount, direction, type, beverage) =>
                  onChangeAmountBeverage(amount, direction, beverage, "beverage", nro_item),
              })
            }
          }
        }

        break

      case "size":
        if (!!selectedProduct) {
          let flavours = []
          for (const flavour of haystack.flavours) {
            //Verifica se o sabor está habilitado para o tamanho selecionado.
            const isShowable = flavour.sizes.find(({ id_size }) => id_size === selectedProduct.size.id_size)
            if (isShowable) {
              flavours.push({
                ...flavour,
                sizes: flavour.sizes.map(({ flavour_size, id_size, nm_size, uuid_size }) => {
                  return { id_size, nm_size, uuid_size, ...flavour_size }
                }),
              })
            }
          }

          result.push({
            type: "flavour",
            title: "Escolha seu(s) sabor(es)",
            content: flavours,
            option: selectedProduct.size.qty_min_flavours > 0 ? "Obrigatório" : "Opcional",
            selected: selectedProduct.flavours,
            size: selectedProduct.size,
            //flavour, selected, nro_item, complement, optionType, index
            onClick: (flavour, selected, nro_item, complement, optionType, index) =>
              onChangeFlavour(flavour, selected, nro_item, complement, optionType, index),
            onChangeAmount: onChangeAmountFlavour,
            onChangeAmountFlavour: onChangeAmountFlavour,
            onChangeIngredients: (flavour, nroItem) => {
              const selectedFlavourIndex = selectedProduct.flavours.findIndex(({ id_flavour }) => flavour.id_flavour === id_flavour)

              if (selectedFlavourIndex !== -1) {
                setModalIngredients({
                  status: true,
                  content: {
                    flavour: selectedProduct.flavours[selectedFlavourIndex],
                    // itemIndex: productIndex,
                    size: selectedProduct.size,
                    nro_item: nroItem,
                  },
                })
              }
            },
          })
          for (const complement of haystack.complements) {
            if (complement.st_website === true) {
              const selectedComplementSize = !!complement.size
                ? complement.size
                : complement.sizes.find(({ id_size }) => id_size === selectedProduct.size.id_size)
              if (!!selectedComplementSize) {
                const { id_size = 0, st_website = false } = selectedComplementSize

                if (id_size === selectedProduct.size.id_size && st_website === true) {
                  const additionalsFiltered = complement.additionals.filter(
                    (size) => size.st_website === true && size.additional_size.st_website === true
                  )

                  result.push({
                    type: "complement",
                    title: !!complement.title_complement
                      ? complement.title_complement
                      : !!complement.lb_complement
                      ? complement.lb_complement
                      : complement.nm_complement,
                    content: additionalsFiltered,
                    selected: selectedProduct.additionals,
                    option: selectedProduct.size.qty_min_complements > 0 ? "Obrigatório" : "Opcional",
                    size: selectedProduct.size,
                    onClick: onChangeAdditional,
                    onChangeAmount: onChangeAmountAdditional,
                  })
                }
              }
            }
          }
        }
        break

      case "flavour":
        if (!!selectedProduct) {
          //No Haystack vão estar os tamanhos que estão habilitados para o site
          if (haystack.sizes.length > 1) {
            result.push({
              type: "size",
              title: "Selecione um tamanho",
              option: "Obrigatório",
              selectFlavour: selectedProduct.flavours.length === 1,
              content: selectedProduct?.flavours[0]?.sizes?.map((flavourSize) => {
                return {
                  ...flavourSize,
                  val_price: flavourSize.val_price + (selectedProduct?.size?.val_price || 0),
                }
              }),
              size: selectedProduct.size,
              selected: selectedProduct.size,
              onClick: onChangeSizeByFlavour,
            })
          }

          selectedProduct.flavours.forEach((flavour) => {
            const enabledIngredients = flavour.ingredients.filter(
              ({ ingredient_flavour: { st_website }, sizes }) =>
                st_website && sizes.find(({ uuid_size }) => uuid_size === selectedProduct.size.uuid_size)?.ingredient_size?.st_website
            )
            if (enabledIngredients.length > 0) {
              result.push({
                type: "ingredient",
                title: "Deseja REMOVER algum ingrediente?",
                option: "Opcional",
                selectedFlavour: flavour,
                content: enabledIngredients,
                selected: flavour.restrictions,
                size: selectedProduct.size,
                onClick: onChangeIngredient,
              })
            }

            const enabledOptionals = flavour.optionals.filter(
              ({ optional_flavour: { st_website }, sizes }) =>
                st_website && sizes.find(({ uuid_size }) => uuid_size === selectedProduct.size.uuid_size)?.ingredient_size?.st_website
            )
            if (enabledOptionals.length > 0) {
              result.push({
                type: "optional",
                title: "Deseja adicionar algo?",
                option: "Opcional",
                onChangeAmount: (amount, direction, type, optional, id_flavour, nro_item, itemIndex) => {
                  onChangeAmountOptional(amount, direction, type, optional, flavour.id_flavour, nro_item, itemIndex)
                },
                selectedFlavour: flavour,
                content: enabledOptionals,
                selected: flavour.additions,
                size: selectedProduct.size,
                onClick: onChangeOptional,
              })
            }
          })
        }
        break

      case "complement": {
        for (const complement of haystack) {
          let validationSize = false
          let complementSize = null
          if (!!complement.size) {
            complementSize = complement.size
          } else {
            const complementSizeSelected = complement.sizes.find(({ id_size }) => id_size === selectedProduct.size.id_size)
            if (!!complementSizeSelected) {
              complementSize = complementSizeSelected
            }
          }
          if (!complementSize) continue
          validationSize = complementSize.id_size === selectedProduct.size.id_size

          //Verificando se o complemento está habilitado para o site
          if (!!content && complement.st_website === true && validationSize) {
            const { id_complement, nm_complement, lb_complement, additionals, size = null, sizes = null, title_complement } = complement
            const { complements } = selectedProduct
            const enabledAdditionals = additionals.filter(
              ({ st_website, size, sizes }) =>
                st_website &&
                (size?.additional_size?.st_website ||
                  (!!sizes && sizes.find(({ uuid_size }) => uuid_size === selectedProduct.size.uuid_size)?.additional_size?.st_website))
            )
            result.push({
              complement: complement,
              complementId: id_complement,
              type: "complement",
              group: complement.group_complement,
              option: complementSize?.complement_size?.qty_min_allowed > 0 ? "Obrigatório" : "Opcional",
              title: !!title_complement ? title_complement : !!lb_complement ? lb_complement : nm_complement,
              content: enabledAdditionals,
              selected: complements,
              size: !!selectedProductRef.current ? selectedProductRef.current.size : !!size ? size : selectedProduct.size,
              onClick: onChangeComplements,
              onChangeAmountComplement: onChangeAmountComplements,
            })
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        //Nada
        break
      }
    }
    return result
  }
  //==================================================
  const validateComplementsSelecteds = () => {
    let finishValidations = []
    let status = false

    // A principio não é mais utilizado o product additionals.
    if (!!product.additionals) {
      const complementsValidation = product.additionals.map((complement) => {
        //Verifica no mínimo quantos complementos devem ser selecionados
        let qntMinAdditionals = 0
        if (!!complement.sizes) {
          qntMinAdditionals = complement.sizes.find(({ id_size }) => id_size === selectedProduct.size.id_size).complement_size.qty_min_allowed
        } else if (!!complement.size) {
          qntMinAdditionals = complement.size.complement_size.qty_min_allowed
        }
        //Se a quantidade de complementos for no mínimo 1, validar se foi selecionado complemento.
        if (qntMinAdditionals > 0) {
          const select = selectedProduct.complements.find(({ id_complement }) => id_complement === complement.id_complement)
          return select !== undefined
        } else {
          return true
        }
      })

      status = complementsValidation.every((compl) => compl === true)
    } else {
      status = true
    }

    if (!!product.complements) {
      // Fazendo filtro dos complementos para validação.
      const validItens = getNestedItems("complement", product.complements)
        .filter(({ content }) => content.length > 0)
        .filter(
          ({ group, complementId }) =>
            !group ||
            (!!group && !!selectedProduct.group_selected && selectedProduct.group_selected === complementId) ||
            (!!group && !selectedProduct.group_selected)
        )

      const filteredComplements = product.complements.filter(
        ({ id_complement }) => !!validItens.find(({ complementId }) => complementId === id_complement)
      )

      if (filteredComplements.length > 0) {
        filteredComplements.forEach((complement) => {
          if (!!complement.sizes) {
            const validateComplement = complement.sizes.find(({ uuid_size }) => selectedProduct.size.uuid_size === uuid_size)
            if (!!validateComplement.complement_size && validateComplement.complement_size.qty_min_allowed > 0) {
              const selected = selectedProduct.complements.find((selectedComplement) => {
                return selectedComplement.id_complement === complement.id_complement
              })

              if (!!selected) {
                let quantyTotalAdditionalsAdded = selected.additionals.reduce((tot, current) => {
                  return tot + current.amount
                }, 0)
                if (validateComplement.complement_size.qty_min_allowed <= quantyTotalAdditionalsAdded) {
                  status = true
                } else {
                  finishValidations.push({
                    type: "complement",
                    id_complement: complement.id_complement,
                    id_product: product.id_product,
                  })
                  status = false
                }
              } else {
                finishValidations.push({
                  type: "complement",
                  id_complement: complement.id_complement,
                  id_product: product.id_product,
                })
                status = false
              }
            } else {
              status = true
            }
          } else if (!!complement.size) {
            if (!!complement.size.complement_size && complement.size.complement_size.qty_min_allowed > 0) {
              const selected = selectedProduct.complements.find((selectedComplement) => {
                return selectedComplement.id_complement === complement.id_complement
              })
              if (!!selected) {
                let quantyTotalAdditionalsAdded = selected.additionals.reduce((tot, current) => {
                  return tot + current.amount
                }, 0)
                if (complement.size.complement_size.qty_min_allowed <= quantyTotalAdditionalsAdded) {
                  status = true
                } else {
                  finishValidations.push({
                    type: "complement",
                    id_complement: complement.id_complement,
                    id_product: product.id_product,
                  })
                  status = false
                }
              } else {
                finishValidations.push({
                  type: "complement",
                  id_complement: complement.id_complement,
                  id_product: product.id_product,
                })
                status = false
              }
            } else {
              status = true
            }
          }
        })
      } else {
        status = true
      }
    } else {
      status = true
    }
    setValidations(finishValidations)
    return status && finishValidations.length === 0
  }

  const finishBuilderOrder = () => {
    if (!!selectedComboRef.current) {
      let validations = []

      // Verificando os itens do combo
      for (const productItem of combo.product_items) {
        const {
          nro_item,
          st_required_product,
          combo_beverages = [],
          combo_complements = [],
          // combo_flavours,
          // combo_sizes,
          // id_combo_product,
          // product,
          // qty_max_beverage,
          // qty_min_beverage,
          // st_percentage_calc,
          // st_required_beverage,
          // title_complement,
          // title_flavour,
          // title_size,
        } = productItem

        const productSelected = selectedComboRef.current.products.find((product) => product.nro_item === nro_item)
        // const productSelectedIndex = selectedComboRef.current.products.findIndex((product) => product.nro_item === nro_item)

        if (!!productSelected && !!st_required_product) {
          //Verifica se o tamanho está selecionado
          if (!productSelected.size) {
            validations.push({
              type: "size",
              nro_item,
              message: `Faltou selecionar um tamanho para o item ${GeneralTools.setNumber(nro_item, 2)}`,
            })
          }

          // Verificando se todos os sabores estão preenchidos conforme a quantidade de sabores
          let quantyTotalFlavoursAdded = productSelected.flavours.reduce((tot, current) => {
            return tot + current.amount
          }, 0)

          if (quantyTotalFlavoursAdded < productSelected?.size?.qty_min_flavours) {
            validations.push({
              type: "flavour",
              nro_item,
              message: `Faltou informar ${selectedFlavourNumber > 0 ? "os sabores" : "o sabor"} para o item ${GeneralTools.setNumber(nro_item, 2)}`,
            })
          } else if (productSelected?.size?.qty_max_flavours < quantyTotalFlavoursAdded) {
            validations.push({
              type: "flavour",
              nro_item,
              message: `Quantidade de sabores excedido o limite permitido para o item ${GeneralTools.setNumber(nro_item, 2)}`,
            })
          }

          const filteredComplements = combo_complements.filter(
            ({ combo_additionals, size, sizes }) =>
              combo_additionals.filter(({ st_website }) => st_website).length > 0 &&
              ((!!size && size?.complement_size?.st_website && size.uuid_size === productSelected.size.uuid_size) ||
                (!!sizes && sizes.find(({ uuid_size }) => uuid_size === productSelected.size.uuid_size)?.complement_size?.st_website))
          )

          if (filteredComplements.length > 0 && filteredComplements.length > productSelected.complements.length) {
            validations.push({
              type: "complement",
              nro_item,
              message: `Faltou o(s) complemento(s) para o item ${GeneralTools.setNumber(nro_item, 2)}`,
            })
          }

          // Verificando as bebidas

          const filteredBeverages = combo_beverages.filter(({ st_website }) => st_website)
          if (
            filteredBeverages.length > 0 &&
            (productSelected.qty_max_beverage < productSelected.beverages.length ||
              productSelected.qty_min_beverage > productSelected.beverages.length)
          ) {
            validations.push({
              type: "beverage",
              nro_item,
              message: `Faltou selecionar a(s) bebida(s) para o item ${GeneralTools.setNumber(nro_item, 2)}`,
            })
          }
        }
      }

      // Verificando as bebidas do combo
      for (const beverageItem of combo.beverage_items) {
        const { nro_item, qty_max_beverage, qty_min_beverage, st_required_beverage } = beverageItem

        // const selectedBeverage = selectedComboRef.current.beverages.find(({ nro_item: itemNumber }) => itemNumber === nro_item)
        if (!!st_required_beverage) {
          const beveragesAdded = selectedComboRef.current.beverages.reduce((tot, sum) => {
            return sum.nro_item === nro_item ? (sum?.amount || 1) + tot : tot
          }, 0)
          if (beveragesAdded > 0) {
            if (beveragesAdded > qty_max_beverage) {
              validations.push({
                type: "beverage",
                nro_item,
                message: `Quantidade de bebidas a cima do valor máximo permitido, item ${GeneralTools.setNumber(nro_item, 2)}`,
              })
            } else if (beveragesAdded < qty_min_beverage) {
              validations.push({
                type: "beverage",
                nro_item,
                message: `Quantidade de bebidas a baixo do valor mínimo permitido, item ${GeneralTools.setNumber(nro_item, 2)}`,
              })
            }
          } else {
            validations.push({
              type: "beverage",
              nro_item,
              message: `Faltou selecionar a(s) bebida(s) para o item ${GeneralTools.setNumber(nro_item, 2)}`,
            })
          }
        }
      }

      // Remover items opcionais que não foi escolhido sabor.
      const filteredProducts = selectedComboRef.current.products.filter(({ flavours = [] }) => flavours.length > 0)

      //Verificando se nenhum item foi selecionado do combo
      //Para impedir que o cliente abra o combo e clique em adicionar a sacola sem escolher nenhuma opção.
      if (filteredProducts.length === 0 && selectedComboRef.current.beverages.length === 0) {
        validations.push({
          type: "product",
          message: `Não foi selecionado nenhum item do combo, por favor, escolha ao menos um dos produtos.`,
        })
        Message.info("Não foi selecionado nenhum item do combo, por favor, escolha ao menos um dos produtos.")
        return null
      }

      if (validations.length > 0) {
        GeneralTools.swalMessage("info")
          .fire({
            title: `Faltou selecionar alguns items obrigatórios do combo!`,
            icon: "info",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
        setValidations(validations)
      } else {
        onChangeOrder(
          combo,
          { ...selectedComboRef.current, products: filteredProducts },
          { comboNro: content?.combo?.nro_combo, comboId: content?.combo?.id_combo }
        )
        ReactPixel.track("AddToCart", { item_name: combo?.nm_combo })
        setSelectedCombo(null)
        selectedComboRef.current = null
        onClose()
      }
    } else {
      let validateComplements = validateComplementsSelecteds()
      let quantyTotalFlavoursAdded = selectedProduct.flavours.reduce((tot, current) => {
        return tot + current.amount
      }, 0)
      if (quantyTotalFlavoursAdded < selectedProduct?.size?.qty_min_flavours) {
        GeneralTools.swalMessage("info")
          .fire({
            title: `Faltou informar ${selectedFlavourNumber > 0 ? "os sabores" : "o sabor"}!`,
            icon: "info",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
      } else if (selectedProduct?.size?.qty_max_flavours < quantyTotalFlavoursAdded) {
        GeneralTools.swalMessage("info")
          .fire({
            title: `Quantidade de sabores excedido o limite permitido!`,
            icon: "info",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
      } else if (!validateComplements) {
        GeneralTools.swalMessage("info")
          .fire({
            title: `Faltou informar os complementos`,
            icon: "info",
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
      } else {
        onChangeOrder(product, selectedProduct)

        if (!selectedProduct.size.beverages || selectedProduct.size.beverages.length <= 0) {
          setSelectedProduct(null)
          selectedProductRef.current = null
          setModalAdditional({ state: false, content: null })
          // setFlavourPage({ currentPage: 0, hasNextPage: true })
          ReactPixel.track("AddToCart", { item_name: product?.nm_product })

          onCloseModal()
        }
      }
    }
  }

  const onChangeSelectedGroupComplement = (complement, status) => {
    let group_selected = 0

    //Limpando o complemento selecionado
    if (!!selectedProduct.group_selected) {
      const complementIndexOnSelectedProduct = selectedProduct.complements.findIndex(
        ({ id_complement }) => id_complement === selectedProduct.group_selected
      )
      if (complementIndexOnSelectedProduct !== -1) {
        selectedProduct.complements.splice(complementIndexOnSelectedProduct, 1)
      }
    }

    if (complement.complementId !== selectedProduct.group_selected) {
      group_selected = complement.complementId
    }
    setSelectedProduct({ ...selectedProduct, group_selected })
    selectedProductRef.current = { ...selectedProduct, group_selected }
  }

  const getTotalCombo = () => {
    if (!!selectedComboRef.current) {
      const { val_price } = selectedComboRef.current

      let result = [GeneralTools.parseFloat(val_price) || 0]

      if (!!selectedComboRef.current.products) {
        for (const { flavours, beverages, complements, size, nro_item, st_percentage_calc } of selectedComboRef.current.products) {
          result.push(size?.val_price || 0)
          let flavoursHigherValues = [0]
          const flavoursExceeded = flavours.map((flavour) => {
            const optionalsExceed = flavour.additions.map((additional) => {
              const optionalSize = additional.sizes.find(({ uuid_size }) => uuid_size === size.uuid_size)
              return GeneralTools.parseFloat(optionalSize.ingredient_size?.val_price)
            })
            const flavoursWithOptionals = optionalsExceed.reduce((a, b) => a + b, 0)

            if (!!size) {
              if (size.st_higher_price) {
                //Quando a config de maior sabor estiver habilitada, ele não pode adicionar o valor do sabor ao cálculo nesta etapa
                // Apenas o valor dos seus adicionais.
                flavoursHigherValues.push(flavour.val_price)
                return flavoursWithOptionals
              } else {
                if (!!size.st_percentage_calc && !!st_percentage_calc) {
                  //Tanto o tamanho, quando a configuração do combo devem estar para calcular percentual o
                  // do valor do sabor excedente do combo.
                  const flavourValue =
                    ((flavoursWithOptionals + flavour.val_price) * (flavour?.amount || 1)) /
                    ((selectedComboFlavourNumber.find((product) => product.nro_item === nro_item)?.number || 0) + 1)
                  return flavourValue
                } else {
                  const flavourValue = (flavoursWithOptionals + flavour.val_price) * (flavour?.amount || 1)
                  return flavourValue
                }
              }
            } else {
              return 0
            }
          })

          const complementsExceeded = complements.map(({ val_price, additionals }) => {
            const additionalsExceeded = additionals.map(({ val_price }) => GeneralTools.parseFloat(val_price))
            const complementWithAdditionals = [...additionalsExceeded, val_price]
            return GeneralTools.parseFloat(complementWithAdditionals)
          })

          //Para rotina de pagamento pelo maior valor do sabor, será ordenado o array com os valores dos sabores para pegar o maior valor.
          if (flavoursHigherValues.length > 1) {
            flavoursHigherValues = flavoursHigherValues.sort((a, b) => b - a)
          }

          const beveragesExceeded = beverages.map(({ val_price }) => GeneralTools.parseFloat(val_price))

          result = [...result, ...flavoursExceeded, flavoursHigherValues[0], ...complementsExceeded, ...beveragesExceeded]
        }
      }

      if (!!selectedComboRef.current.beverages) {
        for (const beverageItem of selectedComboRef.current.beverages) {
          result.push(GeneralTools.parseFloat(beverageItem?.val_price || 0))
        }
      }
      return GeneralTools.formatMoney((result.length > 0 ? result.reduce((sum, number) => sum + number) : 0.0) * selectedComboRef.current.amount)
    }

    return GeneralTools.formatMoney(0)
  }

  const getTotalAdditionals = (additionals, size) => {
    let totalAdditionals = []
    if (!!additionals) {
      for (let additional of additionals) {
        const filteredFlavourIndex = additional.sizes.findIndex(
          ({ id_size, additional_size: { st_website } }) => id_size === size.id_size && st_website
        )
        totalAdditionals.push(additional.sizes[filteredFlavourIndex].additional_size.val_price / selectedFlavourNumber)

        // Obtendo o valor do adicional
        // if (!!additional.prices && additional.prices.length > 0) {

        //     const additionalPrice = additional.prices.find(({ fk_id_size }) => fk_id_size === size.id_size)

        //     if (!!additionalPrice) {
        //         totalAdditionals.push(GeneralTools.parseFloat(additionalPrice.val_price * additional.amount))
        //     }
        // }
      }
    }
    return totalAdditionals.length > 0 ? totalAdditionals.reduce((sum, number) => sum + number) / selectedFlavourNumber : 0
  }

  const checkValidation = (type, nro_item, selectedCombo = null) => {
    const validationIndex = validations.findIndex((validation) => validation.nro_item === nro_item && validation.type === type)

    if (validationIndex !== -1 && selectedCombo !== null) {
      const productIndex = selectedCombo.products.findIndex((product) => product.nro_item === nro_item)

      if (productIndex !== -1) {
        const productItem = combo.product_items.find((product) => product.nro_item === nro_item)

        if (!!productItem) {
          const { combo_additionals, combo_beverages, qty_beverage, qty_additional, st_required_additional, st_required_beverage } = productItem

          const { size, flavours, additionals, beverages } = selectedCombo.products[productIndex]

          switch (type) {
            case "size":
              // Verificando os tamanhos
              if (!size) {
                validations[validationIndex] = {
                  type: "size",
                  nro_item,
                  message: `Faltou selecionar um tamanho para o item ${GeneralTools.setNumber(nro_item, 2)}`,
                }
              } else {
                validations.splice(validationIndex, 1)
              }

              setValidations([...validations])

              break
            case "flavour":
              // Verificando os sabores
              if (flavours.length < 1) {
                validations[validationIndex] = {
                  type: "flavour",
                  nro_item,
                  message: `Faltou selecionar um sabor para o item ${GeneralTools.setNumber(nro_item, 2)}`,
                }
              } else {
                validations.splice(validationIndex, 1)
              }
              setValidations([...validations])
              break
            case "additional":
              // Verificando os adicionais
              if (
                !!st_required_additional &&
                combo_additionals.length > 0 &&
                additionals.length < qty_additional &&
                additionals.reduce((acc, curr) => curr.amount + acc, 0) < qty_additional &&
                !additionals.find(({ nm_additional }) => `${nm_additional}`.toLowerCase().trim() === "sem borda")
              ) {
                validations[validationIndex] = {
                  type: "additional",
                  nro_item,
                  message: `Faltou selecionar ${
                    qty_additional === 1 ? qty_additional + " adicional" : qty_additional + " adicionais"
                  } para o item ${GeneralTools.setNumber(nro_item, 2)}`,
                }
              } else {
                validations.splice(validationIndex, 1)
              }
              setValidations([...validations])
              break
            default:
              // Verificando as bebidas
              if (
                !!st_required_beverage &&
                combo_beverages.length > 0 &&
                beverages.length < qty_beverage &&
                beverages.reduce((acc, curr) => curr.amount + acc, 0) < qty_beverage &&
                !beverages.find(({ nm_beverage }) => `${nm_beverage}`.toLowerCase().trim() === "sem bebida")
              ) {
                validations[validationIndex] = {
                  type: "beverage",
                  nro_item,
                  message: `Faltou selecionar ${
                    qty_beverage === 1 ? qty_beverage + " bebida" : qty_beverage + " bebidas"
                  } para o item ${GeneralTools.setNumber(nro_item, 2)}`,
                }
              } else {
                validations.splice(validationIndex, 1)
              }

              setValidations([...validations])
              break
          }
        }
      } else {
        const beverageIndex = selectedCombo.beverages.findIndex((beverage) => beverage.nro_item === nro_item)

        if (beverageIndex !== -1) {
          const beverageItem = combo.beverage_items.find((beverage) => beverage.nro_item === nro_item)

          if (!!beverageItem) {
            const { qty_allowed, st_required } = beverageItem

            if (
              !!st_required &&
              selectedCombo.beverages.length < qty_allowed &&
              selectedCombo.beverages.reduce((acc, curr) => curr.amount + acc, 0) < qty_allowed &&
              !selectedCombo.beverages.find(({ nm_beverage }) => `${nm_beverage}`.toLowerCase().trim() === "sem bebida")
            ) {
              validations[validationIndex] = {
                type: "beverage",
                nro_item,
                message: `Faltou selecionar ${
                  qty_allowed === 1 ? qty_allowed + " bebida" : qty_allowed + " bebidas"
                } para o item ${GeneralTools.setNumber(nro_item, 2)}`,
              }
            } else {
              validations.splice(validationIndex, 1)
            }

            setValidations([...validations])
          }
        }
      }
    } else {
      const validationIndex = validations.findIndex((item) => item.type === type)
      validations.splice(validationIndex, 1)
      setValidations([...validations])
    }
  }

  const onOpenIngredientsModalFromLabel = (flavour, key) => {
    if (!flavour.loadingIngredient || !flavour.loadingOptional) {
      setModalIngredients({
        status: true,
        content: {
          flavour,
          size: !!selectedProduct ? selectedProduct.size : null,
          itemIndex: key,
        },
      })
    }
  }

  const getWidthChart = () => {
    if (window.innerWidth <= 380) {
      return 250
    } else if (window.innerWidth <= 480) {
      return 300
    } else if (window.innerWidth <= 600) {
      return 300
    } else if (window.innerWidth <= 768) {
      return 400
    } else if (window.innerWidth <= 900) {
      return 300
    } else if (window.innerWidth <= 1024) {
      return 350
    } else if (window.innerWidth <= 1280) {
      return 300
    } else if (window.innerWidth <= 1600) {
      return 300
    } else if (window.innerWidth <= 1920) {
      return 400
    } else if (window.innerWidth <= 2560) {
      return 500
    } else {
      return 600
    }
  }

  const getTotalCartValue = () => {
    const productAmount = selectedProduct.amount || 1
    const totalFlavours = getTotalFlavours(
      selectedProduct?.flavours,
      selectedProduct?.size,
      null,
      null,
      null,
      null,
      graph ? selectedFlavourNumber + 1 : null
    )
    const totalComplements = !!selectedProduct.complements ? getTotalComplements(selectedProduct?.complements, selectedProduct?.size) : 0
    const totalAdditionals = !!selectedProduct.additionals ? getTotalAdditionals(selectedProduct?.additionals, selectedProduct?.size) : 0
    const totalSize = selectedProduct.size.val_price || 0

    return `(${GeneralTools.formatMoney(productAmount * (totalFlavours + totalSize + totalComplements + totalAdditionals))})`
  }

  if (!status || !content) {
    return ""
  }
  return (
    <Modal isOpen={status} toggle={onCloseModal} className={"builder"} backdrop={"static"} fade={false}>
      <ModalBody className={"builder--body"}>
        {/* Esta é a parte para a escolha dos itens do pedido, e também da observação do item */}
        <div className={"left-side-builder" + (isDefaultCard ? " hide-left-side-builder" : "")}>
          {!selectedProduct && !selectedCombo && (
            <>
              <Skeleton style={{ width: "100vw", marginTop: "-20px" }} height={464} />

              <div className="skeleton-container">
                <Skeleton style={{ width: "100%", marginTop: "8px" }} height={27} />
                <Skeleton style={{ width: "100%" }} height={82} />
                <div className="skeleton-container--item" style={{ marginTop: "30px" }}>
                  <Skeleton style={{ width: "100%" }} height={80} width={80} />
                  <div className="skeleton-container--item--right">
                    <Skeleton style={{ width: "100%" }} height={16} />
                    <div>
                      <Skeleton style={{ width: "100%" }} height={14} />
                      <Skeleton style={{ width: "100%" }} height={14} />
                      <Skeleton style={{ width: "100%" }} height={14} />
                    </div>

                    <Skeleton style={{ width: "100%" }} height={16} />
                  </div>
                </div>
                <div className="skeleton-container--item" style={{ marginTop: "30px" }}>
                  <Skeleton style={{ width: "100%" }} height={80} width={80} />
                  <div className="skeleton-container--item--right">
                    <Skeleton style={{ width: "100%" }} height={16} />
                    <div>
                      <Skeleton style={{ width: "100%" }} height={14} />
                      <Skeleton style={{ width: "100%" }} height={14} />
                      <Skeleton style={{ width: "100%" }} height={14} />
                    </div>

                    <Skeleton style={{ width: "100%" }} height={16} />
                  </div>
                </div>
                <div className="skeleton-container--item" style={{ marginTop: "30px" }}>
                  <Skeleton style={{ width: "100%" }} height={80} width={80} />
                  <div className="skeleton-container--item--right">
                    <Skeleton style={{ width: "100%" }} height={16} />
                    <div>
                      <Skeleton style={{ width: "100%" }} height={14} />
                      <Skeleton style={{ width: "100%" }} height={14} />
                      <Skeleton style={{ width: "100%" }} height={14} />
                    </div>

                    <Skeleton style={{ width: "100%" }} height={16} />
                  </div>
                </div>
                <div className="skeleton-container--item" style={{ marginTop: "30px" }}>
                  <Skeleton style={{ width: "100%" }} height={80} width={80} />
                  <div className="skeleton-container--item--right">
                    <Skeleton style={{ width: "100%" }} height={16} />
                    <div>
                      <Skeleton style={{ width: "100%" }} height={14} />
                      <Skeleton style={{ width: "100%" }} height={14} />
                      <Skeleton style={{ width: "100%" }} height={14} />
                    </div>

                    <Skeleton style={{ width: "100%" }} height={16} />
                  </div>
                </div>
              </div>
            </>
          )}

          {(() => {
            if (!!content) {
              switch (type) {
                case "combo":
                  const nestedItems = getNestedItems("combo", combo)
                  let filteredNestedItems = []

                  nestedItems.forEach((item) => {
                    const duplicated = filteredNestedItems.findIndex((filtered) => {
                      return filtered.nro_item === item.nro_item && filtered.type === item.type && filtered.type !== "complement"
                    })

                    if (duplicated === -1) {
                      filteredNestedItems.push({ ...item, content: item.content.filter(({ st_website }) => st_website) })
                    }
                  })

                  filteredNestedItems = filteredNestedItems
                    .filter(
                      ({ type, content }) =>
                        ((type === "beverage" || type === "complement") && content.length > 0) || (type !== "beverage" && type !== "complement")
                    )
                    .sort((a, b) => a.id_combo_product - b.id_combo_product)
                    .sort((a, b) => a.nro_item - b.nro_item)

                  if (loadingFlavours || loadingSizes || loading) {
                    return filteredNestedItems.map((_item, idx) => {
                      return (
                        <div className="skeleton-container" key={idx}>
                          <Skeleton style={{ width: "100%" }} height={34} />
                          <div className="skeleton-container--item">
                            <Skeleton style={{ width: "100%" }} height={80} width={80} />
                            <div className="skeleton-container--item--right">
                              <Skeleton style={{ width: "100%" }} height={16} />
                              <div>
                                <Skeleton style={{ width: "100%" }} height={14} />
                                <Skeleton style={{ width: "100%" }} height={14} />
                                <Skeleton style={{ width: "100%" }} height={14} />
                              </div>

                              <Skeleton style={{ width: "100%" }} height={16} />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  } else if (!!combo) {
                    if (!!nestedItems) {
                      if (filteredNestedItems.length > 0) {
                        return filteredNestedItems.map(
                          (
                            {
                              nro_item,
                              type,
                              title,
                              option,
                              content,
                              contentGraph,
                              selected,
                              selectFlavour,
                              qtyItems,
                              size,
                              onClick,
                              onChangeIngredients,
                              id_combo_product,
                              onChangeAmount,
                              onChangeAmountFlavour,
                              onChangeAmountBeverage,
                            },
                            key
                          ) => {
                            return (
                              <CollapseItem
                                key={type + nro_item + key}
                                type={type}
                                title={title}
                                option={option}
                                status={true}
                                size={size}
                                content={content}
                                nro_item={nro_item}
                                selectFlavour={selectFlavour}
                                comboProductId={id_combo_product}
                                loadingFlavours={loadingFlavours}
                                selected={selected}
                                contentGraph={contentGraph}
                                validations={validations}
                                qtyItems={qtyItems}
                                onClick={onClick}
                                onChangeAmount={onChangeAmount}
                                onChangeAmountFlavour={(amount, direction, type, flavour) =>
                                  onChangeAmountFlavour(amount, direction, type, flavour, nro_item, id_combo_product)
                                }
                                onChangeAmountBeverage={(amount, direction, type, beverage) =>
                                  onChangeAmountBeverage(amount, direction, type, beverage, nro_item, id_combo_product)
                                }
                                onChangeIngredients={onChangeIngredients}
                              />
                            )
                          }
                        )
                      } else {
                        return (
                          <>
                            <div className="skeleton-container">
                              <Skeleton style={{ width: "100%" }} height={34} />
                              <div className="skeleton-container--item">
                                <Skeleton style={{ width: "100%" }} height={80} width={80} />
                                <div className="skeleton-container--item--right">
                                  <Skeleton style={{ width: "100%" }} height={16} />
                                  <div>
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                  </div>

                                  <Skeleton style={{ width: "100%" }} height={16} />
                                </div>
                              </div>
                            </div>
                            <div className="skeleton-container">
                              <Skeleton style={{ width: "100%" }} height={34} />
                              <div className="skeleton-container--item">
                                <Skeleton style={{ width: "100%" }} height={80} width={80} />
                                <div className="skeleton-container--item--right">
                                  <Skeleton style={{ width: "100%" }} height={16} />
                                  <div>
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                  </div>

                                  <Skeleton style={{ width: "100%" }} height={16} />
                                </div>
                              </div>
                            </div>
                            <div className="skeleton-container">
                              <Skeleton style={{ width: "100%" }} height={34} />
                              <div className="skeleton-container--item">
                                <Skeleton style={{ width: "100%" }} height={80} width={80} />
                                <div className="skeleton-container--item--right">
                                  <Skeleton style={{ width: "100%" }} height={16} />
                                  <div>
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                  </div>

                                  <Skeleton style={{ width: "100%" }} height={16} />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    }
                  } else {
                    return (
                      <>
                        <div className="skeleton-container">
                          <Skeleton style={{ width: "100%" }} height={34} />
                          <div className="skeleton-container--item">
                            <Skeleton style={{ width: "100%" }} height={80} width={80} />
                            <div className="skeleton-container--item--right">
                              <Skeleton style={{ width: "100%" }} height={16} />
                              <div>
                                <Skeleton style={{ width: "100%" }} height={14} />
                                <Skeleton style={{ width: "100%" }} height={14} />
                                <Skeleton style={{ width: "100%" }} height={14} />
                              </div>

                              <Skeleton style={{ width: "100%" }} height={16} />
                            </div>
                          </div>
                        </div>
                        <div className="skeleton-container">
                          <Skeleton style={{ width: "100%" }} height={34} />
                          <div className="skeleton-container--item">
                            <Skeleton style={{ width: "100%" }} height={80} width={80} />
                            <div className="skeleton-container--item--right">
                              <Skeleton style={{ width: "100%" }} height={16} />
                              <div>
                                <Skeleton style={{ width: "100%" }} height={14} />
                                <Skeleton style={{ width: "100%" }} height={14} />
                                <Skeleton style={{ width: "100%" }} height={14} />
                              </div>

                              <Skeleton style={{ width: "100%" }} height={16} />
                            </div>
                          </div>
                        </div>
                        <div className="skeleton-container">
                          <Skeleton style={{ width: "100%" }} height={34} />
                          <div className="skeleton-container--item">
                            <Skeleton style={{ width: "100%" }} height={80} width={80} />
                            <div className="skeleton-container--item--right">
                              <Skeleton style={{ width: "100%" }} height={16} />
                              <div>
                                <Skeleton style={{ width: "100%" }} height={14} />
                                <Skeleton style={{ width: "100%" }} height={14} />
                                <Skeleton style={{ width: "100%" }} height={14} />
                              </div>

                              <Skeleton style={{ width: "100%" }} height={16} />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  break
                case "size":
                  if (!!product) {
                    if (!!selectedProduct) {
                      const { qty_max_flavours, qty_min_flavours } = selectedProduct.size

                      // Verificando se é para exibir
                      if (graph) {
                        let label = "Selecione um sabor..."
                        let seriesChart = []
                        let labelsChart = []
                        let imagesChart = []
                        let flavourNumbers = [...Array(qty_max_flavours).keys()]

                        for (let index = 0; index < selectedFlavourNumber + 1; index++) {
                          const flavour = selectedProduct.flavours.find((item) => item.index === index)

                          imagesChart[index] = !!flavour ? getUrlAWSManagerFile(flavour.url_cover_image, noFlavour) : pizzaStandard
                          labelsChart[index] = !!flavour ? flavour.nm_flavour : label

                          seriesChart.push(100 / (selectedFlavourNumber + 1))
                        }

                        const newOptionsChart = {
                          ...optionsChart,
                          labels: labelsChart,
                          fill: {
                            ...optionsChart.fill,
                            image: { ...optionsChart.fill.image, src: imagesChart },
                          },
                          stroke: {
                            ...optionsChart.stroke,
                            show: selectedFlavourNumber > 0,
                          },
                          responsive: [
                            {
                              options: {
                                chart: {
                                  width: getWidthChart(),
                                },
                              },
                            },
                          ],
                        }

                        return (
                          <div className="builder-graph">
                            <Label className="lbl-flavour-numbers">Selecione a quantidade de sabores</Label>

                            <div className="flavour-numbers">
                              {flavourNumbers.map((number, key) => (
                                <Button
                                  key={key}
                                  disabled={!(qty_min_flavours > 0 && number >= qty_min_flavours - 1)}
                                  className={selectedFlavourNumber === number ? "active" : ""}
                                  onClick={() => {
                                    setSelectedFlavourNumber(number)

                                    // se for selecionado quantidade de sabores que é o menor que o selecionado
                                    if (number < selectedFlavourNumber) {
                                      // retira os sabores que passam da quantidade selecionado e muda o index no gráfico
                                      const newSelectedProduct = selectedProduct.flavours.slice(0, number + 1).map((item, index) => {
                                        return { ...item, index }
                                      })

                                      setSelectedProduct({
                                        ...selectedProduct,
                                        flavours: newSelectedProduct,
                                      })
                                      selectedProductRef.current = {
                                        ...selectedProduct,
                                        flavours: newSelectedProduct,
                                      }
                                    }
                                  }}
                                >
                                  {number + 1}
                                </Button>
                              ))}
                            </div>

                            <div className="flavours-graph">
                              {(() => {
                                if (!!selectedProduct && selectedProduct.flavours.length > 0) {
                                  return selectedProduct.flavours.map((flavour, key) => (
                                    <div className="flavour-items" key={key}>
                                      <button className="flavour-description" onClick={() => onOpenIngredientsModalFromLabel(flavour, flavour.index)}>
                                        {flavour.nm_flavour}&nbsp;
                                        {flavour.loadingIngredient || flavour.loadingOptional ? <Spinner color="secondary" size="sm" /> : ""}
                                      </button>
                                      <label className="flavour-delete">
                                        <FontAwesomeIcon className="icon" icon="times-circle" onClick={(event) => onDeleteFlavour(event, key)} />
                                      </label>
                                    </div>
                                  ))
                                } else {
                                  return <Label className="lbl-no-flavours">Sem sabores selecionados</Label>
                                }
                              })()}
                            </div>
                            <div className="chart-graph">
                              <Chart
                                options={newOptionsChart}
                                series={seriesChart}
                                type="pie"
                                width={getWidthChart()}
                                className="cursor-pointer pie-chart"
                              />
                            </div>
                            <Label className="lbl-info-ingredients">
                              <FontAwesomeIcon icon="info-circle" />
                              &nbsp;Clique no sabor para adicionar ou remover ingredientes
                            </Label>

                            {selectedProduct.flavours.length === 0 && (
                              <div className="cursor">
                                <img src={cursor} alt="icone de cursor" />
                              </div>
                            )}
                          </div>
                        )
                      } else {
                        const nestedItems = getNestedItems("size", product)

                        if (loadingFlavours || loadingSizes || loading) {
                          return nestedItems.map((_item, idx) => {
                            return (
                              <div className="skeleton-container" key={idx}>
                                <Skeleton style={{ width: "100%" }} height={34} />
                                <div className="skeleton-container--item">
                                  <Skeleton style={{ width: "100%" }} height={80} width={80} />
                                  <div className="skeleton-container--item--right">
                                    <Skeleton style={{ width: "100%" }} height={16} />
                                    <div>
                                      <Skeleton style={{ width: "100%" }} height={14} />
                                      <Skeleton style={{ width: "100%" }} height={14} />
                                      <Skeleton style={{ width: "100%" }} height={14} />
                                    </div>

                                    <Skeleton style={{ width: "100%" }} height={16} />
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        } else if (nestedItems.length > 0) {
                          return nestedItems.map(
                            (
                              {
                                type,
                                title,
                                subtitle,
                                option,
                                content,
                                product,
                                selected,
                                size,
                                onClick,
                                onChangeAmount,
                                onChangeIngredients,
                                onChangeAmountFlavour,
                              },
                              key
                            ) => (
                              <CollapseItem
                                key={key}
                                size={size}
                                type={type}
                                status={true}
                                title={title}
                                subtitle={subtitle}
                                option={option}
                                content={content}
                                product={product}
                                selected={selected}
                                onClick={onClick}
                                onChangeAmount={onChangeAmount}
                                onChangeIngredients={onChangeIngredients}
                                onChangeAmountFlavour={onChangeAmountFlavour}
                              />
                            )
                          )
                        } else {
                          return (
                            <div className="skeleton-container">
                              <Skeleton style={{ width: "100%" }} height={34} />
                              <div className="skeleton-container--item">
                                <Skeleton style={{ width: "100%" }} height={80} width={80} />
                                <div className="skeleton-container--item--right">
                                  <Skeleton style={{ width: "100%" }} height={16} />
                                  <div>
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                    <Skeleton style={{ width: "100%" }} height={14} />
                                  </div>

                                  <Skeleton style={{ width: "100%" }} height={16} />
                                </div>
                              </div>
                            </div>
                          )
                        }
                      }
                    }
                  }
                  break
                case "flavour":
                  if (!!product) {
                    const nestedItems = getNestedItems("flavour", product)

                    if (loadingFlavours || loadingSizes || loading) {
                      return nestedItems.map((_item, idx) => {
                        return (
                          <div className="skeleton-container" key={idx}>
                            <Skeleton style={{ width: "100%" }} height={34} />
                            <div className="skeleton-container--item">
                              <Skeleton style={{ width: "100%" }} height={80} width={80} />
                              <div className="skeleton-container--item--right">
                                <Skeleton style={{ width: "100%" }} height={16} />
                                <div>
                                  <Skeleton style={{ width: "100%" }} height={14} />
                                  <Skeleton style={{ width: "100%" }} height={14} />
                                  <Skeleton style={{ width: "100%" }} height={14} />
                                </div>

                                <Skeleton style={{ width: "100%" }} height={16} />
                              </div>
                            </div>
                          </div>
                        )
                      })
                    } else if (nestedItems.length > 0) {
                      return nestedItems.map(
                        (
                          {
                            type,
                            title,
                            subtitle,
                            selectedFlavour,
                            option,
                            content,
                            selected,
                            size,
                            complement,
                            complementId,
                            maxAdditionals,
                            onClick,
                            onChangeAmount,
                            onChangeIngredients,
                            onChangeAmountFlavour,
                            selectFlavour,
                          },
                          key
                        ) => {
                          if (content.length <= 1) return ""
                          return (
                            <CollapseItem
                              key={key}
                              size={size}
                              type={type}
                              status={true}
                              title={title}
                              option={option}
                              selectedFlavour={selectedFlavour}
                              content={content}
                              onClick={onClick}
                              subtitle={subtitle}
                              selected={selected}
                              complement={complement}
                              complementId={complementId}
                              onChangeAmount={onChangeAmount}
                              onChangeAmountFlavour={onChangeAmountFlavour}
                              maxAdditionals={maxAdditionals}
                              selectFlavour={selectFlavour}
                              onChangeIngredients={onChangeIngredients}
                            />
                          )
                        }
                      )
                    }
                  }
                  break
                default: {
                  break
                }
              }
            }
          })()}
          {/* Complementos do produto */}
          {(() => {
            if (!!product && !!selectedProduct && !selectedCombo && product.complements && product.complements.length > 0) {
              const nestedItems = getNestedItems("complement", product.complements).filter(({ content }) => content.length > 0)

              if (loadingFlavours || loadingSizes || loading || loadingComplements) {
                return nestedItems.map((_item, idx) => {
                  return (
                    <div className="skeleton-container" key={idx}>
                      <Skeleton style={{ width: "100%" }} height={34} />
                      <div className="skeleton-container--item">
                        <Skeleton style={{ width: "100%" }} height={80} width={80} />
                        <div className="skeleton-container--item--right">
                          <Skeleton style={{ width: "100%" }} height={16} />
                          <div>
                            <Skeleton style={{ width: "100%" }} height={14} />
                            <Skeleton style={{ width: "100%" }} height={14} />
                            <Skeleton style={{ width: "100%" }} height={14} />
                          </div>

                          <Skeleton style={{ width: "100%" }} height={16} />
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              let showingItems = []
              //Verificando se existe um grupo de complementos antes de exibir
              for (const item of nestedItems) {
                if (!!item.group) {
                  //Verificando se algum item do grupo já foi adicionado.
                  const showingItemIndex = showingItems.findIndex(({ title }) => title === item.group)

                  if (showingItemIndex !== -1) {
                    showingItems[showingItemIndex].content.push(item)
                  } else {
                    showingItems.push({
                      type: "group",
                      title: item.group,
                      content: [item],
                      selected: false,
                      // size: selectedProduct.size,
                      onClick: onChangeSelectedGroupComplement,
                    })
                  }
                } else {
                  showingItems.push(item)
                }
              }

              return (
                <div className="product-complements">
                  {showingItems.map((complementData, key) => {
                    const {
                      type,
                      complement,
                      option,
                      complement_id,
                      title,
                      subtitle,
                      content,
                      contentGraph,
                      selected,
                      size,
                      onClick,
                      onChangeAmountComplement,
                    } = complementData
                    return (
                      <>
                        <CollapseItem
                          key={key}
                          type={type}
                          option={option}
                          title={title}
                          complement={complement}
                          complementId={complement_id}
                          subtitle={subtitle}
                          status={true}
                          selectedGroup={selectedProduct.group_selected || 0}
                          content={content}
                          size={size}
                          selected={selected}
                          contentGraph={contentGraph}
                          validations={validations}
                          onClick={onClick}
                          onChangeAmountComplement={onChangeAmountComplement}
                        />
                        {(() => {
                          if (!!selectedProduct.group_selected) {
                            const selectedComplement = complementData.content.find(
                              ({ complementId }) => complementId === selectedProduct.group_selected
                            )

                            if (!!selectedComplement) {
                              const {
                                type,
                                complement,
                                option,
                                complement_id,
                                title,
                                subtitle,
                                content,
                                contentGraph,
                                selected,
                                size,
                                onClick,
                                onChangeAmountComplement,
                              } = selectedComplement

                              return (
                                <CollapseItem
                                  key={key + "_" + complement_id}
                                  type={type}
                                  option={option}
                                  title={title}
                                  complement={complement}
                                  complementId={complement_id}
                                  subtitle={subtitle}
                                  status={true}
                                  selectedGroup={selectedProduct.group_selected || 0}
                                  content={content}
                                  size={size}
                                  selected={selected}
                                  contentGraph={contentGraph}
                                  validations={validations}
                                  onClick={onClick}
                                  onChangeAmountComplement={onChangeAmountComplement}
                                />
                              )
                            }
                          }
                        })()}
                      </>
                    )
                  })}
                </div>
              )
            }
          })()}

          {/*Campo de observação do item/combo*/}
          {(() => {
            if (!!selectedProduct) {
              return (
                <div className="left-side-builder--observation-content">
                  <div className="left-side-builder--observation-content--header">
                    <span>Alguma observação?</span>
                    <span>{!!selectedProduct.ds_obs ? selectedProduct.ds_obs.length : 0} / 140</span>
                  </div>
                  <div className="left-side-builder--observation-content--body">
                    <textarea
                      maxLength={140}
                      wrap="soft"
                      value={!!selectedProduct ? selectedProduct.ds_obs : ""}
                      onChange={(event) => {
                        setSelectedProduct({ ...selectedProduct, ds_obs: event.target.value })
                        selectedProductRef.current = { ...selectedProduct, ds_obs: event.target.value }
                      }}
                    />
                  </div>
                </div>
              )
            }
            if (!!selectedCombo) {
              return (
                <div className="left-side-builder--observation-content">
                  <div className="left-side-builder--observation-content--header">
                    <span>Alguma observação?</span>
                    <span>{!!selectedCombo.ds_obs ? selectedCombo.ds_obs.length : 0} / 140</span>
                  </div>
                  <div className="left-side-builder--observation-content--body">
                    <textarea
                      maxLength={140}
                      wrap="soft"
                      value={!!selectedCombo ? selectedCombo.ds_obs : ""}
                      onChange={(event) => setCombo({ ...combo, ds_obs: event.target.value })}
                    />
                  </div>
                </div>
              )
            }
          })()}
        </div>

        {/* Esta parte é das informações do item como descrição, imagem, valores, etc */}
        <div className={"right-side-builder" + (isDefaultCard ? " center-builder" : "")}>
          <div className="close-button-modal-builder">
            <FontAwesomeIcon icon="circle-chevron-down" onClick={() => onCloseModal()} />
          </div>
          {(() => {
            const { size, flavours } = selectedProduct || {}

            switch (type) {
              case "combo":
                const { nm_combo, lb_combo, ds_combo, url_image, lb_val_price, lb_tag_image, color_tag_main_image } = combo || {}

                return (
                  <Card>
                    <CardBody>
                      <div className="card-info">
                        <CardTitle>{!!lb_combo ? lb_combo : nm_combo}</CardTitle>
                        <CardText className="card-description">{ds_combo || "Sem descrição"}</CardText>
                        <CardText className="card-price">
                          {lb_val_price || "A partir de"}&nbsp;<strong>{getTotalCombo()}</strong>
                        </CardText>
                      </div>
                      <div className="card-image-right">
                        {(() => {
                          if (!!lb_tag_image) {
                            return (
                              <div className="flag-image">
                                <div
                                  className="flag-image--body"
                                  style={{
                                    backgroundColor: color_tag_main_image || "#F44336",
                                    borderRightColor: color_tag_main_image || "#F44336",
                                  }}
                                >
                                  <div>
                                    <Label>{lb_tag_image}</Label>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        })()}
                        <CardImg src={getUrlAWSManagerFile(url_image, noImage)} alt="" />
                      </div>
                    </CardBody>
                  </Card>
                )

              case "size":
                if (!!size) {
                  return (
                    <Card>
                      <CardBody>
                        <div className="card-info">
                          <CardTitle>{size.lb_size}</CardTitle>
                          <CardText className="card-description">{size.ds_size || "Sem descrição"}</CardText>
                          <div className="card-price">
                            {!!size.val_price_back ? (
                              <div className="card-price-item-back">
                                <p>{size.lb_val_price_back}&nbsp;</p>
                                <p> {GeneralTools.formatMoney(size.val_price_back)}</p>
                              </div>
                            ) : (
                              ""
                            )}
                            {!!size.val_price_sample ? (
                              <div className="card-price-item">
                                <p>{size.lb_val_price_sample}&nbsp;</p>
                                <p>{GeneralTools.formatMoney(size.val_price_sample)}</p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="card-image-right">
                          {(() => {
                            if (!!size.lb_tag_image) {
                              return (
                                <div className="flag-image">
                                  <div
                                    className="flag-image--body"
                                    style={{
                                      backgroundColor: size.color_tag_main_image || "#F44336",
                                      borderRightColor: size.color_tag_main_image || "#F44336",
                                    }}
                                  >
                                    <div>
                                      <Label>{size.lb_tag_image}</Label>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })()}
                          <CardImg src={getUrlAWSManagerFile(size.url_image, noImage)} alt="" />
                        </div>
                      </CardBody>
                    </Card>
                  )
                }

                break

              case "flavour":
                if (!!flavours && flavours.length > 0) {
                  // if (flavours[0].optionals.length >= 0 || flavours[0].ingredients.length >= 0) {
                  return (
                    <Card>
                      {/* <CardImg src={getUrlAWSManagerFile(flavours[0].url_main_image, noImage)} alt="" /> */}
                      <CardBody>
                        <div className="card-info">
                          <CardTitle>{!!flavours[0].lb_flavour ? flavours[0].lb_flavour : flavours[0].nm_flavour}</CardTitle>
                          <CardText className="card-description">{flavours[0].ds_flavour || getIngredients(flavours[0].ingredients)}</CardText>
                          <div className="card-price">
                            {flavours[0].lb_val_price_back && flavours[0].val_price_back ? (
                              <div className="card-price-item-back">
                                <span>
                                  {flavours[0].lb_val_price_back}&nbsp;
                                  {GeneralTools.formatMoney(flavours[0].val_price_back)}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {flavours[0].lb_val_price_sample && flavours[0].val_price_sample ? (
                              <div className="card-price-item">
                                <span>
                                  {flavours[0].lb_val_price_sample}&nbsp;
                                  {GeneralTools.formatMoney(flavours[0].val_price_sample)}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="card-image-right">
                          {(() => {
                            if (!!flavours[0].lb_tag_main_image) {
                              return (
                                <div className="flag-image">
                                  <div
                                    className="flag-image--body"
                                    style={{
                                      backgroundColor: flavours[0].color_tag_main_image || "#F44336",
                                      borderRightColor: flavours[0].color_tag_main_image || "#F44336",
                                    }}
                                  >
                                    <div>
                                      <Label>{flavours[0].lb_tag_main_image}</Label>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })()}
                          <CardImg src={getUrlAWSManagerFile(flavours[0].url_main_image, noImage)} alt="" />
                        </div>
                      </CardBody>
                    </Card>
                  )
                }

                break

              default: {
                break
              }
            }
          })()}
        </div>
      </ModalBody>
      <ModalFooter className={"builder--footer"}>
        <div className={"footer-buttons"} style={{ marginBottom: isAppleDevice() ? 20 : 0 }}>
          {(() => {
            if (!!selectedCombo) {
              return (
                <div className="button-group">
                  <FontAwesomeIcon icon="minus" onClick={() => onChangeAmountCombo((selectedCombo?.amount || 1) - 1, "down")} />
                  <Label>{selectedCombo?.amount || 1}</Label>
                  <FontAwesomeIcon icon="plus" onClick={() => onChangeAmountCombo((selectedCombo?.amount || 1) + 1, "up")} />
                </div>
              )
            } else if (!!selectedProduct) {
              return (
                <div className="button-group">
                  <FontAwesomeIcon icon="minus" onClick={() => onChangeAmountProduct((selectedProduct?.amount || 1) - 1, "down")} />
                  <Label>{selectedProduct?.amount || 1}</Label>
                  <FontAwesomeIcon icon="plus" onClick={() => onChangeAmountProduct((selectedProduct?.amount || 1) + 1, "up")} />
                </div>
              )
            }
          })()}

          <div className={"button-footer-button-right"}>
            {(() => {
              if (!formatBoolean(company?.st_website) && !formatBoolean(company?.st_schedule)) {
                return <Label className="footer-button-right-disabled">Estamos fechados</Label>
              } else {
                return (
                  <Label
                    onClick={() => {
                      if (!loadingProducts && !loadingSizes && !loadingFlavours && !loadingComplements) {
                        finishBuilderOrder()
                      }
                    }}
                    className={
                      !loadingProducts && !loadingSizes && !loadingFlavours && !loadingComplements
                        ? "footer-button-right"
                        : "footer-button-right-disabled"
                    }
                  >
                    {loadingProducts || loadingSizes || loadingFlavours || loadingComplements ? <Spinner color="secondary" size="sm" /> : ""}
                    <FontAwesomeIcon icon="plus-circle" />
                    &nbsp;
                    {(() => {
                      if (!!selectedCombo) {
                        return "Adicionar a Sacola"
                      } else if (!!selectedProduct) {
                        return selectedProduct?.size?.beverages?.length > 0 ? "Continuar" : "Adicionar a Sacola"
                      }
                    })()}
                    &nbsp;
                    {(() => {
                      if (!!selectedCombo) {
                        return getTotalCombo()
                      } else if (!!selectedProduct) {
                        return getTotalCartValue()
                      }
                    })()}
                  </Label>
                )
              }
            })()}
          </div>
        </div>
      </ModalFooter>

      {/*Modal de Products*/}
      <ModalFlavours
        content={modalFlavours.content}
        status={modalFlavours.status}
        size={!!selectedProduct ? selectedProduct : null}
        isCombo={!!selectedCombo}
        comboData={!!selectedCombo ? selectedCombo : null}
        comboNroItem={modalFlavours.nro_item}
        selectedFlavourNumber={selectedFlavourNumber}
        selectedComboFlavourNumber={selectedComboFlavourNumber}
        onClick={
          !!modalFlavours.onClick
            ? modalFlavours.onClick
            : (flavour) => {
                onChangeFlavour(flavour, null, null, null, null, indexChart)
              }
        }
        onClose={() => {
          setModalFlavours({ ...modalFlavours, status: false })
        }}
        // loading={loadingFlavours}
      />

      {/*Modal de Ingredientes*/}
      <ModalIngredients
        title="Ingredientes"
        content={modalIngredients.content}
        status={modalIngredients.status}
        flavoursAmount={selectedProduct?.flavours?.reduce((tot, { amount = 1 }) => tot + amount, 0) || 0}
        selectedComboFlavourNumber={selectedComboFlavourNumber}
        onChangeOptional={onChangeOptional}
        onChangeRemoveIngredient={(ingredient, id_flavour, nro_item, itemIndex) => {
          onChangeIngredient(ingredient, nro_item, id_flavour, itemIndex)
        }}
        onChangeAmountOptional={(amount, direction, type, optional, id_flavour, nro_item, itemIndex) => {
          onChangeAmountOptional(amount, direction, type, optional, id_flavour, nro_item, itemIndex)
        }}
        onClose={() => {
          setModalIngredients({ status: false, content: null })
        }}
      />

      {/* Modal Additional */}
      <ModalAdditional
        title="Adicionais"
        status={modalAdditional.status}
        content={modalAdditional.content}
        onClose={() => {
          setModalAdditional({ status: false, content: null })
          setSelectedProduct({
            ...selectedProduct,
            additionals: [],
          })
          selectedProductRef.current = {
            ...selectedProduct,
            additionals: [],
          }
        }}
        finishBuilderOrder={finishBuilderOrder}
        onChangeAdditional={onChangeAdditional}
      />
    </Modal>
  )
}

export default ModalBuilder
