// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados do pagamento online
 */
const PaymentsController = {
  createPayment(order, saveCard, customer, paymentType, newRequest) {
    console.log("order createPayment", order)
    return API.request("/v1/payments/create", { integration: "mercadopago", order, saveCard, customer, paymentType, resend: newRequest }, "POST")
  },

  getPendingPayments(customerUid) {
    return API.request(`/v1/payments/pending/${customerUid}`, {}, "GET")
  },

  getPaidPayments(customerUid) {
    return API.request(`/v1/payments/paid/${customerUid}`, {}, "GET")
  },

  getPaymentInfo(paymentId) {
    return API.request(`/v1/payments/info`, { paymentId }, "GET")
  },

  getHash(data) {
    return API.request("/v1/payments/hash", data, "GET")
  },

  getRecentPendingPayments(customerUid) {
    return API.request(`/v1/payments/recent/pending/${customerUid}`, {}, "GET")
  },

  getOrderByPaymentRef(paymentId) {
    return API.request(`/v1/payments/order/${paymentId}`, {}, "GET")
  },
}

export default PaymentsController
