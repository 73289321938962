import React from "react"
import { Card, CardBody, CardImg, CardText, Label } from "reactstrap"

// Styles
import "./Beverages.scss"

// Resources
import { formatMoney, getUrlAWSManagerFile } from "../../helpers/GeneralTools"

// Imagens
import noImage from "../../assets/img/builder/no-image.png"

// Controllers
import SettingsContext from "../../contexts/SettingsContext"
import { getBeverageName } from "../../helpers/BuilderTools"

const Beverages = ({ onClickBeverage, onClickCategory }) => {
  const { settings, beverages } = React.useContext(SettingsContext)
  if (!!settings["display_mode_beverages"] && settings["display_mode_beverages"] === "category") {
    let categories = []
    let noCategories = []

    for (const beverage of beverages) {
      const { category } = beverage

      if (!!category) {
        const categoryIndex = categories.findIndex(({ uuid_category }) => uuid_category === category.uuid_category)

        if (categoryIndex !== -1) {
          categories[categoryIndex].beverages.push(beverage)
        } else {
          categories = [...categories, { ...category, beverages: [beverage] }]
        }
      } else {
        if (noCategories.length > 0) {
          noCategories[0].beverages.push(beverage)
        } else {
          noCategories = [...noCategories, { ...category, beverages: [beverage] }]
        }
      }
    }

    if (categories.length > 0) {
      return (
        <section id="beverages" className="section-beverages">
          <div className="beverage-title">
            <label>Bebidas</label>
            <span />
          </div>
          <div className="beverage-content">
            {categories.length > 0 &&
              categories.map((category, key) => (
                <Card
                  key={key}
                  className="shadow-05 card-category"
                  onClick={() =>
                    onClickCategory("category", {
                      ds_category: !!category.lb_category ? category.lb_category : category.nm_category,
                      beverages: category.beverages,
                    })
                  }
                >
                  <CardBody>
                    <strong className="card-title">{!!category.lb_category ? category.lb_category : category.nm_category}</strong>
                    <CardText className="card-description">{!!category.ds_category ? category.ds_category : ""}</CardText>
                    <div>
                      {(() => {
                        if (!!category.val_price_prefix) {
                          return (
                            <>
                              <span className="card-price">{category?.lb_val_price_prefix || ""} </span>
                              <span className="card-price">{formatMoney(category.val_price_prefix)} </span>
                            </>
                          )
                        } else {
                          if (category?.val_price) {
                            return <span className="card-price">{formatMoney(category?.val_price || 0)}</span>
                          }
                        }
                      })()}
                      {/*{!!category.lb_val_price_prefix ? <span className="card-price">{category.lb_val_price_prefix} </span> : ""}*/}
                      {/*{!!category.val_price_prefix ? <span className="card-price">{formatMoney(category.val_price_prefix)} </span> : ""}*/}
                    </div>
                  </CardBody>
                  <div className="card-preview-image">
                    {(() => {
                      if (!!category.lb_tag_image) {
                        return (
                          <div className="flag-image">
                            <div
                              className="flag-image--body"
                              style={{
                                backgroundColor: category.color_tag_image || "#F44336",
                                borderRightColor: category.color_tag_image || "#F44336",
                              }}
                            >
                              <div>
                                <Label>{category.lb_tag_image}</Label>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })()}
                    <CardImg alt="" width="100%" src={getUrlAWSManagerFile(category.url_image, noImage)} />
                  </div>
                  {/*<CardImg alt="" width="100%" src={getUrlAWSManagerFile(category.url_image, noImage)} />*/}
                </Card>
              ))}
            {noCategories.length > 0 &&
              noCategories.map((nocategory, key) => (
                <Card
                  key={key}
                  className="shadow-05 card-category"
                  onClick={() =>
                    onClickCategory("category", {
                      ds_category: "Outros",
                      beverages: nocategory.beverages,
                    })
                  }
                >
                  <CardBody>
                    <strong className="card-title">Outras</strong>
                  </CardBody>
                  <CardImg alt="" width="100%" src={getUrlAWSManagerFile(nocategory.beverages.url_image, noImage)} />
                </Card>
                // <Card
                //   key={key}
                //   className="shadow-05 card-category"
                // onClick={() =>
                //   onClickCategory("category", {
                //     ds_category: "Outros",
                //     beverages: category.beverages,
                //   })
                // }
                // >
                //   {/* {console.log("category", category)} */}
                //   <CardBody>
                //     <CardTitle>Outras</CardTitle>
                //     {/* <CardText className="card-price">{formatMoney(category.val_price || 0)}</CardText> */}
                //   </CardBody>
                // </Card>
              ))}
          </div>
        </section>
      )
    }
    return ""
  } else {
    if (beverages.length > 0) {
      return (
        <section id="beverages" className="section-beverages">
          <div className="beverage-title">
            <label>Bebidas</label>
            <span />
          </div>
          <div className="beverage-content">
            {beverages.map((beverage, key) => {
              const beverageName = getBeverageName(beverage)
              return (
                <Card key={key} className="shadow-05 card-beverage" onClick={() => onClickBeverage("beverage", beverage)}>
                  <CardBody>
                    <strong className="card-category-title">{beverageName}</strong>
                    <CardText className="card-description">{!!beverage.ds_beverage ? beverage.ds_beverage : beverageName}</CardText>
                    <div>
                      {(() => {
                        if (!!beverage.val_price_prefix) {
                          return (
                            <>
                              <span className="card-price">{beverage?.lb_val_price_prefix || ""} </span>
                              <span className="card-price">{formatMoney(beverage.val_price_prefix)} </span>
                            </>
                          )
                        } else {
                          return <span className="card-price">{formatMoney(beverage?.val_price || 0)}</span>
                        }
                      })()}
                    </div>
                    {/* <div>
                    <span className="card-price">{formatMoney(beverage.val_price || 0)}</span>
                  </div> */}
                  </CardBody>
                  <div className="card-preview-image">
                    {(() => {
                      if (!!beverage.lb_tag_image) {
                        return (
                          <div className="flag-image">
                            <div
                              className="flag-image--body"
                              style={{
                                backgroundColor: beverage.color_tag_image || "#F44336",
                                borderRightColor: beverage.color_tag_image || "#F44336",
                              }}
                            >
                              <div>
                                <Label>{beverage.lb_tag_image}</Label>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })()}
                    <CardImg alt="" width="100%" src={getUrlAWSManagerFile(beverage.url_image, noImage)} />
                  </div>
                </Card>
              )
            })}
          </div>
        </section>
      )
    }

    return ""
  }
}

export default Beverages
