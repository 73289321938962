import { useState, useEffect, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import useIsElementVisible from "../../helpers/useIsElementVisible"
import ComboController from "../../controllers/ComboController"
import ProductController from "../../controllers/ProductController"
import GeneralTools, { getUrlAWSManagerFile } from "../../helpers/GeneralTools"
import FullContentLoading from "../../components/FullContentLoading"

import { Card, CardBody, CardImg, CardText, CardTitle, Modal, ModalBody, ModalHeader, Input, Button } from "reactstrap"

// Sytles
import "./ModalFlavours.scss"
import noFlavour from "../../assets/img/builder/no-flavour.png"

const ModalFlavours = ({
  status,
  size,
  onClose,
  selectedFlavourNumber,
  selectedComboFlavourNumber,
  isCombo,
  comboData,
  onClick,
  onEndReachedThreshold = 0.2,
  onEndReached,
  comboNroItem,
  // loading = false,
  // title,
  // content,
}) => {
  const [endReached, setEndReached] = useState(false)
  const [flavourPage, setFlavourPage] = useState({ currentPage: 1, hasNextPage: true })
  const [flavours, setFlavours] = useState([])
  const [loadingFlavours, setLoadingFlavours] = useState(false)
  const [filterName, setFilterName] = useState("")
  const lastRef = useRef(null)
  useEffect(() => {
    setFlavourPage({ currentPage: 1, hasNextPage: true })
    setLoadingFlavours(true)
    setFilterName("")
    getProductFlavours()
  }, [status])

  useEffect(() => {
    ;(async () => {
      if (filterName.length >= 3) {
        if (!!comboData) {
          setLoadingFlavours(true)
          const selectedProductCombo = comboData.products.find(({ nro_item }) => nro_item === comboNroItem)

          if (selectedProductCombo !== undefined) {
            const newParams = {
              orderField: "name",
              // orderField: "priority",
              orderKeyword: "asc",
              pageNumber: 1,
              pageSize: 30,
              withSizes: true,
              withIngredients: false,
              flavourName: filterName,
            }
            const responseFlavours = await ComboController.fetchComboFlavoursProduct(
              selectedProductCombo.id_combo_product,
              selectedProductCombo.id_product,
              newParams
            )

            //Ajustandos os sabores recebidos para ficar de acordo com o padrão do site.
            const filteredFlavours = responseFlavours.data.rows.map((flavour) => {
              const tempFlavour = {
                ...flavour,
                ...flavour.product_association,
                ...flavour.combo_flavour_association,

                sizes: flavour.sizes.map((size) => {
                  const tempSize = {
                    ...size,
                    ...size.flavour_size,
                  }
                  delete tempSize.flavour_size
                  return tempSize
                }),
              }
              delete tempFlavour.product_association
              delete tempFlavour.combo_flavour_association
              return tempFlavour
            })

            setFlavours(filteredFlavours)
            //.sort((a, b) => a.nro_priority - b.nro_priority)
            setFlavourPage({ currentPage: newParams.pageNumber + 1, hasNextPage: responseFlavours.data.total === 30 })
          }

          setLoadingFlavours(false)
        } else if (!!size) {
          if (!loadingFlavours) {
            setLoadingFlavours(true)

            const newParams = {
              uuid_product: size.uuid_product,
              orderField: "name",
              // orderField: "priority",
              orderKeyword: "asc",
              pageNumber: 1,
              pageSize: 30,
              withSizes: true,
              uuidSize: size.size.uuid_size,
              withIngredients: false,
              flavourName: filterName,
            }
            const response = await ProductController.fetchFlavoursBySize(newParams)

            //Ajustandos os sabores recebidos para ficar de acordo com o padrão do site.
            const filteredFlavours = response.data.rows
              .filter((flavour) => {
                const { sizes } = flavour
                const enabledSize = sizes.find(({ uuid_size }) => uuid_size === size.size.uuid_size)
                return enabledSize.flavour_size.st_website
              })
              .map((flavour) => {
                const tempFlavour = {
                  ...flavour,
                  ...flavour.product_association,
                  sizes: flavour.sizes.map((size) => {
                    const tempSize = {
                      ...size,
                      ...size.flavour_size,
                    }
                    delete tempSize.flavour_size
                    return tempSize
                  }),
                }
                delete tempFlavour.product_association
                return tempFlavour
              })
            setFlavours(filteredFlavours)
            //.sort((a, b) => a.nro_priority - b.nro_priority)
            setFlavourPage({ currentPage: 1, hasNextPage: response.data.total === 30 })
            setLoadingFlavours(false)
          }
        }
      } else if (filterName.length === 0) {
        setLoadingFlavours(true)
        setFlavourPage({ currentPage: 1, hasNextPage: true })
        getProductFlavours()
      }
    })()
  }, [filterName])

  const getProductFlavours = async () => {
    try {
      if (!!comboData && !!comboNroItem) {
        const data = comboData.products.find(({ nro_item }) => nro_item === comboNroItem)
        if (!!data) {
          const { id_combo_product = null, id_product = null } = data
          const params = {
            orderField: "name",
            // orderField: "priority",
            orderKeyword: "asc",
            pageNumber: 1,
            pageSize: 30,
            withSizes: true,
            withIngredients: false,
            flavourName: "",
          }
          if (!!id_combo_product && !!id_product) {
            ComboController.fetchComboFlavoursProduct(id_combo_product, id_product, params).then((responseFlavours) => {
              setLoadingFlavours(false)
              const comboFlavoursProduct =
                responseFlavours.status === 200
                  ? responseFlavours.data.rows.map(
                      ({
                        id_flavour,
                        uuid_flavour,
                        ref_flavour,
                        nm_flavour,
                        sizes = [],
                        product_association = {},
                        combo_flavour_association = {},
                      }) => {
                        return {
                          id_flavour,
                          uuid_flavour,
                          ref_flavour,
                          nm_flavour,
                          sizes,
                          ...product_association,
                          ...combo_flavour_association,
                        }
                      }
                    )
                  : []

              setFlavours(comboFlavoursProduct)
              //.sort((a, b) => a.nro_priority - b.nro_priority)
            })
          }
        }
      } else if (!!size) {
        const params = {
          uuid_product: size.uuid_product,
          // orderField: "priority",
          orderField: "name",
          orderKeyword: "asc",
          pageNumber: 1,
          pageSize: 30,
          uuidSize: size.size.uuid_size,
          withSizes: true,
          withIngredients: false,
          withOptionals: false,
          flavourName: "",
        }

        // const response = await
        ProductController.fetchFlavoursBySize(params).then((response) => {
          setLoadingFlavours(false)

          const flavours = response.data.rows
            .filter((flavour) => {
              const { sizes } = flavour
              const enabledSize = sizes.find(({ uuid_size }) => uuid_size === size.size.uuid_size)
              if (enabledSize === undefined) {
                return false
              }
              return enabledSize.flavour_size.st_website
            })
            .map((flavour) => {
              let { product_association, uuid_flavour, id_flavour, nm_flavour, sizes } = flavour

              sizes = sizes.map((eachFlavourSize) => {
                const { flavour_size, id_size, nm_size, ref_size, uuid_size } = eachFlavourSize
                return { ...flavour_size, id_size, nm_size, ref_size, uuid_size }
              })

              return { ...product_association, uuid_flavour, id_flavour, nm_flavour, sizes }
            })

          setFlavours(flavours)
          //.sort((a, b) => a.nro_priority - b.nro_priority)
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const isLastVisible = useIsElementVisible(lastRef.current)

  useEffect(() => {
    // Fetch ao chegar no final da lista.
    if (isLastVisible) {
      const { currentPage, hasNextPage } = flavourPage
      if (hasNextPage) {
        loadMoreFlavours()
      }
    }
  }, [isLastVisible])

  const loadMoreFlavours = async () => {
    if (isCombo) {
      const { currentPage, hasNextPage } = flavourPage

      if (hasNextPage && !loadingFlavours) {
        setLoadingFlavours(true)

        const selectedProductCombo = comboData.products.find(({ nro_item }) => nro_item === comboNroItem)

        if (selectedProductCombo !== undefined) {
          const newParams = {
            orderField: "name",
            // orderField: "priority",
            orderKeyword: "asc",
            pageNumber: currentPage + 1,
            pageSize: 30,
            withSizes: true,
            withIngredients: false,
            withOptionals: false,
            flavourName: filterName,
          }

          ComboController.fetchComboFlavoursProduct(selectedProductCombo.id_combo_product, selectedProductCombo.id_product, newParams)
            .then((responseFlavours) => {
              //Ajustandos os sabores recebidos para ficar de acordo com o padrão do site.
              const newFlavours = responseFlavours.data.rows.map((flavour) => {
                const tempFlavour = {
                  ...flavour,
                  ...flavour.product_association,
                  ...flavour.combo_flavour_association,
                  sizes: flavour.sizes.map((size) => {
                    const tempSize = {
                      ...size,
                      ...size.flavour_size,
                    }
                    delete tempSize.flavour_size
                    return tempSize
                  }),
                }
                delete tempFlavour.product_association
                delete tempFlavour.combo_flavour_association
                return tempFlavour
              })

              const concatFlavours = [...flavours, ...newFlavours]
              //.sort((a, b) => a.nro_priority - b.nro_priority)

              // Vai atualizar os flavours apenas se o status for true
              // Evitando que caso o cliente faça a request e feche a modal, o sistema não vai alterar os flavours novos
              if (status === true && selectedProductCombo.nro_item === comboNroItem) {
                setFlavours(concatFlavours)
                setFlavourPage({ currentPage: currentPage + 1, hasNextPage: responseFlavours.data.total === 30 })
              }
            })
            .catch((error) => console.error("Erro ao carregar mais sabores do combo, ", error))
            .finally(() => setLoadingFlavours(false))
        }

        // setLoadingFlavours(false)
      }
    } else {
      const { currentPage, hasNextPage } = flavourPage
      const { uuid_product } = size
      if (hasNextPage && !loadingFlavours) {
        setLoadingFlavours(true)

        const newParams = {
          uuid_product,
          orderField: "name",
          // orderField: "priority",
          orderKeyword: "asc",
          pageNumber: currentPage + 1,
          pageSize: 30,
          withSizes: true,
          uuidSize: size.size.uuid_size,
          withIngredients: false,
          withOptionals: false,
          flavourName: filterName,
        }

        const newFetchedFlavorus = await ProductController.fetchFlavoursBySize(newParams)
        //Ajustandos os sabores recebidos para ficar de acordo com o padrão do site.
        const newFlavours = newFetchedFlavorus.data.rows
          .filter((flavour) => {
            const { sizes } = flavour
            const enabledSize = sizes.find(({ uuid_size }) => uuid_size === size.size.uuid_size)
            if (enabledSize === undefined) {
              return false
            }

            return enabledSize.flavour_size.st_website
          })
          .map((flavour) => {
            const tempFlavour = {
              ...flavour,
              ...flavour.product_association,
              sizes: flavour.sizes.map((size) => {
                const tempSize = {
                  ...size,
                  ...size.flavour_size,
                }
                delete tempSize.flavour_size
                return tempSize
              }),
            }
            delete tempFlavour.product_association

            return tempFlavour
          })

        const concatFlavours = [...flavours, ...newFlavours]
        // .sort((a, b) => a.nro_priority - b.nro_priority)
        setFlavourPage({ currentPage: currentPage + 1, hasNextPage: newFetchedFlavorus.data.total === 30 })
        setFlavours(concatFlavours)
        setLoadingFlavours(false)
      }
    }
  }

  const filterFlavourName = async (filter) => {
    setFilterName(filter)
  }

  const getIngredients = (ingredients) => {
    return !!ingredients && ingredients.length > 0 ? ingredients.map((item) => item.nm_ingredient).join(", ") : ""
  }

  const handleOnEndReached = () => {
    if (endReached) {
      loadMoreFlavours()
      setEndReached(false)
    }
  }

  const getFlavourValue = (flavour) => {
    let flavourValue = 0
    const productSize = !!size
      ? !!size.size
        ? size.size
        : null
      : !!comboData?.products.find(({ nro_item }) => nro_item === comboNroItem)
      ? !!comboData?.products.find(({ nro_item }) => nro_item === comboNroItem).size
        ? comboData?.products.find(({ nro_item }) => nro_item === comboNroItem).size
        : null
      : null
    if (!!isCombo && !!comboNroItem) {
      const {
        size: { st_percentage_calc },
      } = comboData.products.find(({ nro_item }) => nro_item === comboNroItem)

      if (productSize.st_higher_price) {
        flavourValue = flavour.val_price
      } else {
        if (st_percentage_calc) {
          flavourValue = flavour.val_price / (selectedComboFlavourNumber.find(({ nro_item }) => nro_item === comboNroItem).number + 1)
        } else {
          flavourValue = flavour.val_price
        }
      }
    } else {
      if (!!flavour.size) {
        flavourValue = flavour.size.val_price
      } else if (!!flavour.sizes) {
        //Todo: preço dos sabores em caso de possui um array sizes dentro.
      }
    }

    if (flavourValue > 0) {
      let value = flavourValue
      if (!productSize.st_higher_price && productSize.st_percentage_calc) {
        return GeneralTools.formatMoney(value / (selectedFlavourNumber + 1))
      }
      return GeneralTools.formatMoney(value)
    } else {
      return ""
    }
  }

  const filterFlavourByName = (nameFlavour) => {
    if (isCombo) {
      filterFlavourName(nameFlavour, comboNroItem)
    } else {
      filterFlavourName(nameFlavour)
    }
  }

  /* eslint-disable */
  useEffect(() => {
    if (!!status && !!endReached) handleOnEndReached()
  }, [endReached, handleOnEndReached])

  return (
    <Modal
      isOpen={status}
      toggle={onClose}
      className="modal-flavours"
      scrollable={true}
      autoFocus={true}
      fullscreen={true}
      backdrop={"static"}
      keyboard={false}
    >
      {loadingFlavours ? <FullContentLoading loading={loadingFlavours} /> : ""}
      <ModalHeader toggle={onClose}>
        {/*{title}*/}
        <div className="modal-flavours--header--search-input">
          <Input placeholder="Procurar um sabor" onChange={(event) => filterFlavourByName(event.target.value)} />
        </div>
      </ModalHeader>

      <ModalBody>
        {(() => {
          if (!!flavours && flavours.length > 0) {
            let newContent = flavours

            if (isCombo) {
              const selectedComboProduct = comboData.products.find(({ nro_item }) => nro_item === comboNroItem)

              if (selectedComboProduct !== undefined) {
                newContent = newContent.map((flavour) => {
                  let tempFlavour = {
                    ...flavour,
                    size: !!flavour.sizes ? flavour.sizes.find(({ id_size }) => id_size === selectedComboProduct?.size?.id_size) : null,
                  }
                  delete tempFlavour.sizes

                  return tempFlavour
                })
              }
            } else {
              if (!!size) {
                newContent = newContent.map((flavour) => {
                  let tempFlavour = {
                    ...flavour,
                    size: !!flavour.sizes ? flavour.sizes.find(({ id_size }) => id_size === size.size.id_size) : null,
                  }
                  delete tempFlavour.sizes

                  return tempFlavour
                })
              }
            }

            if (newContent.length > 0) {
              return newContent.map((flavour, key) => {
                const flavourValue = getFlavourValue(flavour)
                return (
                  <Card
                    key={key}
                    className="shadow-05 card-flavours"
                    onClick={() => {
                      onClick(flavour)
                    }}
                  >
                    <CardBody>
                      <CardTitle>{flavour.nm_flavour}</CardTitle>
                      <CardText className="card-description">{flavour.ds_flavour || getIngredients(flavour.ingredients)}</CardText>
                      <CardText className="card-price-exceeded">
                        {flavourValue === "" ? (isCombo ? "Sem excedente" : "") : `+ ${flavourValue}`}
                      </CardText>
                    </CardBody>
                    <CardImg alt="" width="100%" src={getUrlAWSManagerFile(flavour.url_main_image, noFlavour)} />
                  </Card>
                )
              })

              // {isLoading && <p>Loading...</p>}
            } else {
              return <p>Nenhum sabor encontrado!</p>
            }
          } else {
            return <p>Nenhum sabor encontrado!</p>
          }
        })()}
        <div ref={lastRef} />

        {flavourPage.hasNextPage && flavours.length >= 30 ? (
          <div className={"modal-body--loading-more" + (window.innerWidth < 900 ? " hide-loading-more" : "")}>
            <Button color="danger" onClick={loadMoreFlavours}>
              Carregar mais sabores
            </Button>
          </div>
        ) : (
          ""
        )}

        {/*{loading && (*/}
        {/*  <div className="loading-container">*/}
        {/*    <Spinner color="danger" />*/}
        {/*  </div>*/}
        {/*)}*/}
      </ModalBody>
    </Modal>
  )
}

ModalFlavours.propTypes = {
  content: PropTypes.array,
}

export default ModalFlavours
