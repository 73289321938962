import React from "react"

import { Label, Card, CardBody, CardImg, CardText, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

//Contexts
import CompanyContext from "../../contexts/CompanyContext"

// Helpers
import GeneralTools, { formatBoolean, getUrlAWSManagerFile, onMessageDialog } from "../../helpers/GeneralTools"

//Components
import Message from "../../components/Message"
import NumberControl from "../../components/NumberControl/NumberControl"

// Controllers
import SettingsContext from "../../contexts/SettingsContext"

// Styles
import noImage from "../../assets/img/builder/no-image.png"
import "./ModalBeverages.scss"

const ModalBeverages = ({ title, status, content, onClose, onClick, category, data = null }) => {
  const { beverages } = React.useContext(SettingsContext)
  const { company } = React.useContext(CompanyContext)
  const [selectedBeverages, setSelectedBeverages] = React.useState([])
  const [qtyBeverages, setQtyBeverages] = React.useState(1)
  const [categories, setCategories] = React.useState([])

  /* eslint-disable */
  React.useEffect(() => {
    if (!data && !!content && content.length > 0) {
      const filterBeveragesWithProduct = content.filter((beverage) => !beverage.id_product && !beverage.nro_item)
      setSelectedBeverages(filterBeveragesWithProduct)
    } else {
      setSelectedBeverages([])
    }

    setCategories(getCategories(beverages))
  }, [content])

  React.useEffect(() => {
    if (!content && !!data) {
      const {
        selectedItem: {
          size: { beverages, qty_beverages },
        },
      } = data
      setQtyBeverages(qty_beverages)
      setCategories(getCategories(beverages))
    }
  }, [data])

  const onSelectedBeverage = (beverage) => {
    const selectedBeverageIndex = selectedBeverages.findIndex(({ id_beverage }) => id_beverage === beverage.id_beverage)
    if (selectedBeverageIndex !== -1) {
      selectedBeverages.splice(selectedBeverageIndex, 1)
      setSelectedBeverages([...selectedBeverages])
    } else if (!data) {
      setSelectedBeverages([...selectedBeverages, { ...beverage, amount: 1 }])
    } else if (selectedBeverages.length < data.maxBeverages) {
      const { selectedItem } = data
      setSelectedBeverages([
        ...selectedBeverages,
        {
          ...beverage,
          amount: 1,
          id_product: selectedItem.id_product,
          val_sale: "0",
        },
      ])
    } else if (data.maxBeverages === 1 && selectedBeverages.length === data.maxBeverages) {
      const { selectedItem } = data
      selectedBeverages.splice(0, 1)
      setSelectedBeverages([
        ...selectedBeverages,
        {
          ...beverage,
          amount: 1,
          id_product: selectedItem.id_product,
          val_sale: "0",
        },
      ])
    } else {
      Message.info("Número máximo de bebida atingido")
    }
  }

  const onChangeBeverageAmount = (beverage, amount, direction, id = null) => {
    if (id === "") return
    let tempSelectedBeverages = [...selectedBeverages]

    //Verificando o total de bebidas selecionadas
    const totalBeveragesSelected = tempSelectedBeverages.reduce((tot, current) => current.amount + tot, 0)

    if (totalBeveragesSelected + 1 > qtyBeverages && direction === "up") {
      onMessageDialog("info")
        .fire({
          title: "Quantidade máxima de bebidas atingida",
          icon: "info",
          timer: 1500,
          showCancelButton: false,
          showConfirmButton: false,
        })
        .then()
      return null
    }
    const selectedBeveragesIndex = tempSelectedBeverages.findIndex(({ id_beverage }) => id_beverage === beverage.id_beverage)
    if (selectedBeveragesIndex !== -1) {
      //Verificando o total da bebida específica selecionada
      if (tempSelectedBeverages[selectedBeveragesIndex].amount === 1 && direction === "down") {
        tempSelectedBeverages.splice(selectedBeveragesIndex, 1)
      } else {
        tempSelectedBeverages[selectedBeveragesIndex].amount = amount
      }
      setSelectedBeverages([...tempSelectedBeverages])
    } else {
      tempSelectedBeverages.push({
        ...beverage,
        amount: 1,
      })
      setSelectedBeverages(tempSelectedBeverages)
    }
  }

  const handleOnClose = () => {
    setSelectedBeverages([])
    onClose()
  }

  const handleOnClick = () => {
    if (!data) {
      onClick(selectedBeverages)
      setSelectedBeverages([])
    } else if (data.isMandatory) {
      if (selectedBeverages.length !== 0) {
        const newBeverages = selectedBeverages.map((beverage) => {
          return { ...beverage, amount: (beverage.amount || 1) * data.selectedItem.amount }
        })
        const order = {
          products: data.selectedItem,
          beverages: newBeverages,
        }
        onClick(selectedBeverages, order)
        setSelectedBeverages([])
      } else {
        onMessageDialog("info")
          .fire({
            title: `Necessário escolher de 1 a ${data.maxBeverages} bebidas!`,
            icon: "info",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
        return null
      }
    } else {
      const newBeverages = selectedBeverages.map((beverage) => {
        return { ...beverage, amount: (beverage.amount || 1) * data.selectedItem.amount }
      })
      const order = {
        products: data.selectedItem,
        beverages: newBeverages,
      }
      onClick(selectedBeverages, order)
      setSelectedBeverages([])
    }
  }

  const getCategories = (beverages) => {
    let categories = []
    let noCategories = []

    for (const beverage of beverages) {
      const { category } = beverage

      if (!!category) {
        const categoryIndex = categories.findIndex(({ id_category }) => id_category === category.id_category)

        if (categoryIndex !== -1) {
          categories[categoryIndex].beverages.push(beverage)
        } else {
          categories = [...categories, { ...category, beverages: [beverage] }]
        }
      } else {
        if (noCategories.length > 0) {
          noCategories[0].beverages.push(beverage)
        } else {
          noCategories.push({ ds_category: "Diversas", beverages: [beverage] })
        }
      }
    }

    return [...categories, ...noCategories]
  }

  const getBeveragePrice = (beverage) => {
    let beveragePrice = 0

    if (!!beverage.beverage_size) {
      beveragePrice = beverage.beverage_size.val_price
    } else {
      beveragePrice = beverage.val_price
    }

    if (beveragePrice > 0) {
      return GeneralTools.formatMoney(beveragePrice)
    } else {
      return "Grátis"
    }
  }

  const getBeverageImage = (beverage) => {
    if (!!beverage.url_image) {
      return getUrlAWSManagerFile(beverage.url_image, noImage)
    } else {
      const beverageImage = beverages.find(({ uuid_beverage }) => uuid_beverage === beverage.uuid_beverage)
      let url_image = ""
      if (beverageImage !== undefined) {
        url_image = beverages
      }
      return getUrlAWSManagerFile(url_image, noImage)
    }
  }

  return (
    <Modal isOpen={status} toggle={handleOnClose} className={"modal-beverages"}>
      <ModalHeader toggle={handleOnClose}>{!!category ? category.ds_category : title}</ModalHeader>
      <ModalBody>
        {(() => {
          // Verificando se já foi informada categoria
          if (!!category) {
            if (beverages.length > 0) {
              return category.beverages.map((beverage, key) => (
                <Card
                  key={key}
                  className={
                    "shadow-05 card-beverage " +
                    (selectedBeverages.find(({ id_beverage }) => id_beverage === beverage.id_beverage) ? "beverage-selected" : "")
                  }
                  onClick={() => onSelectedBeverage(beverage)}
                >
                  <CardBody>
                    <CardTitle>{!!beverage.lb_beverage ? beverage.lb_beverage : beverage.nm_beverage}</CardTitle>
                    <CardText className="card-description">{!!beverage.ds_beverage ? beverage.ds_beverage : ""}</CardText>
                    <CardText className="card-price">{getBeveragePrice(beverage)}</CardText>
                  </CardBody>
                  <div className="card-preview-image">
                    {(() => {
                      if (!!beverage.lb_tag_image) {
                        return (
                          <div className="flag-image">
                            <div
                              className="flag-image--body"
                              style={{
                                backgroundColor: beverage.color_tag_image || "#F44336",
                                borderRightColor: beverage.color_tag_image || "#F44336",
                              }}
                            >
                              <div>
                                <Label>{beverage.lb_tag_image}</Label>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })()}
                    <CardImg alt="" width="100%" src={getBeverageImage(beverage)} />
                  </div>
                  {/*<CardImg alt="" width="100%" src={getBeverageImage(beverage)} />*/}
                </Card>
              ))
            } else {
              return <label>Sem bebidas</label>
            }
          } else {
            if (categories.length > 0) {
              return categories.map(({ ds_category, beverages }, key) => (
                <div key={key} className="card-category">
                  <Label>{ds_category}</Label>
                  <div className="card-category-content">
                    {beverages.map((beverage, key) => {
                      const thisSelectedBeverage = selectedBeverages.find(({ id_beverage }) => id_beverage === beverage.id_beverage)

                      if (qtyBeverages > 1) {
                        return (
                          <div
                            key={key}
                            id="id-card-beverage-multi"
                            onClick={(event) => onChangeBeverageAmount(beverage, 1, "click", event.target.id)}
                            className={
                              "card-beverage-multi " +
                              (selectedBeverages.find(({ id_beverage }) => id_beverage === beverage.id_beverage) ? "beverage-selected" : "")
                            }
                          >
                            <div className="card-beverage-multi--left">
                              <strong>{beverage.nm_beverage}</strong>
                              <span>{beverage.ds_about || ""}</span>
                              <strong>{getBeveragePrice(beverage)}</strong>
                            </div>
                            <div className="card-beverage-multi--right">
                              <NumberControl
                                selectedOptionals={selectedBeverages.reduce((tot, current) => current.amount + tot, 0)}
                                maxOptions={qtyBeverages}
                                number={thisSelectedBeverage?.amount || 0}
                                onChangeNumber={(amount, direction) => onChangeBeverageAmount(beverage, amount, direction, "number-control")}
                                maximum={true}
                                minium={0}
                                limit={qtyBeverages}
                                className="card-beverage-multi--right--number-control"
                                direction="horizontal"
                              />
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <Card
                            key={key}
                            className={
                              "shadow-05 card-beverage " +
                              (selectedBeverages.find(({ id_beverage }) => id_beverage === beverage.id_beverage) ? "beverage-selected" : "")
                            }
                            onClick={() => onSelectedBeverage(beverage)}
                          >
                            <CardBody>
                              <CardTitle>{beverage.nm_beverage}</CardTitle>
                              <CardText className="card-description">{beverage.ds_about || ""}</CardText>
                              <CardText className="card-price">{getBeveragePrice(beverage)}</CardText>
                            </CardBody>
                            <CardImg alt="" width="100%" src={getBeverageImage(beverage)} />
                          </Card>
                        )
                      }
                    })}
                  </div>
                </div>
              ))
            } else {
              return <label>Sem bebidas</label>
            }
          }
        })()}
      </ModalBody>
      <ModalFooter>
        <Label
          onClick={formatBoolean(company.st_website) || formatBoolean(company.st_schedule) ? () => handleOnClick() : () => {}}
          className="footer-button-right"
        >
          {formatBoolean(company.st_website) || formatBoolean(company.st_schedule) ? "Adicionar a sacola" : "Estamos fechados"}
        </Label>
      </ModalFooter>
    </Modal>
  )
}

export default ModalBeverages
