import React from "react"
import { Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// Components
import OrderTotalInfo from "../OrderTotalInfo/OrderTotalInfo"
import CFButton from "../CFButton/CFButton"
import BeverageItem from "../BeverageItem/BeverageItem"
import ProductItem from "../ProductItem/ProductItem"
// Helpers
import { getBeverageName, getDiscountOrder } from "../../helpers/OrderTools"
import {
  formatMoney,
  getFormatDate,
  // onMessageDialog,
  setNumber,
  // sortList,
  // getUrlAWSFile,
  getUrlAWSManagerFile,
  formatBoolean,
} from "../../helpers/GeneralTools"
import {} from "../../helpers/AddressessTools"
// Contexts
import UserContext from "../../contexts/UserContext"
import SettingsContext from "../../contexts/SettingsContext"
import CompanyContext from "../../contexts/CompanyContext"
import MenuContext from "../../contexts/MenuContext"
import logoImage from "../../assets/img/general/placeholder@2x.png"
import noImage from "../../assets/img/builder/no-image-01.png"
import "./OrderDetails.scss"
// import placeholder from "../../assets/img/general/placeholder@2x.png"

const OrderDetails = ({ status, order, returnMenu, handleOrderInfo }) => {
  const { user } = React.useContext(UserContext)
  const { setModalCompanyContacts } = React.useContext(MenuContext)
  const { company } = React.useContext(CompanyContext)
  const { beverages, products, settings, modality } = React.useContext(SettingsContext)

  // const getFinishDate = (order) => {
  //   const orderProgress = order?.order_progress.sort((a, b) => sortList(a, b, "step", "desc"))[0]
  //
  //   const finishDate = orderProgress?.order_in_progress?.finish_at
  //
  //   if (!!finishDate) {
  //     return getFormatDate(orderProgress?.order_in_progress?.finish_at).getDate()
  //   }
  //
  //   if (order.st_confirmed) {
  //     return getFormatDate(order?.dt_updated_at).getDate()
  //   }
  //
  //   return "Não reconhecida"
  // }

  const getCustomerAddress = (order, user) => {
    const addressFound = user?.addresses?.find(
      ({ customer_address }) => customer_address.id_customer_address === order?.customer_address_order?.id_customer_address
    )

    if (!!addressFound) {
      const {
        nm_street,
        street_type,
        neighbourhood: {
          nm_neighbourhood,
          city: {
            nm_city,
            state: { ds_acronym },
          },
        },
        customer_address: { ds_title, ds_address_additional_info: complement, ds_observation: reference, num_address },
      } = addressFound || {}

      return {
        ds_title,
        street_type,
        nm_street: nm_street || "Não identificada",
        num_address,
        reference,
        complement,
        nm_neighbourhood,
        nm_city,
        ds_acronym,
      }
    }

    return null
  }

  const customerAddress = getCustomerAddress(order, user)

  const getItemsOrderDetails = () => {
    let productsItems = []
    let beveragesItems = []
    const { order_items = [], order_benefits = [] } = order
    let benefitsItems = order_benefits

    order_items.forEach((item, index) => {
      if (item.id_class_item === 2) {
        const productEnabled = products.find(({ uuid_product }) => uuid_product === item.uuid_order_class_item)

        if (!!productEnabled) {
          const selectedCombo = order_items.find(({ id_class_item }) => id_class_item === 1)
          const isCombo = selectedCombo && item.id_parent_order_item
          const titleProduct = item.lb_order_item + (!!isCombo ? ` (${selectedCombo.lb_order_item} - ${formatMoney(selectedCombo.val_price)})` : "")

          //Detalhes do tamanho do produto selecionado
          const sizeOrderItem = order_items.find(
            ({ id_parent_order_item, id_class_item }) => id_parent_order_item === item.id_order_item && id_class_item === 3
          )
          const sizeDetails = productEnabled.sizes.find(({ uuid_size }) => uuid_size === sizeOrderItem.uuid_order_class_item)

          //Detalhes dos sabores do produto selecionado
          const flavoursOrderItem = order_items.filter(
            ({ id_parent_order_item, id_class_item }) => id_parent_order_item === item.id_order_item && id_class_item === 4
          )

          //Detalhes dos complementos do produto selecionado
          const complementsOrderItem = order_items.filter(
            ({ id_parent_order_item, id_class_item }) => id_parent_order_item === item.id_order_item && id_class_item === 7
          )

          const totalProduct = order_items.reduce(
            (prev, next) =>
              next.id_class_item !== 8 && (next.id_parent_order_item === item.id_order_item || next.id_order_item === item.id_order_item)
                ? prev + next.val_price
                : prev,
            0
          )
          const flavoursAmount = flavoursOrderItem?.reduce((tot, { amount = 1 }) => amount + tot, 0) || 1

          if (!!sizeDetails) {
            productsItems.push(
              <ProductItem
                key={productEnabled.uuid_product + index}
                isCombo={true}
                productData={{
                  title: titleProduct,
                  image: getUrlAWSManagerFile(sizeDetails.url_image, noImage),
                  amount: item.amount,
                  id_product: productEnabled.id_product,
                  val_price: formatMoney(totalProduct),
                  observation: !!item.ds_order_item ? item.ds_order_item : null,
                  flavours: flavoursOrderItem.map((flavour) => {
                    return {
                      flavour_name: flavour.lb_order_item,
                      // val_price: flavour.val_price,
                      additions: order_items
                        .filter(({ id_parent_order_item, id_class_item }) => id_parent_order_item === flavour.id_order_item && id_class_item === 6)
                        .map(({ lb_order_item, val_price, amount = 1 }) => {
                          return {
                            nm_ingredient: lb_order_item,
                            val_price: val_price * amount * flavoursAmount,
                          }
                        }),
                      restrictions: order_items
                        .filter(({ id_parent_order_item, id_class_item }) => id_parent_order_item === flavour.id_order_item && id_class_item === 5)
                        .map(({ lb_order_item }) => {
                          return lb_order_item
                        }),
                    }
                  }),
                  complements: complementsOrderItem.map((complement) => {
                    return {
                      nm_complement: complement.lb_order_item,
                      val_price: complement.val_price,
                    }
                  }),
                }}
              />
            )
          }
        } else {
          //
          productsItems
            .push
            // <ProductItem
            //   key={product.uuid_product + index}
            //   isCombo={false}
            //   productData={{
            //     title: getProductName(product),
            //     image: getUrlAWSManagerFile(product.size?.url_image),
            //     amount: product.amount,
            //     id_product: product.id_product,
            //     nro_combo: product.nro_combo,
            //     nro_product: product.nro_product,
            //     val_price: getProductPrice(product),
            //     observation: !!product.ds_obs ? product.ds_obs : null,
            //     flavours: extractFlavoursDetails(product, !!product.id_combo),
            //     complements: extractComplementsDetails(product.complements, !!product.id_combo),
            //   }}
            //   changeAmount={onChangeProductAmount}
            // />
            ()
        }
      }

      if (item.id_class_item === 8) {
        const beverageEnabled = beverages.find(({ uuid_beverage }) => uuid_beverage === item.uuid_order_class_item)

        if (!!beverageEnabled) {
          const [category, name] = getBeverageName(beverageEnabled)
          beveragesItems.push(
            <BeverageItem
              key={beverageEnabled.uuid_beverage + "_" + category + "_" + name + "_" + index}
              isCombo={true}
              productBeverage={true}
              beverageData={{
                category,
                name,
                id_beverage: beverageEnabled.id_beverage,
                amount: item.amount,
                val_price: item.val_price,
                image: getUrlAWSManagerFile(beverageEnabled.url_image),
              }}
            />
          )
        } else {
          beveragesItems.push(
            <BeverageItem
              key={item.uuid_order_class_item + "_" + index}
              isCombo={true}
              productBeverage={true}
              beverageData={{
                name: item.lb_order_item,
                id_beverage: item.id_order_class_item,
                amount: item.amount,
                val_price: item.val_price,
                image: getUrlAWSManagerFile(null, noImage),
              }}
            />
          )
        }
      }
    })

    return { benefitsItems, productsItems, beveragesItems }
  }

  const getFullScheduleDate = () => {
    const currentDate = new Date()
    const newDate = new Date(order.dt_schedule + " " + order.start_hour)

    let date = defaultDays[newDate.getDay()] + ", " + getFormatDate(newDate).getDate("DD/MM/YYYY")

    if (getFormatDate(currentDate).getDate("DD/MM/YYYY") === getFormatDate(newDate).getDate("DD/MM/YYYY")) date = "Hoje"

    return `${date} - ${order.nro_order}`
  }

  const companyGeolocation = React.useRef(null)
  const companyAddress = company?.company_address?.find(({ st_primary }) => st_primary === true)

  if (!!companyAddress) {
    companyGeolocation.current = {
      latitude: companyAddress.geo_coordinates[0],
      longitude: companyAddress.geo_coordinates[1],
    }
  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const defaultDays = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"]

  if (status) {
    return (
      <div className={"order-details " + (!status ? "" : "order-details-active")}>
        <div className={"order-details--header"}>
          <FontAwesomeIcon className="order-details--header--return" icon={"chevron-left"} onClick={returnMenu} />
          <span>{order.schedule ? "Pedido Agendado" : "Detalhes do Pedido"}</span>
        </div>

        {order.schedule ? (
          <div className="order-details--schedule--body">
            <div className="order-details--schedule--body--top">
              <span>Seu pedido foi agendado para:</span>
              <span>{getFullScheduleDate()}</span>
            </div>

            <div className="order-details--schedule--body--center">
              <div className="order-details--schedule--body--center--left">
                <FontAwesomeIcon icon={"location-dot"} />
              </div>
              {order.modality.id_modality === 1 ? (
                <>
                  <div className="order-details--schedule--body--center--center">
                    <div className="order-details--schedule--body--center--center--upper">
                      <span>Entregar em</span>
                    </div>
                    <div className="order-details--schedule--body--center--center--bottom">
                      <p>
                        {`${!!customerAddress?.street_type?.nm_type || "Rua"} ${customerAddress.nm_street}
                        ${!!customerAddress.num_address ? `, ${customerAddress.num_address}` : "S/N"}`}
                      </p>
                      <p>
                        {`
                        ${!!customerAddress.nm_neighbourhood ? customerAddress.nm_neighbourhood : ""}
                       ${!!customerAddress.nm_city ? `- ${customerAddress.nm_city}` : ""}
                      `}
                      </p>
                    </div>
                  </div>
                  <div className="order-details--schedule--body--center--right" />
                </>
              ) : (
                ""
              )}

              {[3, 2].includes(order.modality.id_modality) ? (
                <>
                  <div className="order-details--schedule--body--center--center">
                    <div className="order-details--schedule--body--center--center-upper">
                      <span>{order.modality.id_modality === 2 ? "Retirar em" : "Consumir em"}</span>
                    </div>
                    <div className="order-details--schedule--body--center--center--bottom">
                      <span>
                        {companyAddress?.street?.nm_street}, {companyAddress?.num_address} - {companyAddress?.street?.neighbourhood?.city?.nm_city}
                      </span>
                    </div>
                  </div>
                  <div
                    className="order-details--schedule--body--center--right"
                    onClick={() => {
                      window.open(
                        `http://www.google.com/maps/place/${companyGeolocation.current.latitude},${companyGeolocation.current.longitude}`,
                        "_blank",
                        "location=yes"
                      )
                    }}
                  >
                    <span>Abrir no mapa</span>
                    <FontAwesomeIcon icon={"chevron-right"} />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="order-details--schedule--body--bottom" onClick={() => setModalCompanyContacts(true)}>
              <div className="order-details--schedule--body--bottom--left">
                <FontAwesomeIcon icon={"phone"} />
              </div>
              <div className="order-details--schedule--body--bottom--center">
                <span>Fale com o restaurante</span>
              </div>
              <div className="order-details--schedule--body--bottom--right">
                <FontAwesomeIcon icon={"chevron-right"} />
              </div>
            </div>
          </div>
        ) : (
          <div className="order-details--body">
            <div className="order-details--body--top">
              <div className="order-details--body--top--header">
                <img src={!!order?.company?.url_image ? getUrlAWSManagerFile(order.company.url_image) : logoImage} alt="Logo do restaturante" />
                <div className="order-details--body--top--header--right">
                  <div>
                    <Label>{setNumber(order?.nro_order)}</Label>
                    <Label>{order?.company?.nm_company || "Desconhecido"}</Label>
                  </div>
                  <Label>{getFormatDate(order?.dt_created_at).getDate()}</Label>
                </div>
              </div>
              <div
                className={
                  "order-details--body--top--footer " +
                  (order.dt_deleted_at ? "canceled" : "") +
                  (order?.order_progress[0]?.is_final_step ? "final" : "")
                }
              >
                <div className="order-details--body--top--footer-status">
                  <div className="order-details--body--top--footer-status-circle" />
                  {(() => {
                    if (!order.st_approved && order.order_progress.length === 1) {
                      return <Label>Aguardando aprovação do restaurante</Label>
                    }

                    if (order.st_approved) {
                      if (order.order_progress[0].master_step_id === 3 && formatBoolean(settings.dispatch_order_ready)) {
                        return <Label>Seu pedido saiu para entrega</Label>
                      }

                      if ([5].includes(order.order_progress[0].master_step_id)) {
                        if (order.order_progress[0].is_final_step) {
                          return <Label>Seu pedido foi entregue</Label>
                        }

                        return <Label>Seu pedido saiu para entrega</Label>
                      } else {
                        return <Label>Seu pedido está em preparo</Label>
                      }
                    }
                  })()}
                </div>
                <CFButton
                  title={"Acompanhar pedido"}
                  extraClass={"order-details--body--top--footer-follow"}
                  onClick={() => {
                    handleOrderInfo({}, order, "progress")
                  }}
                  outline={true}
                />
              </div>

              {(() => {
                if (order.dt_deleted_at && !!order.orders_cancellations) {
                  return (
                    <div className={"order-details--body--top--footer reason"}>
                      <Label className="reason-label">{capitalize(order.orders_cancellations?.message_reason.toLowerCase())}</Label>
                    </div>
                  )
                }
              })()}
            </div>
            <div className="order-details--body--center">
              {(() => {
                const { productsItems } = getItemsOrderDetails()

                if (productsItems.length > 0) {
                  return (
                    <div className="order-details--body--center--products">
                      <span className="order-details--body--center--products--title">PRODUTOS:</span>
                      {productsItems.map((product) => {
                        return product
                      })}
                    </div>
                  )
                }
              })()}
              {(() => {
                const { beveragesItems } = getItemsOrderDetails()

                if (beveragesItems.length > 0) {
                  return (
                    <div className="order-details--body--center--beverages">
                      <span className="order-details--body--center--beverages--title">BEBIDAS:</span>
                      {beveragesItems.map((beverage) => {
                        return beverage
                      })}
                    </div>
                  )
                }
              })()}
              {(() => {
                const { benefitsItems } = getItemsOrderDetails()

                if (benefitsItems.length > 0) {
                  return (
                    <div className="order-details--body--center--benefits">
                      <span>CUPONS:</span>
                      {benefitsItems.map((benefit, index) => {
                        return (
                          <div key={index} className="order-details--body--center--benefits--item">
                            <span>{benefit.nm_benefit}</span>, valor {formatMoney(parseFloat(benefit.val_benefit))}
                          </div>
                        )
                      })}
                    </div>
                  )
                }
              })()}
            </div>
            <div className="order-details--body--bottom">
              <div className="order-details--body--bottom--payments">
                <div />
              </div>
              {(() => {
                const discountType = !!order.pc_discount && order.pc_discount > 0 ? "%" : "R$"
                const discountOrder =
                  !!order.pc_discount && order.pc_discount > 0
                    ? order.pc_discount
                    : order.val_discount && order.val_discount > 0
                    ? order.val_discount
                    : 0

                const subTotal = order?.val_total - order?.val_benefits
                const discount = getDiscountOrder(
                  { discountType, discount: discountOrder },
                  subTotal,
                  order?.val_delivery_tax,
                  settings,
                  modality,
                  true
                )

                return (
                  <OrderTotalInfo
                    total={order?.val_total}
                    totalBenefits={order?.val_benefits}
                    deliveryFee={order?.val_delivery_tax}
                    additionalFee={order?.val_additional_tax}
                    totalDiscount={discount}
                    discountTypeOrder={discountType}
                    discountOrder={discountOrder}
                    valSubTotal={order?.val_subtotal}
                  />
                )
              })()}
              <div className="order-details--body--bottom--address">
                <div className="order-details--body--address--top">
                  <Label>{order.modality.id_modality === 1 ? "Endereço de Entrega" : ""}</Label>
                </div>
                {order.modality.id_modality === 1 && !!customerAddress ? (
                  <div className="order-details--body--address--bottom">
                    <Label className="order-details--body--address--bottom--title">{customerAddress.ds_title ? customerAddress.ds_title : ""}</Label>
                    <Label className="order-details--body--address--bottom--street">
                      {customerAddress?.street_type?.nm_type || "Rua"} {customerAddress.nm_street}{" "}
                      {!!customerAddress.num_address ? `, ${customerAddress.num_address}` : ""}
                      {customerAddress.complement || ""}
                    </Label>
                    <Label className="order-details--body--address--bottom--city">
                      {customerAddress.nm_neighbourhood} {customerAddress.nm_city}{" "}
                      {!!customerAddress.ds_acronym ? `- ${customerAddress.ds_acronym}` : ""}
                      {customerAddress.reference || ""}
                    </Label>
                    <Label className="order-details--body--address--bottom--extra">{customerAddress.complement}</Label>
                    <Label className="order-details--body--address--bottom--extra">{customerAddress.reference}</Label>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/*<CFButton*/}
              {/*  disabled={true}*/}
              {/*  title="Refazer o pedido"*/}
              {/*  style={{ width: "80%" }}*/}
              {/*  onClick={() => {*/}
              {/*    onMessageDialog().fire({*/}
              {/*      title: "Função desabilitada pelo estabelecimento",*/}
              {/*      icon: "info",*/}
              {/*      timer: 3000,*/}
              {/*      showCancelButton: false,*/}
              {/*      showConfirmButton: false,*/}
              {/*    })*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          </div>
        )}
      </div>
    )
  }

  return ""
}

export default OrderDetails
