// Resources
import API from "../helpers/API"

/**
 * Componente funcional para manipulacão dos dados do pixel
 */
const PixelController = {
  getPixelId() {
    return API.request("/v1/integration/pixel/id", {}, "GET")
  },
}

export default PixelController
