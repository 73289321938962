import React, { useEffect } from "react"
import PixelController from "../controllers/PixelController"
import { getLocalItem, setLocalItem } from "../helpers/StorageTools"

const FacebookPixelManager = ({ companyId, activePixelId, setActivePixelId }) => {
  const pixelInitializedRef = React.useRef(null)

  useEffect(() => {
    if (activePixelId.companies.includes(companyId)) {
      console.info("PIXEL ATIVO PARA:", { companyId })
      return
    }
    getPixelIntegation().then()
    return () => {
      console.info("Desmontando Facebook Pixel para:", companyId)
    }
  }, [])

  const getPixelIntegation = async () => {
    // Verificando dado local para inicialização mais rápida
    const pixelIdLocal = getLocalItem("pixelId")
    if (!!pixelIdLocal) {
      await initPixel(pixelIdLocal)
    }
    // Verificando os dados no banco de dados para ver se houve alteração
    const responsePixelId = await PixelController.getPixelId()
    if (responsePixelId.status === 200) {
      const pixelId = responsePixelId?.data?.pixel_id
      if (
        !!pixelId &&
        pixelId.length > 0 &&
        activePixelId?.pixelId !== pixelId &&
        pixelId !== pixelIdLocal &&
        pixelInitializedRef.current !== pixelId
      ) {
        await initPixel(pixelId)
      } else {
        console.info("PIXEL ATIVO PARA:", companyId)
      }
    }
  }

  const initPixel = async (pixelId) => {
    await import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(pixelId)
        ReactPixel.pageView()
        setActivePixelId({ companies: [...activePixelId.companies, companyId], pixelId })
        setLocalItem("pixelId", pixelId)
        pixelInitializedRef.current = pixelId
        console.info("NOVO PIXEL INICIALIZADO:", companyId)
      })
  }

  return null
}

export default FacebookPixelManager
