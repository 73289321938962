import React from "react"
import moment from "moment"
import ReactPixel from "react-facebook-pixel"

// import Geocode from "react-geocode"
import socketIOClient from "socket.io-client"
import { loadMercadoPago } from "@mercadopago/sdk-js"

import { useState } from "react"
import { Button, Label } from "reactstrap"
import { ErrorBoundary } from "react-error-boundary"

import "moment/locale/pt-br"

//Contexts
import TabContext from "./contexts/TabContext"
import MenuContext from "./contexts/MenuContext"
import UserContext from "./contexts/UserContext"
import OrderContext from "./contexts/OrderContext"
import SocketContext from "./contexts/SocketContext"
import LoadingContext from "./contexts/LoadingContext"
import CompanyContext from "./contexts/CompanyContext"
import SettingsContext from "./contexts/SettingsContext"
import MercadoPagoContext from "./contexts/MercadoPagoContext"

//Components
import Header from "./components/Header"
import Footer from "./components/Footer"
import OrderResumeBar from "./components/OrderResumeBar"
import FullContentLoading from "./components/FullContentLoading"
import OrderPreview from "./components/OrderPreview/OrderPreview"
import Profile from "./components/Profile/Profile"
import Main from "./views/sections/Main"
import ModalBuilder from "./views/modals/ModalBuilder"
import Message from "./components/Message"
import Highlights from "./views/sections/Highlights"
import Combos from "./views/sections/Combos"
import Products from "./views/sections/Products"
import Beverages from "./views/sections/Beverages"
import toast, { Toaster } from "react-hot-toast"

//Controllers
import UserController from "./controllers/UserController"
import OrderController from "./controllers/OrderController"
import PopupController from "./controllers/PopupController"
import ComboController from "./controllers/ComboController"
import ProductController from "./controllers/ProductController"
import CompanyController from "./controllers/CompanyController"
import BenefitController from "./controllers/BenefitController"
import SettingController from "./controllers/SettingController"
import SupportController from "./controllers/SupportController"
import FidelityController from "./controllers/FidelityController"
import CustomerController from "./controllers/CustomerController"
import BeverageController from "./controllers/BeverageController"
import ScheduleController from "./controllers/ScheduleController"
import CredentialController from "./controllers/CredentialController"
import HighlightsController from "./controllers/HighlightsController"
import DeliveryFeeController from "./controllers/DeliveryFeeController"
import DeliveryAreasController from "./controllers/DeliveryAreasController"
import MercadoPagoController from "./controllers/MercadoPagoController"
import PaymentsController from "./controllers/PaymentsController"

// Modals
import ModalPopup from "./views/modals/ModalPopup"
import ModalSignIn from "./views/modals/ModalSignIn"
import ModalBlocked from "./views/modals/ModalBlocked"
import ModalCompanies from "./views/modals/ModalCompanies"
import ModalBeverages from "./views/modals/ModalBeverages"
import ModalSuccessOrder from "./views/modals/ModalSuccessOrder"
import ModalOrderFinisher from "./views/modals/ModalOrderFinisher/ModalOrderFinisher"
import ModalOrderProcess from "./views/modals/ModalOrderProcess/ModalOrderProcess"
import ModalOrderSchedule from "./views/modals/ModalOrderSchedule/ModalOrderSchedule"
import ModalInitialAddress from "./views/modals/ModalInitialAddress/ModalInitialAddress"
import ModalMapAddress from "./views/modals/ModalMapAddress/ModalMapAddress"
import ModalNumberAddress from "./views/modals/ModalNumberAddress/ModalNumberAddress"
import ModalFinalAddress from "./views/modals/ModalFinalAddress/ModalFinalAddress"
import ModalCompanyContacts from "./views/modals/ModalCompanyContacts/ModalCompanyContacts"
import ModalAddressNotFound from "./views/modals/ModalAddressNotFound/ModalAddressNotFound"
import ModalPixPayment from "./views/modals/ModalPixPayment/ModalPixPayment"

// Helpers
import { getDiscountOrder, getParamsToCreateOrder, getTotalOrder } from "./helpers/OrderTools"
import { getBeverageName, getProductName } from "./helpers/BuilderTools"
import { userIsConnected, getLocalUser } from "./helpers/UserTools"
import GeneralTools, {
  formatBoolean,
  onMessageDialog,
  formatFloat,
  getFormatDate,
  getUrlAWSManagerFile,
  escapeSpecialChart,
} from "./helpers/GeneralTools"
import { checkBetweenIntervalPeriod } from "./helpers/DateTools"
import {
  getCity,
  getLabelAddress,
  getNeighbourhood,
  getState,
  getStates,
  getStreetName,
  getStreetTypes,
  getDistanceCoordinates,
  getAddressSuggestionsByName,
  getAddressCoordinates,
} from "./helpers/AddressessTools"
import {
  encrypt,
  getLocalItem,
  getSessionItem,
  removeLocalItem,
  removeSessionItem,
  setLocalItem,
  setSessionItem,
  getCookie,
  setCookie,
  removeCookie,
} from "./helpers/StorageTools"

import ModalClientAddressNotFound from "./views/modals/ModalClientAddressNotFound/ModalClientAddressNotFound"

// Sytles
import logoImage from "./assets/img/brand/logo.png"
import "./App.scss"
import ModalMinimapAddress from "./views/modals/ModalMinimapAddress/ModalMinimapAddress"
import FacebookPixelManager from "./components/FacebookPixelManager"

moment.locale("pt-br")

const App = () => {
  const version = process.env.REACT_APP_VERSION

  const [errorMessage, setErrorMessage] = React.useState([])

  const [loading, setLoading] = React.useState(true)
  const [forceLoading, setForceLoading] = React.useState(false)
  const [addressesLoading, setAddressesLoading] = React.useState(false)

  const [order, setOrder] = React.useState({
    customer: null,
    combos: [],
    products: [],
    beverages: [],
    payments: [],
    benefits: [],
    total: 0.0,
    subTotal: 0.0,
    discount: 0,
    discountType: "R$",
    deliveryFee: 0,
    deliveryComments: "",
  })
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState({
    id_payment_method: -1,
  })

  const [currentOrderCombo, setCurrentOrderCombo] = React.useState(0)

  const [user, setUser] = React.useState(null)
  const [socket, setSocket] = React.useState(null)
  const [company, setCompany] = React.useState({})

  const [tabSelected, setTabSelected] = React.useState("home")
  const [hideTabs, setHideTabs] = React.useState(false)

  const [menuFixed, setMenuFixed] = React.useState(false)
  const [menuOptions, setMenuOptions] = React.useState([])

  const [combos, setCombos] = React.useState([])
  const [settings, setSettings] = React.useState([])
  const [products, setProducts] = React.useState([])
  const [popup, setPopup] = React.useState(null)
  const [highlights, setHighlights] = React.useState([])
  const [beverages, setBeverages] = React.useState([])
  const [schedules, setSchedules] = React.useState([])
  const [companies, setCompanies] = React.useState([])
  const [fidelities, setFidelities] = React.useState([])
  const [benefits, setBenefits] = React.useState([])
  const [deliveryMans, setDeliveryMans] = React.useState([])

  const [modality, setModality] = React.useState(0) // 1 - entreta / 2 - retirada
  const [currentStep, setCurrentStep] = React.useState({ step: 0, date: "00:00" })

  const [deliveryType, setDeliveryType] = useState("direct")

  const [isOutsideCoverageArea, setIsOutsideCoverageArea] = React.useState(false)
  const [finalizingOrder, setFinalizingOrder] = React.useState(false)

  const [states, setStates] = React.useState([])
  const [neighbourhoods, setNeighbourhoods] = React.useState([])
  const [streetTypes, setStreetTypes] = React.useState([])

  const [userSelectedAddress, setUserSelectedAddress] = React.useState(null)
  const [modalContentOrder, setModalContentOrder] = React.useState(false)
  const [modalSignIn, setModalSignIn] = React.useState({ status: false })
  const [modalOrderFinisher, setModalOrderFinisher] = React.useState(false)
  const [modalOrderProcess, setModalOrderProcess] = React.useState({
    status: false,
    content: null,
    resend: false,
    newRequest: false,
    scheduleInfo: null,
  })
  const [modalOrderPreview, setModalOrderPreview] = React.useState(false)
  const [modalOrderSchedule, setModalOrderSchedule] = React.useState(false)
  const [modalCompanyContacts, setModalCompanyContacts] = React.useState(false)
  const [modalCompanies, setModalCompanies] = React.useState({ status: false, content: null, earlyAddress: null })
  const [modalPaymentMethods, setModalPaymentMethods] = useState({ status: false, content: null })
  const [modalBuilder, setModalBuilder] = useState({ status: false, type: "product", content: null })
  const [modalBeverages, setModalBeverages] = useState({ status: false, content: null })
  const [modalSuccessOrder, setModalSuccessOrder] = useState({ status: false, content: null })
  const [modalPopup, setModalPopup] = React.useState({ status: true })
  const [modalBlockByOutdatedVersion, setModalBlockByOutdatedVersion] = React.useState({ status: false, content: null })
  const [modalClientAddressNotFound, setModalClientAddressNotFound] = React.useState({ status: false, address: null })
  const [modalAddressNotFound, setModalAddressNotFound] = React.useState({ status: false, title: "", companies: [] })
  const [modalPixPayment, setModalPixPayment] = React.useState({ status: false, content: null })
  const [modalMinimapAddress, setModalMinimapAddress] = React.useState({ status: false, content: null })

  const [modalInitialAddress, setModalInitialAddress] = React.useState({ status: false, content: null })
  const [modalMapAddress, setModalMapAddress] = React.useState({ status: false, location: {} })
  const [modalNumberAddress, setModalNumberAddress] = React.useState({ status: false, address: {} })
  const [modalFinalAddress, setModalFinalAddress] = React.useState({ status: false, address: {}, editNeighbourhood: false })
  const [showPaymentTypeSelector, setShowPaymentTypeSelector] = React.useState(false)
  const [recoverySettings, setRecoverySettings] = React.useState({})

  const [colors, setColors] = React.useState([
    { id: 1, used: false, color: "#F2D7D5" },
    { id: 2, used: false, color: "#D4E6F1" },
    { id: 3, used: false, color: "#D4EFDF" },
    { id: 4, used: false, color: "#FDEBD0" },
    { id: 5, used: false, color: "#FADBD8" },
    { id: 6, used: false, color: "#EBDEF0" },
    { id: 7, used: false, color: "#D6EAF8" },
    { id: 8, used: false, color: "#D1F2EB" },
    { id: 9, used: false, color: "#D6DBDF" },
    { id: 10, used: false, color: "#F4ECF7" },
    { id: 11, used: false, color: "#FAE5D3" },
  ])
  const [updateOrders, setUpdateOrders] = React.useState(false)
  const [activePixelId, setActivePixelId] = React.useState({ companies: [], pixelId: null })

  const companyRef = React.useRef({ uuid_company: null })
  const menuOptionsRef = React.useRef(menuOptions)
  const deliveryFeesRef = React.useRef(null)
  const deliveryAreasRef = React.useRef(null)
  const orderSuccessId = React.useRef(null)
  const popupAlreadyOpen = React.useRef(false)
  const goToOrderProgressRef = React.useRef({ status: false, orderUid: null, scheduleOrderUid: null })
  const modalPixPaymentContent = React.useRef(null)
  const goToFidelityRef = React.useRef(false)
  const goToOrdersRef = React.useRef(false)
  const editAddressRef = React.useRef(null)
  const menuFixedRef = React.useRef(menuFixed)
  const finalizingOrderRef = React.useRef(false)
  const userRef = React.useRef(null)
  const clickRef = React.useRef(0)
  const scheduleInfoRef = React.useRef(null)
  const userSelectedAddressRef = React.useRef(null)
  const showOrderProgressContextRef = React.useRef({ status: false, orderUid: null, scheduleOrderUid: null })

  // Objeto para armazenar a instância do mercado pago
  const [MercadoPagoInstance, setMercadoPagoInstance] = React.useState({})

  // React.useEffect(() => {
  //   if (!!process.env.REACT_APP_FB_ID && process.env.REACT_APP_FB_ID.length > 0) {
  //     import("react-facebook-pixel")
  //       .then((x) => x.default)
  //       .then((ReactPixel) => {
  //         ReactPixel.init(process.env.REACT_APP_FB_ID) // facebookPixelId
  //         ReactPixel.pageView()
  //         isPixelInitializedRef.current = true
  //       })
  //   }
  // }, [])

  /* eslint-disable */
  React.useEffect(async () => {
    setLoading(true)

    const searchParams = new URLSearchParams(document.location.search)

    const customerUid = searchParams.get("c")
    const expiresIn = searchParams.get("e")

    if (customerUid !== null || expiresIn !== null) {
      setRecoverySettings({ customerUid: customerUid, expiresIn: expiresIn })
      setModalSignIn({ status: true })
    }

    //Verificando se o cliente está na versão atual correta
    //Caso não esteja, zerar o localstorage e cookies para evitar erros
    verifyWebsiteVersion().then()

    getCookie("publicToken")
      .then(async (publicToken) => {
        let storageCompany = getLocalItem("company", true)
        let storageCompanyIds = getLocalItem("companyIds", true)

        let hostStorage = getLocalItem("siteHost")

        if (!publicToken || hostStorage !== window.location.host) {
          // Obtendo as credenciais do assinante
          CredentialController.fetchOne().then((responseCredentials) => {
            if (responseCredentials.status === 200) {
              const [subscriberUid, accessToken, database, companyIds = []] = responseCredentials.data
              if (hostStorage !== window.location.host) {
                storageCompany = null
              }

              publicToken = accessToken
              setLocalItem("database", database)
              setLocalItem("companyIds", companyIds, true)
              setLocalItem("siteHost", window.location.host)
              setCookie("subscriberUid", subscriberUid).then()
              setCookie("publicToken", publicToken).then(() => {
                setOnStartInfo(publicToken, storageCompany, companyIds)
              })
            } else {
              setLoading(false)
              setErrorMessage([
                "Não foi possível carregar o conteúdo do site.",
                "Favor atualizar a página! ",
                "Caso o erro persista, favor reportar imediatamente ao estabelecimento! ",
              ])
            }
          })
        } else {
          setOnStartInfo(publicToken, storageCompany, storageCompanyIds)
        }
      })
      .catch((error) => {
        console.error("ERROR", error)
        setLoading(false)
        setErrorMessage(["Não foi possível carregar o conteúdo do site.", "Favor reportar imediatamente ao estabelecimento! "])
      })

    // Eventos de rolagem da página
    document.getElementById("section-home").addEventListener("scroll", () => {
      document.getElementById("section-home").scrollHeight

      // Verificando se o rolar da página já chegou na posição do tamanho do cabeçalho
      // as vezes o getGlement não renderiza e por isso tem que validar antes.

      if (document.getElementById("header") && document.getElementById("section-home").scrollTop >= document.getElementById("header").offsetHeight) {
        if (menuFixedRef.current === false) {
          menuFixedRef.current = true
          setMenuFixed(true)
        }
        // setSidebarMenu(false)
      } else {
        if (menuFixedRef.current === true) {
          menuFixedRef.current = false
          setMenuFixed(false)
        }
        // setSidebarMenu(true)
      }

      for (const { id } of menuOptionsRef.current) {
        if (!!document.getElementById(id)) {
          // Definindo o valor da seção de acordo com o tamanho e posição na página
          const offset = document.getElementById(id).offsetTop + document.getElementById(id).offsetHeight - 200

          // Se for maior significa que está sendo mostrado
          if (offset > document.getElementById("section-home").scrollTop) {
            const newMenuOptions = menuOptionsRef.current.map((item) => {
              return { ...item, focus: item.id === id }
            })

            // Mudando o item do menu ativo
            menuOptionsRef.current = [...newMenuOptions]

            // setMenuOptions([...newMenuOptions])

            // const menuFixed = document.getElementsByClassName('menu-options-fixed')[0]
            //
            // if (!!menuFixed) {
            //     const menuChildren = Array.from(menuFixed.children)
            //     const optionIndex = menuChildren.findIndex(item => item.className === 'active')
            //
            //     if (optionIndex !== -1) {
            //
            //         if (optionIndex === 0) {
            //             menuFixed.scrollTo(0, 0)
            //         } else if (optionIndex === menuChildren.length - 1) {
            //             menuFixed.scrollTo(menuFixed.offsetWidth, 0)
            //         } else {
            //             menuFixed.scrollTo(menuChildren[optionIndex].offsetWidth, 0)
            //         }
            //     }
            // }

            break
          }
        }
      }
    })
  }, [])

  React.useEffect(async () => {
    showRecentPendingPayment()
  }, [userRef.current])

  React.useEffect(() => {
    const popupStorage = getLocalItem("popups", true)
    if (!!popupStorage) {
      const companyPopup = popupStorage?.data?.rows?.find(
        ({ fk_id_company, fk_id_subscriber }) =>
          company.id_company === fk_id_company && company?.subscriber?.id_subscriber === fk_id_subscriber && !modalCompanies.status
      )
      if (!!companyPopup && !popupAlreadyOpen.current) {
        popupAlreadyOpen.current = true
        showPopupMessage(companyPopup, companyPopup)
      }
    }
  }, [company])

  React.useEffect(() => {
    getDiscountType()
  }, [modality])

  const verifyWebsiteVersion = async () => {
    const buildVersion = process.env.REACT_APP_VERSION
    const currentWebsiteVersion = await SupportController.getWebsiteVersion()
    if (currentWebsiteVersion.status === 200) {
      const currentWebsiteVersionList = (currentWebsiteVersion?.data?.nro_version || "0.0.0").split(".").map(Number)
      const buildVersionList = buildVersion.split(".").map(Number)

      // if (currentWebsiteVersion.data.nro_version !== buildVersion) {
      if (
        currentWebsiteVersionList[0] !== buildVersionList[0] ||
        currentWebsiteVersionList[1] !== buildVersionList[1] ||
        buildVersionList[2] < currentWebsiteVersionList[2]
      ) {
        setModalBlockByOutdatedVersion({
          status: true,
          content: {
            buildVersion,
            currentVersion: currentWebsiteVersion.data.nro_version,
          },
        })
      }
    } else {
      setModalBlockByOutdatedVersion({
        status: true,
        content: {
          buildVersion,
          currentVersion: null,
          getWebsiteVersionFailed: true,
        },
      })
    }
  }

  const showRecentPendingPayment = async () => {
    if (!!userRef.current) {
      const pendingPaymentsRequest = await PaymentsController.getRecentPendingPayments(userRef.current.uuid_customer)

      if (pendingPaymentsRequest.status === 200 && !modalPixPayment.status) {
        const pendingPayment = pendingPaymentsRequest.data

        const paymentInfoRequest = await PaymentsController.getPaymentInfo(pendingPayment.payment_id)

        if (paymentInfoRequest.status === 200) {
          setModalPixPayment({ status: true, content: paymentInfoRequest.data })
        }
      }
    }
  }

  // Buscar configurações de desconto por modalidade
  const getDiscountType = (modalityOrder, changeOrder) => {
    let newOrder = { ...order }
    let discountType = "R$"
    let discount = "0,00"

    let newModality = !!modalityOrder ? modalityOrder : modality
    // if (!!modality) {
    switch (newModality) {
      case 1:
        if (!!settings.delivery_discount_type) {
          discountType = settings.delivery_discount_type
          newOrder = { ...newOrder, discountType }
        }

        if (!!settings.delivery_discount_value) {
          const [total, subTotal] = getTotalOrder(newOrder, userSelectedAddress?.deliveryFee)
          const discount = parseFloat(settings.delivery_discount_value)
          // getDiscountOrder(newOrder, subTotal, userSelectedAddress?.deliveryFee, settings, newModality)
          newOrder = { ...newOrder, discount, ds_discount: discount > 0 ? "Desconto de modalidade" : "" }
        }
        if (changeOrder) {
          setOrder({ ...newOrder })
        }
        return { discountType, discount }
      case 2:
        if (!!settings.pickup_discount_type) {
          discountType = settings.pickup_discount_type
          newOrder = { ...newOrder, discountType }
        }

        if (!!settings.pickup_discount_value) {
          const [total, subTotal] = getTotalOrder(newOrder, userSelectedAddress?.deliveryFee)
          const discount = parseFloat(settings.pickup_discount_value)
          // getDiscountOrder(newOrder, subTotal, userSelectedAddress?.deliveryFee, settings, newModality)
          newOrder = { ...newOrder, discount, ds_discount: discount > 0 ? "Desconto de modalidade" : "" }
        }
        if (changeOrder) {
          setOrder({ ...newOrder })
        }
        return { discountType, discount }
      case 3:
        if (!!settings.indoor_discount_type) {
          discountType = settings.indoor_discount_type

          newOrder = { ...newOrder, discountType }
        }

        if (!!settings.indoor_discount_type) {
          const discount = parseFloat(settings.indoor_discount_value)
          // getDiscountOrder(newOrder, subTotal, userSelectedAddress?.deliveryFee, settings, newModality)
          newOrder = { ...newOrder, discount, ds_discount: discount > 0 ? "Desconto de modalidade" : "" }
        }
        if (changeOrder) {
          setOrder({ ...newOrder })
        }
        return { discountType, discount }
      default:
        newOrder = { ...newOrder, discountType, discount }
        if (setOrder) {
          setOrder({ ...newOrder })
        }
        return { discountType, discount }
    }
    // }
  }
  //Dados do socket
  const setOnSocket = (publicToken, database) => {
    const socket = socketIOClient(process.env.REACT_APP_CF_SOCKET_HOST, {
      transports: ["websocket"],
      query: { token: publicToken, origin: "site-subscriber", database },
    })

    socket.on("store_openned", () => {
      console.info("RECEBENDO DADOS DO SOCKET [Home] [store_openned]")
      onChangeStatusCompany(true)
    })

    socket.on("store_closed", () => {
      console.info("RECEBENDO DADOS DO SOCKET [Home] [store_closed]")
      onChangeStatusCompany(false)
    })

    socket.on("online_payment_approved", async (socketInfo) => {
      console.info("RECEBENDO DADOS DO SOCKET [Home] [online_payment_approved]")
      if (companyRef.current?.id_company === socketInfo.fk_id_company && socketInfo.uuid_customer === userRef.current?.uuid_customer) {
        goToOrderProggressAfterOnlinePayment(socketInfo.order.uuid_order)
      }
    })

    // //pedido aprovado
    // socket.on("approve_orders", (socketinfo) => {
    //   if (company.id_company === socketinfo.companyId || companyRef.current.id_company === socketinfo.companyId) {
    //     console.info("RECEBENDO DADOS DO SOCKET [Home] [approve_orders]")
    //     const newDate = new Date()
    //     setCurrentStep({
    //       step: 1,
    //       date: `${newDate.getHours()}:${newDate.getMinutes()}`,
    //       orderId: socketinfo.orderId,
    //     })
    //   }
    // })
    //
    // //motoboy iniciou a corrida
    // socket.on("deliveryman_order_started", (socketinfo) => {
    //   if (company.id_company === socketinfo.companyId || companyRef.current.id_company === socketinfo.companyId) {
    //     console.info("RECEBENDO DADOS DO SOCKET [deliveryman] [deliveryman_order_started]")
    //     const newDate = new Date()
    //     setCurrentStep({
    //       step: 2,
    //       date: `${newDate.getHours()}:${newDate.getMinutes()}`,
    //       orderId: socketinfo.orderId,
    //       userId: socketinfo.userId,
    //     })
    //   }
    // })
    //
    // //motoboy se movendo
    // socket.on("deliveryman_moved", ({ user }) => {
    //   //Verificando se o motoboy é vinculado a esta empresa
    //   const userCompany = user.companies.find(({ id_company }) => {
    //     return company.id_company === id_company || companyRef.current.id_company === id_company
    //   })
    //   if (!!userCompany) {
    //     // console.info("RECEBENDO DADOS DO SOCKET [App] [deliveryman_moved]")
    //     setCurrentStep({
    //       ...currentStep,
    //       deliverymanPos: {
    //         latitude: user.latitude,
    //         longitude: user.longitude,
    //       },
    //       userId: user.id_user,
    //       userOrdersInRoute: user.orders_in_route,
    //     })
    //   }
    // })
    //
    // //Motoboy encerrou o pedido
    // socket.on("deliveryman_order_finished", (socketinfo) => {
    //   if (company.id_company === socketinfo.companyId || companyRef.current.id_company === socketinfo.companyId) {
    //     console.info("RECEBENDO DADOS DO SOCKET [deliveryman] [deliveryman_order_finished]")
    //     const newDate = new Date()
    //     setCurrentStep({
    //       step: 3,
    //       date: `${newDate.getHours()}:${newDate.getMinutes()}`,
    //       orderId: socketinfo.orderId,
    //     })
    //   }
    // })
    //
    // //Estabelecimento encerrou o pedido
    // socket.on("finish_orders", (socketinfo) => {
    //   if (company.id_company === socketinfo.companyId || companyRef.current.id_company === socketinfo.companyId) {
    //     console.info("RECEBENDO DADOS DO SOCKET [Home] [finish_orders]")
    //     const newDate = new Date()
    //     setCurrentStep({
    //       step: 3,
    //       date: `${newDate.getHours()}:${newDate.getMinutes()}`,
    //       orderId: socketinfo.orderId,
    //     })
    //   }
    // })

    //Estabelecimento atualizou o tempo de retirada
    socket.on("pickup_time_updated", (socketinfo) => {
      if (company.id_company === socketinfo.companyId || companyRef.current.id_company === socketinfo.companyId) {
        console.info("RECEBENDO DADOS DO SOCKET [Home] [pickup_time_updated]")
        setSettings({ ...settings, pickup_time: socketinfo.pickup_time })
      }
    })

    setSocket(socket)
  }

  const goToOrderProggressAfterOnlinePayment = async (uuidOrder) => {
    clearOrder()
    toast.success("Pedido confirmado com sucesso!")
    setModalPixPayment({ status: false, content: null })

    const actualOrder = await OrderController.fetchOne(uuidOrder)

    setTabSelected("profile")

    showOrderProgressContextRef.current = { status: true, orderUid: uuidOrder, content: actualOrder.data }
    setUpdateOrders(!updateOrders)
  }

  //Zerar os dados do storage e cookies
  const clearStorageData = async () => {
    await removeCookie("publicToken")
    await removeCookie("subscriberUid")
    removeLocalItem("menuOptions")
    removeLocalItem("settings")
    removeLocalItem("highlights")
    removeLocalItem("combos")
    removeLocalItem("products")
    removeLocalItem("productSizes")
    removeLocalItem("productFlavours")
    removeLocalItem("beverages")
    removeLocalItem("schedules")
    removeLocalItem("deliveryFee")
    removeLocalItem("deliveryTime")
    removeLocalItem("userSelectedAddress")
    removeLocalItem("user")
    removeLocalItem("siteHost")
    removeLocalItem("orderItems")
    removeLocalItem("deliveryFees")
    removeLocalItem("states")
    removeLocalItem("streettypes")
    removeLocalItem("modality")
    removeLocalItem("location")
    removeLocalItem("popups")
  }

  const setOnStartInfo = async (publicToken, storageCompany = null, companyIds = []) => {
    const database = getLocalItem("database")
    const menuOptionsStorage = getLocalItem("menuOptions", true)

    if (menuOptionsStorage) {
      setMenuOptions(menuOptionsStorage)
      menuOptionsRef.current = menuOptionsStorage
    }

    console.info("CONECTANDO NO SOCKET...")
    setOnSocket(publicToken, database)

    //Consultando a lista de estados
    const statesLocal = getLocalItem("states", true)
    if (!!statesLocal && statesLocal.length > 0) {
      setStates(statesLocal)
    }
    getStates().then((resultStates) => {
      setStates(resultStates)
      setLocalItem("states", resultStates, true)
    })

    // Consultando os tipos de endereços
    const streetTypesLocal = getLocalItem("streetTypes", true)
    if (!!streetTypesLocal) {
      setStreetTypes(streetTypesLocal)
    }
    getStreetTypes().then((resultStreetTypes) => {
      setStreetTypes(resultStreetTypes)
      setLocalItem("streetTypes", resultStreetTypes, true)
    })

    console.info("CONSULTANDO DADOS DE ESTABELECIMENTOS...")
    let newCompany = storageCompany

    const searchParams = new URLSearchParams(document.location.search)

    const whatsappNumber = searchParams.get("wn")
    const companyIdString = searchParams.get("cId")

    if (whatsappNumber !== null) {
      setForceLoading(true)

      if (!!newCompany) {
        const whatsappCompanyId = parseInt(companyIdString)

        if (newCompany.id_company !== whatsappCompanyId) {
          await setLocalCompany(storageCompany, companyIds, whatsappCompanyId)
        }
      }

      const parsedWhatsappNumber = atob(whatsappNumber)

      const localUser = getLocalUser()

      let makeLogin = false

      if (!!localUser) {
        sessionStorage.clear()
        removeLocalItem("user")
        setUserSelectedAddress(null)
        setUser(null)
        setOrder({ ...order, benefits: [] })

        makeLogin = true
      } else {
        makeLogin = true
      }

      if (makeLogin) {
        setTabSelected("home")

        onSignIn(parsedWhatsappNumber.replace("55", ""), null, true)
          .then(async () => {
            const trackingId = searchParams.get("r")

            if (trackingId !== null) {
              const orderUid = atob(trackingId)

              const actualOrder = await OrderController.fetchOne(orderUid)

              setTabSelected("profile")

              showOrderProgressContextRef.current = { status: true, orderUid: orderUid, content: actualOrder.data }
              setUpdateOrders(!updateOrders)
            }
          })
          .finally(() => {
            setForceLoading(false)

            window.history.pushState({}, document.title, "/" + "")
          })
      }
    }

    const parsedCompanyId = parseInt(companyIdString)
    await setLocalCompany(storageCompany, companyIds, !!parsedCompanyId && !isNaN(parsedCompanyId) ? parsedCompanyId : null)
  }

  const setEditAddressRef = (editAddress) => {
    editAddressRef.current = editAddress
  }
  const removeMenuOption = (option) => {
    let localMenuOptions = getLocalItem("menuOptions", true)
    if (!!localMenuOptions) {
      if (option !== "products") {
        const removeIndex = localMenuOptions.findIndex(({ id }) => id === option)
        localMenuOptions.splice(removeIndex, 1)
      } else {
        localMenuOptions = localMenuOptions.filter(({ id }) => ["highlights", "combos", "beverages"].includes(id))
      }

      setMenuOptions(localMenuOptions)
      menuOptionsRef.current = localMenuOptions
      setLocalItem("menuOptions", localMenuOptions, true)
    }
  }

  const addMenuOption = (content) => {
    let newMenuOptions
    // Consultando dados do armazenamento local
    const localMenuOptions = getLocalItem("menuOptions", true)

    if (!!localMenuOptions && localMenuOptions.length > 0) {
      const indexMenu = localMenuOptions.findIndex(({ id }) => id === content.id)

      if (indexMenu !== -1) {
        localMenuOptions.splice(indexMenu, 1)
      }

      newMenuOptions = [...localMenuOptions, content]
    } else {
      const indexMenu = menuOptions.findIndex(({ id }) => id === content.id)

      if (indexMenu !== -1) {
        menuOptions.splice(indexMenu, 1)
      }

      newMenuOptions = [...menuOptions, content]
    }
    // Ordenando por posições definidas
    newMenuOptions.sort((a, b) => a.position - b.position)

    setMenuOptions(newMenuOptions)
    menuOptionsRef.current = newMenuOptions
    setLocalItem("menuOptions", newMenuOptions, true)
  }

  const updateMenu = (event, id) => {
    function verifyAndMoveToTargetElement(id) {
      var element = document.getElementById(id)
      var headerOffset = 45
      var elementPosition = element.getBoundingClientRect().top

      var offsetPosition = elementPosition + document.getElementById("section-home").scrollTop - headerOffset

      document.getElementById("section-home").scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }

    const newMenuOptions = menuOptionsRef.current.map((menu) => {
      return { ...menu, focus: false }
    })

    const indexMenu = newMenuOptions.findIndex((menu) => menu.id === id)

    if (indexMenu !== -1) {
      newMenuOptions[indexMenu] = {
        ...newMenuOptions[indexMenu],
        focus: true,
      }
    }

    menuOptionsRef.current = newMenuOptions

    if (!!document.getElementById(id)) {
      verifyAndMoveToTargetElement(id)

      // document.getElementById(id).scrollIntoView({ top: 20, behavior: "smooth", block: "start", inline: "start" })
    } else {
      window.scrollTo(0, 0)
    }

    setMenuOptions(menuOptionsRef.current)
  }

  //Buscar dados do sistema
  const getContent = (company, startLoad = false, refreshOrder = false, companies = []) => {
    let focusMenuOption = true
    setCompany(company)
    companyRef.current = company
    setLocalItem("company", company, true)

    const storageCompanies = getLocalItem("companies", true)
    setCompanies(storageCompanies)
    let orderItems = getLocalItem("orderItems", true) || []
    if (refreshOrder) {
      setOrder({
        customer: null,
        combos: [],
        products: [],
        beverages: [],
        payments: [],
        benefits: [],
        total: 0.0,
        subTotal: 0.0,
        comments: "",
        discount: "0,00",
        discountType: "R$",
        deliveryFee: 0,
        deliveryComments: "",
      })
      removeSessionItem("order")
    } else {
      // Verificando se existe pedido na sessão --------------------------------------------------------------
      const sessionOrder = getSessionItem("order", true)
      if (!!sessionOrder) {
        if (!!sessionOrder.combos && sessionOrder.combos.length > 0) {
          const comboNroList = sessionOrder.combos.map(({ nro_combo }) => nro_combo)
          const maxComboNro = Math.max(...comboNroList)
          setCurrentOrderCombo(maxComboNro)
        }
        setOrder({ ...sessionOrder })
      }
    }

    console.info("CONSULTANDO DADOS DE CONFIGURAÇÕES DO ESTABELECIMENTO...")
    // Obtendo dados das configurações armazenados no storage ------------------------------------------------------
    const settingsStorage = getLocalItem("settings", true)

    if (!!settingsStorage) {
      setLoading(false)
      setSettings(settingsStorage)

      console.info("CONSULTANDO DADOS DE ENTREGA...")
      getDeliveryFees(settingsStorage).then(() => {
        const storageUserSelectedAddress = getLocalItem("userSelectedAddress", true)
        if (
          !!storageUserSelectedAddress &&
          modality !== 2 &&
          modality !== 3 &&
          !!companyRef.current?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 1)
        ) {
          onChangeUserSelectedAddress(storageUserSelectedAddress, true, settingsStorage)

          setModality(1)
        }
      })

      if (formatBoolean(settingsStorage.enable_delivery_areas)) {
        getDeliveryAreas().then((response) => {
          deliveryAreasRef.current = response
        })
      }
      // Verificando se é preciso exibir as modais de configuração inicial
      if (startLoad) {
        const userAddress = getLocalItem("userSelectedAddress", true)
        if (formatBoolean(settingsStorage.site_get_address_on_early_access)) {
          //Verifica se já existe um endereço no storage
          if (!userAddress) {
            setModalInitialAddress({ status: true, content: { source: "mainOptions" } })
          } else {
            //Verifica se está configurado para mostrar a tela de escolha das lojas
            if (formatBoolean(settingsStorage.site_display_company_choose) && companies.length > 1) {
              // if (ModalEarlyAddresses.status === false) {
              setModalCompanies({ status: true, content: storageCompanies })
              // }
              // setModalCompanies({ status: true, content: companies })
            }
          }
        } else if (formatBoolean(settingsStorage.site_display_company_choose) && storageCompanies.length > 1) {
          // if (ModalEarlyAddresses.status === false) {
          setModalCompanies({ status: true, content: storageCompanies })
          // }
          // setModalCompanies({ status: true, content: companies })
        }
      }

      if (!!settingsStorage.site_show_order) {
        orderItems = JSON.parse(settingsStorage.site_show_order)
        // setOrderMenuItems(orderItems)
        setLocalItem("orderItems", orderItems, true)
      }

      onChangeFavicon(getUrlAWSManagerFile(settingsStorage.site_favicon))
      onChangeTitlePage(settingsStorage.site_title || company.nm_company || "Estabelecimento")
    } else {
      setLoading(true)
    }

    // Obtendo dados das configurações -----------------------------------------------------------------------------
    SettingController.fetchAll()
      .then((responseSettings) => {
        if (responseSettings.status === 200) {
          const settings = responseSettings.data
          // Verificando se é preciso exibiir as modais de configuração inicial
          if (startLoad) {
            const companies = getLocalItem("companies", true)
            const userAddress = getLocalItem("userSelectedAddress", true)

            if (formatBoolean(settings.site_get_address_on_early_access)) {
              //Verifica se já existe um endereço no storage
              if (!userAddress) {
                setModalInitialAddress({ status: true, content: { source: "mainOptions" } })
              } else {
                //Verifica se está configurado para mostrar a tela de escolha das lojas
                if (formatBoolean(settings.site_display_company_choose) && companies.length > 1) {
                  if (modalCompanies.status === false) {
                    setModalCompanies({ status: true, content: companies })
                  }
                  // setModalCompanies({ status: true, content: companies })
                }
              }
            } else if (formatBoolean(settings.site_display_company_choose) && companies.length > 1) {
              if (modalCompanies.status === false || userAddress === null) {
                setModalCompanies({ status: true, content: companies })
              }
              // setModalCompanies({ status: true, content: companies })
            }
          }

          onChangeFavicon(getUrlAWSManagerFile(settings.site_favicon))
          onChangeTitlePage(settings.site_title || company.nm_company || "Restaurante")

          if (!!settings.site_show_order) {
            orderItems = JSON.parse(settings.site_show_order)
            // setOrderMenuItems(orderItems)
            setLocalItem("orderItems", orderItems, true)
          }
          setSettings(settings)
          console.info("CONSULTANDO DADOS DE ENTREGA...")
          getDeliveryFees(settings).then(() => {
            const storageUserSelectedAddress = getLocalItem("userSelectedAddress", true)
            if (
              !!storageUserSelectedAddress &&
              modality !== 2 &&
              modality !== 3 &&
              !!companyRef.current?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 1)
            ) {
              onChangeUserSelectedAddress(storageUserSelectedAddress, true, settings)
              setModality(1)
            }
          })

          // Obtendo informações do pagamento online
          if (formatBoolean(settings.enable_online_payments)) {
            getMercadoPagoPublicKey()
          }
          setLocalItem("settings", settings, true)
        }
      })
      .finally(() => {
        setLoading(false)
      })

    console.info("CONSULTANDO DADOS DO ESTABELECIMENTO...")
    // Obtendo dados do estabelecimento ----------------------------------------------------------------------------
    CompanyController.fetchOne().then((responseCompany) => {
      if (responseCompany.status === 200) {
        company = { ...company, ...responseCompany.data }

        //Recebe todas as formas de pagamento que estiverem habilitadas para a companhia e filtra as ativas pro site.
        const enabledPayments = responseCompany.data.company_payment_methods
          .filter((method) => method.st_website)
          .map((method) => {
            return {
              ds_key_payment_method: method.ds_key_payment_method,
              ds_key_type_payment_method: method.ds_key_type_payment_method,
              ds_obs_payment_method: method.ds_obs_payment_method,
              ...method.payment_method,
            }
          })

        company = { ...company, company_payment_methods: enabledPayments }
        // Pega todas as categorias das formas de pagamento habilitadas.
        const categoriesList = !!enabledPayments ? enabledPayments.map((method) => method.category_association.fk_id_category_method) : []

        // Buscando o período de trabalho do estabelecimento
        CompanyController.fetchWorkingHours()
          .then((responseWorkingHours) => {
            if (responseWorkingHours.status === 200) {
              company = { ...company, company_working_hours: responseWorkingHours.data }
              setCompany(company)
              companyRef.current = company
              setLocalItem("company", company, true)
            }
          })
          .catch((error) => {
            company = { ...company, company_working_hours: null }
            setCompany(company)
            companyRef.current = company
            setLocalItem("company", company, true)
            console.error("Erro ao consultar o período de trabalho do estabelecimento.", error)
          })

        SettingController.fetchAllCategoryPaymentsMethods()
          .then((responseCategoryPayments) => {
            if (responseCategoryPayments.status === 200) {
              let filteredCategories = responseCategoryPayments.data.filter(({ id_category_payment_methods }) =>
                categoriesList.includes(id_category_payment_methods)
              )

              company = { ...company, company_payment_categories: filteredCategories }

              setCompany(company)
              companyRef.current = company
              setLocalItem("company", company, true)
            }
          })
          .catch((error) => {
            company = { ...company, company_payment_categories: [] }
            setCompany(company)
            companyRef.current = company
            setLocalItem("company", company, true)
            console.error("Erro ao consultar as categorias de métodos de pagamento", error)
          })

        if (!companyRef.current?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 1)) {
          setUserSelectedAddress(null)
          setLocalItem("userSelectedAddress", null, true)
          userSelectedAddressRef.current = null
          setModality(0)
        }
      }
    })

    // Obtendo dados do cliente ----------------------------------------------------------------------------
    let localUser = getLocalUser()
    if (!!localUser) {
      setUser(localUser)
      userRef.current = localUser

      let user = localUser
      //Caso já tenha usuário logado, busca novamente os dados dele para atualizar quaisquer informações que possam
      //ter sido alteradas como pontos de fideldiade e endereços e no futuro cupons
      CustomerController.fetchOne(localUser.uuid_customer, { withOrders: true, withStreets: true, withMovements: true, withFidelities: true })
        .then(async (responseCustomer) => {
          if (responseCustomer.status === 200) {
            const addresses = responseCustomer.data?.streets || []
            const customer_orders = responseCustomer.data?.customer_orders
            const fidelities = responseCustomer.data?.customer_fidelities_points || []
            const movements_account = responseCustomer.data?.movements_account || []

            if (addresses.length > 0) {
              const defaultAddress = addresses.find(({ customer_address: { st_primary } }) => st_primary) || addresses[0]

              if (
                !userSelectedAddress?.nm_street &&
                modality !== 2 &&
                modality !== 3 &&
                !!companyRef.current?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 1)
              ) {
                setModality(1)
                setLocalItem("modality", 1)
                onChangeUserSelectedAddress(defaultAddress).then()
              }
            }

            setUser({ ...user, customer_orders, addresses, fidelities, movements_account })
            userRef.current = { ...user, customer_orders, addresses, fidelities, movements_account }
            setLocalItem("user", { ...user, customer_orders, addresses, fidelities, movements_account }, true)
          }
        })
        .catch((error) => {
          console.error("Falha ao atualizar dados do cliente", error)
          setUser(user)
          userRef.current = user
        })
    }

    // Obtendo dados de cupons ----------------------------------------------------------------------------------
    BenefitController.getAll().then((responseFetchBenefits) => {
      if (responseFetchBenefits.status === 200) {
        setBenefits(responseFetchBenefits.data)
      }
    })

    // Obtendo dados de programas de fidelidade -----------------------------------------------------------------------
    FidelityController.fetchAll().then((responseFetchFidelities) => {
      if (responseFetchFidelities.status === 200) {
        setFidelities(responseFetchFidelities.data)
      }
    })

    // Obtendo dados de entregadores ----------------------------------------------------------------------------
    UserController.fetchDelivery().then((responseDeliveryMan) => {
      if (responseDeliveryMan.status) {
        const deliveryManEnabled = (responseDeliveryMan?.data?.rows || [])
          .filter(({ st_active }) => st_active)
          .map(({ id_user, nm_user }) => {
            return { id_user, nm_user }
          })
        setDeliveryMans(deliveryManEnabled)
      }
    })

    // Obtendo dados dos popups do site ----------------------------------------------------------------------------
    console.info("CONSULTANDO DADOS DE MENSAGENS...")
    PopupController.fetchAll().then((responsePopups) => {
      setLocalItem("popups", responsePopups, true)
    })

    // Obtendo dados dos combos ----------------------------------------------------------------------------
    console.info("CONSULTANDO DADOS DE COMBOS...")
    // Obtendo dados dos combos armazenados no storage
    const combosSession = getLocalItem("combos", true)
    if (!!combosSession) {
      addMenuOption({
        companyId: company.id_company,
        id: "combos",
        label: "Combos",
        focus: focusMenuOption,
        position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "combos").nro_priority : 1,
        visible: combosSession.length > 0,
      })
      focusMenuOption = false
      setCombos(combosSession)
    }
    // Obtendo dados dos combos
    const currentDate = new moment()
    ComboController.fetchAll({ stWebsite: true }).then((responseCombos) => {
      if (responseCombos.status === 200 && responseCombos.data.total > 0) {
        const combosFiltered = (responseCombos?.data?.rows || [])
          .filter(({ combo_periods = [] }) => checkBetweenIntervalPeriod(currentDate, combo_periods, "combos_periods_times"))
          .sort((a, b) => a.nro_priority - b.nro_priority)

        if (!combosSession) {
          addMenuOption({
            companyId: company.id_company,
            id: "combos",
            label: "Combos",
            focus: focusMenuOption,
            position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "combos").nro_priority : 1,
            visible: combosFiltered.length > 0,
          })
          focusMenuOption = false
        }

        setCombos(combosFiltered)
        setLocalItem("combos", combosFiltered, true)
      } else {
        setCombos([])
        setLocalItem("combos", [], true)
        removeMenuOption("combos")
      }
    })

    // Obtendo dados dos produtos ----------------------------------------------------------------------------------
    console.info("CONSULTANDO DADOS DE PRODUTOS...")
    // Obtendo dados dos produtos armazenados no storage
    const productsSession = getLocalItem("products", true)
    if (!!productsSession) {
      for (const product of productsSession) {
        if (product.flavours.length > 0) {
          addMenuOption({
            companyId: company.id_company,
            id: product.uuid_product,
            label: getProductName(product),
            focus: focusMenuOption,
            position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "produtos").nro_priority : 2,
            visible: true,
          })

          focusMenuOption = false
        }
      }

      setProducts(productsSession)
    }
    //Tenta consultar os produtos no redis, e caso não exista, realiza a consulta no banco.
    ProductController.fetchAllMemory()
      .then((productsResponseMemory) => {
        if (productsResponseMemory.status === 200) {
          let newProducts = []
          let menuOptionsItems = []
          const localMenuOptions = getLocalItem("menuOptions", true)
          if (!!localMenuOptions) {
            menuOptionsItems = [...localMenuOptions]
          }
          const fetcheProducts = productsResponseMemory.data.rows.filter(
            ({ company_association: { products_periods, st_period_control, st_website } }) =>
              st_website &&
              (st_period_control
                ? !!products_periods && products_periods.length === 7
                  ? !!checkBetweenIntervalPeriod(currentDate, products_periods, "products_periods_times")
                  : true
                : true)
          )
          // tratativa de cada item do estabelecimento
          for (let product of fetcheProducts) {
            let continueLoading = true

            const { id_product, uuid_product, nm_product, company_association } = product
            product = { id_product, uuid_product, nm_product, ...company_association }

            ProductController.fetchSizes(product.uuid_product).then((sizeResponse) => {
              if (sizeResponse.status === 200) {
                let sizes = []
                let product = sizeResponse.data
                const fetchedProductSizes = product.sizes.filter(
                  ({ product_size: { st_website, st_hide_in_builder } }) => st_website && !st_hide_in_builder
                )
                if (fetchedProductSizes.length > 0) {
                  // Obtendo os tamanhos do produto
                  for (const size of fetchedProductSizes) {
                    const { id_size, uuid_size, nm_size, product_size, beverages = [] } = size

                    const beveragesSize = beverages.filter(({ company_association: { st_website } }) => st_website)

                    sizes.push({
                      id_size,
                      uuid_size,
                      nm_size,
                      ...product_size,
                      beverages: beveragesSize.map(({ id_beverage, uuid_beverage, nm_beverage, beverage_size, company_association = {} }) => ({
                        ...company_association,
                        id_beverage,
                        uuid_beverage,
                        nm_beverage,
                        val_price: beverage_size.val_price || 0,
                      })),
                    })
                  }

                  // Atualizando o objeto de produto
                  sizes.sort((a, b) => a.nro_priority - b.nro_priority)

                  // Verificando de qual o index do produto em questão que teve retorno.
                  const productIndex = newProducts.findIndex(({ uuid_product }) => uuid_product === product.uuid_product)

                  // Adiciona os dados do tamanho aquele produto.
                  if (productIndex !== -1) {
                    newProducts[productIndex] = { ...newProducts[productIndex], sizes }
                    menuOptionsItems.push({
                      companyId: company.id_company,
                      id: newProducts[productIndex].uuid_product,
                      label: getProductName(newProducts[productIndex]),
                      focus: focusMenuOption,
                      position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "produtos").nro_priority : 2,
                      visible: true,
                    })
                  }
                  const newSortedProducts = newProducts.filter(({ st_website }) => st_website).sort((a, b) => a.nro_priority - b.nro_priority)

                  menuOptionsItems.sort((a, b) => a.nro_priority - b.nro_priority)

                  for (const newSortedProduct of newSortedProducts) {
                    menuOptionsItems.forEach((menuItem) => {
                      if (menuItem.id === newSortedProduct.uuid_product) {
                        addMenuOption(menuItem)
                      }
                    })
                  }

                  setProducts(newSortedProducts)

                  const productSizesStorage = getLocalItem("productSizes", true) || {}

                  setLocalItem("productSizes", { ...productSizesStorage, [product.uuid_product]: sizes }, true)
                  setLocalItem("products", newSortedProducts, true)
                } else {
                  continueLoading = false
                }
              }

              if (continueLoading) {
                if (product.st_display_mode === "flavour") {
                  ProductController.fetchFlavours({
                    uuid_product: product.uuid_product,
                    withSizes: true,
                    withIngredients: true,
                    withOptionals: true,
                  }).then((flavourResponse) => {
                    if (flavourResponse.status === 200) {
                      let flavours = []
                      let product = flavourResponse.data

                      // Identifica de qual produto é o retorno do sabor.
                      const productIndex = newProducts.findIndex(({ uuid_product }) => uuid_product === product.uuid_product)

                      if (flavourResponse.status === 200) {
                        // Obtendo os sabores do produto e adicionando ao flavours.
                        const fetchedFlavours = product.flavours.filter(({ product_flavour: { st_website }, sizes }) => {
                          const sizesEnabled = sizes.filter(({ flavour_size: { st_website } }) => st_website)

                          return st_website && sizesEnabled.length > 0
                        })

                        for (const flavour of fetchedFlavours) {
                          const { id_flavour, uuid_flavour, nm_flavour, product_flavour, sizes } = flavour

                          const sizesEnabled = sizes.filter(({ flavour_size: { st_website } }) => st_website)

                          flavours.push({
                            id_flavour,
                            uuid_flavour,
                            nm_flavour,
                            ...product_flavour,
                            sizes: sizesEnabled,
                          })
                        }
                        // Atualizando o objeto de sabores para os que estão habilitados no site
                        flavours.sort((a, b) => a.nro_priority - b.nro_priority)
                      } else if (flavourResponse.status === 204) {
                        // Se o retorno é 204, significa que não possui sabores cadastrados, e é para remover o produto para não mostrar no site.
                        if (productIndex !== -1) {
                          newProducts.splice(productIndex, 1)
                        }
                      }

                      if (productIndex !== -1) {
                        newProducts[productIndex] = { ...newProducts[productIndex], flavours }
                        if (flavours.length > 0) {
                          menuOptionsItems.push({
                            companyId: company.id_company,
                            id: newProducts[productIndex].uuid_product,
                            label: getProductName(newProducts[productIndex]),
                            focus: focusMenuOption,
                            position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "produtos").nro_priority : 2,
                            visible: true,
                          })
                        }
                      }

                      // Refatorando menu de acordo com produtos disponíveis
                      // Filtrando apenas produtos com sabores
                      const newSortedProducts = newProducts
                        .filter(({ st_display_mode, flavours }) => st_display_mode === "size" || flavours.length > 0)
                        .sort((a, b) => a.nro_priority - b.nro_priority)

                      menuOptionsItems.sort((a, b) => a.nro_priority - b.nro_priority)

                      for (const newSortedProduct of newSortedProducts) {
                        menuOptionsItems.forEach((menuItem) => {
                          if (menuItem.id === newSortedProduct.uuid_product) {
                            addMenuOption(menuItem)
                          }
                        })
                      }

                      // salvando os estados
                      setProducts(newSortedProducts)
                      // salvando os localStorage
                      setLocalItem("products", newSortedProducts, true)
                      const productFlavoursStorage = getLocalItem("productFlavours", true) || {}
                      setLocalItem(
                        "productFlavours",
                        {
                          ...productFlavoursStorage,
                          [product.uuid_product]: flavours,
                        },
                        true
                      )
                    }
                  })
                }
              }
            })

            const productSizesStorage = getLocalItem("productSizes", true) || {}
            const productFlavoursStorage = getLocalItem("productFlavours", true) || {}

            newProducts.push({
              ...product,
              sizes: productSizesStorage[product.uuid_product] || [],
              flavours: productFlavoursStorage[product.uuid_product] || [],
            })
          }

          // Alterando o estado, populando os produtos do cliente
          setProducts(newProducts)
          setLocalItem("products", newProducts, true)
        } else {
          ProductController.fetchAll()
            .then((productsResponse) => {
              if (productsResponse.status === 200) {
                let newProducts = []
                let menuOptionsItems = []
                const localMenuOptions = getLocalItem("menuOptions", true)
                if (!!localMenuOptions) {
                  menuOptionsItems = [...localMenuOptions]
                }
                const fetcheProducts = productsResponse.data.rows.filter(
                  ({ company_association: { products_periods, st_period_control, st_website } }) =>
                    st_website &&
                    (st_period_control
                      ? !!products_periods && products_periods.length === 7
                        ? !!checkBetweenIntervalPeriod(currentDate, products_periods, "products_periods_times")
                        : true
                      : true)
                )
                // tratativa de cada item do estabelecimento
                for (let product of fetcheProducts) {
                  let continueLoading = true

                  const { id_product, uuid_product, nm_product, company_association } = product
                  product = { id_product, uuid_product, nm_product, ...company_association }

                  ProductController.fetchSizes(product.uuid_product).then((sizeResponse) => {
                    if (sizeResponse.status === 200) {
                      let sizes = []
                      let product = sizeResponse.data
                      const fetchedProductSizes = product.sizes.filter(({ product_size: { st_website } }) => st_website)

                      if (fetchedProductSizes.length > 0) {
                        // Obtendo os tamanhos do produto
                        for (const size of fetchedProductSizes) {
                          const { id_size, uuid_size, nm_size, product_size, beverages = [] } = size

                          const beveragesSize = beverages.filter(({ company_association: { st_website } }) => st_website)

                          sizes.push({
                            id_size,
                            uuid_size,
                            nm_size,
                            ...product_size,
                            beverages: beveragesSize.map(({ id_beverage, uuid_beverage, nm_beverage, beverage_size, company_association = {} }) => ({
                              ...company_association,
                              id_beverage,
                              uuid_beverage,
                              nm_beverage,
                              val_price: beverage_size.val_price || 0,
                            })),
                          })
                        }

                        // Atualizando o objeto de produto
                        sizes.sort((a, b) => a.nro_priority - b.nro_priority)

                        // Verificando de qual o index do produto em questão que teve retorno.
                        const productIndex = newProducts.findIndex(({ uuid_product }) => uuid_product === product.uuid_product)

                        // Adiciona os dados do tamanho aquele produto.
                        if (productIndex !== -1) {
                          newProducts[productIndex] = { ...newProducts[productIndex], sizes }
                          menuOptionsItems.push({
                            companyId: company.id_company,
                            id: newProducts[productIndex].uuid_product,
                            label: getProductName(newProducts[productIndex]),
                            focus: focusMenuOption,
                            position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "produtos").nro_priority : 2,
                            visible: true,
                          })
                        }
                        const newSortedProducts = newProducts.filter(({ st_website }) => st_website).sort((a, b) => a.nro_priority - b.nro_priority)

                        menuOptionsItems.sort((a, b) => a.nro_priority - b.nro_priority)

                        for (const newSortedProduct of newSortedProducts) {
                          menuOptionsItems.forEach((menuItem) => {
                            if (menuItem.id === newSortedProduct.uuid_product) {
                              addMenuOption(menuItem)
                            }
                          })
                        }

                        setProducts(newSortedProducts)

                        const productSizesStorage = getLocalItem("productSizes", true) || {}

                        setLocalItem("productSizes", { ...productSizesStorage, [product.uuid_product]: sizes }, true)
                        setLocalItem("products", newSortedProducts, true)
                      } else {
                        continueLoading = false
                      }
                    }

                    if (continueLoading) {
                      if (product.st_display_mode === "flavour") {
                        ProductController.fetchFlavours({
                          uuid_product: product.uuid_product,
                          withSizes: true,
                          withIngredients: true,
                          withOptionals: true,
                        }).then((flavourResponse) => {
                          if (flavourResponse.status === 200) {
                            let flavours = []
                            let product = flavourResponse.data

                            // Identifica de qual produto é o retorno do sabor.
                            const productIndex = newProducts.findIndex(({ uuid_product }) => uuid_product === product.uuid_product)

                            if (flavourResponse.status === 200) {
                              // Obtendo os sabores do produto e adicionando ao flavours.
                              const fetchedFlavours = product.flavours.filter(({ product_flavour: { st_website }, sizes }) => {
                                const sizesEnabled = sizes.filter(({ flavour_size: { st_website } }) => st_website)
                                return st_website && sizesEnabled.length > 0
                              })

                              for (const flavour of fetchedFlavours) {
                                const { id_flavour, uuid_flavour, nm_flavour, product_flavour } = flavour
                                flavours.push({
                                  id_flavour,
                                  uuid_flavour,
                                  nm_flavour,
                                  ...product_flavour,
                                })
                              }
                              // Atualizando o objeto de sabores para os que estão habilitados no site
                              flavours.sort((a, b) => a.nro_priority - b.nro_priority)
                            } else if (flavourResponse.status === 204) {
                              // Se o retorno é 204, significa que não possui sabores cadastrados, e é para remover o produto para não mostrar no site.
                              if (productIndex !== -1) {
                                newProducts.splice(productIndex, 1)
                              }
                            }

                            if (productIndex !== -1) {
                              newProducts[productIndex] = { ...newProducts[productIndex], flavours }
                              if (flavours.length > 0) {
                                menuOptionsItems.push({
                                  companyId: company.id_company,
                                  id: newProducts[productIndex].uuid_product,
                                  label: getProductName(newProducts[productIndex]),
                                  focus: focusMenuOption,
                                  position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "produtos").nro_priority : 2,
                                  visible: true,
                                })
                              }
                            }

                            // Refatorando menu de acordo com produtos disponíveis
                            // Filtrando apenas produtos com sabores
                            const newSortedProducts = newProducts
                              .filter(({ st_display_mode, flavours }) => st_display_mode === "size" || flavours.length > 0)
                              .sort((a, b) => a.nro_priority - b.nro_priority)

                            menuOptionsItems.sort((a, b) => a.nro_priority - b.nro_priority)

                            for (const newSortedProduct of newSortedProducts) {
                              menuOptionsItems.forEach((menuItem) => {
                                if (menuItem.id === newSortedProduct.uuid_product) {
                                  addMenuOption(menuItem)
                                }
                              })
                            }

                            // salvando os estados
                            setProducts(newSortedProducts)
                            // salvando os localStorage
                            setLocalItem("products", newSortedProducts, true)
                            const productFlavoursStorage = getLocalItem("productFlavours", true) || {}
                            setLocalItem(
                              "productFlavours",
                              {
                                ...productFlavoursStorage,
                                [product.uuid_product]: flavours,
                              },
                              true
                            )
                          }
                        })
                      }
                    }
                  })

                  const productSizesStorage = getLocalItem("productSizes", true) || {}
                  const productFlavoursStorage = getLocalItem("productFlavours", true) || {}

                  newProducts.push({
                    ...product,
                    sizes: productSizesStorage[product.uuid_product] || [],
                    flavours: productFlavoursStorage[product.uuid_product] || [],
                  })
                }

                // Alterando o estado, populando os produtos do cliente
                setProducts(newProducts)
                setLocalItem("products", newProducts, true)
              }
            })
            .catch((error) => {
              console.error("Falha ao consultar produtos no banco", error)
            })
        }
      })
      .catch((error) => {
        console.error("Falha ao consultar produtos na memória", error)
      })
    removeMenuOption("products")

    // Obtendo dados das bebidas -----------------------------------------------------------------------------------
    console.info("CONSULTANDO DADOS DE BEBIDAS...")
    // Obtendo dados das bebidas amazenado no storage
    const beveragesSession = getLocalItem("beverages", true)
    if (!!beveragesSession) {
      addMenuOption({
        companyId: company.id_company,
        id: "beverages",
        label: "Bebidas",
        focus: focusMenuOption,
        position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "bebidas").nro_priority : 3,
        visible: beveragesSession.length > 0,
      })
      focusMenuOption = false
      setBeverages([...beveragesSession])
    }
    //Tenta consultar as bebidas no redis, e caso não exista, realiza a consulta no banco.
    BeverageController.fetchAllMemory().then((responseBeverages) => {
      if (responseBeverages.status === 200 && responseBeverages.data.total > 0) {
        const { rows: beverages } = responseBeverages.data
        addMenuOption({
          companyId: company.id_company,
          id: "beverages",
          label: "Bebidas",
          focus: focusMenuOption,
          position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "bebidas").nro_priority : 3,
          visible: beverages.length > 0,
        })
        focusMenuOption = false

        // Verificando se existe a opção sem bebida
        const beveragesFiltered = beverages
          .filter(
            ({ nm_beverage, company_association }) =>
              `${nm_beverage}`.toLowerCase().indexOf("sem b") === -1 &&
              `${nm_beverage}`.toLowerCase().indexOf("sem r") === -1 &&
              company_association.st_website &&
              (!!company_association.beverages_periods && company_association.beverages_periods.length === 7
                ? !!checkBetweenIntervalPeriod(currentDate, company_association.beverages_periods, "beverages_periods_times")
                : true)
          )
          .map(({ id_beverage, uuid_beverage, nm_beverage, ref_beverage, category, company_association }) => {
            return {
              id_beverage,
              uuid_beverage,
              nm_beverage,
              ref_beverage,
              category,
              ...company_association,
            }
          })
          .sort((a, b) => a.nro_priority - b.nro_priority)
        setBeverages(beveragesFiltered)
        setLocalItem("beverages", beveragesFiltered, true)
      } else {
        BeverageController.fetchAll().then((responseBeverages) => {
          if (responseBeverages.status === 200 && responseBeverages.data.total > 0) {
            const { rows: beverages } = responseBeverages.data
            addMenuOption({
              companyId: company.id_company,
              id: "beverages",
              label: "Bebidas",
              focus: focusMenuOption,
              position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "bebidas").nro_priority : 3,
              visible: beverages.length > 0,
            })
            focusMenuOption = false

            // Verificando se existe a opção sem bebida
            const beveragesFiltered = beverages
              .filter(
                ({ nm_beverage, company_association }) =>
                  `${nm_beverage}`.toLowerCase().indexOf("sem ") === -1 &&
                  company_association.st_website &&
                  (!!company_association.beverages_periods && company_association.beverages_periods.length === 7
                    ? !!checkBetweenIntervalPeriod(currentDate, company_association.beverages_periods, "beverages_periods_times")
                    : true)
              )
              .map(({ id_beverage, uuid_beverage, nm_beverage, ref_beverage, category, company_association }) => {
                return {
                  id_beverage,
                  uuid_beverage,
                  nm_beverage,
                  ref_beverage,
                  category,
                  ...company_association,
                }
              })
              .sort((a, b) => a.nro_priority - b.nro_priority)
            setBeverages(beveragesFiltered)
            setLocalItem("beverages", beveragesFiltered, true)
          } else {
            setBeverages([])
            setLocalItem("beverages", [], true)
            removeMenuOption("beverages")
          }
        })
      }
    })

    // Obtendo dados dos destaques ---------------------------------------------------------------------------------
    console.info("CONSULTANDO DADOS DE DESTAQUES...")
    // Obtendo dados dos destaques armazenados no storage
    const highlightsSession = getLocalItem("highlights", true)
    if (!!highlightsSession) {
      addMenuOption({
        companyId: company.id_company,
        id: "highlights",
        label: "Destaques",
        focus: focusMenuOption,
        position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "destaques").nro_priority : 0,
        visible: highlightsSession.length > 0,
      })
      focusMenuOption = false
      setHighlights(highlightsSession)
    }
    HighlightsController.fetchAll().then((highlightResponse) => {
      if (highlightResponse.status === 200 && highlightResponse.data.total > 0) {
        if (!highlightsSession) {
          addMenuOption({
            companyId: company.id_company,
            id: "highlights",
            label: "Destaques",
            focus: focusMenuOption,
            position: orderItems.length > 0 ? orderItems.find(({ name }) => name === "destaques").nro_priority : 0,
            visible: highlightResponse.data.total > 0,
          })
          focusMenuOption = false
        }

        setHighlights(highlightResponse.data.rows)
        setLocalItem("highlights", highlightResponse.data.rows, true)
      } else {
        setHighlights([])
        setLocalItem("highlights", [], true)
        removeMenuOption("destaques")
      }
    })

    // Obtendo dados dos horarios para agendamento ----------------------------------------------------------
    console.info("CONSULTANDO DADOS DE AGENDAMENTO...")
    // Obtendo dados das bebidas amazenado no storage
    const schedulesSession = getLocalItem("schedules", true)
    if (!!schedulesSession) {
      setSchedules([...schedulesSession])
    }
    ScheduleController.fetchAll().then((responseSchedules) => {
      if (responseSchedules.status === 200) {
        setSchedules(responseSchedules.data)
        setLocalItem("schedules", responseSchedules.data, true)
      }
    })
  }

  //Atualizar dados do endereço do cliente
  const onChangeUserSelectedAddress = async (address = null, finishOrder = false, currentSettings) => {
    let newAddress = { ...address }
    const newSettings = !!currentSettings ? currentSettings : settings
    if (!!newSettings || (Array.isArray(newSettings) && newSettings.length > 0)) {
      try {
        let deliveryFee = 0
        let deliveryTime = 0

        const responseDeliveryCalculate = await DeliveryFeeController.calculate(
          address.customer_address.geo_coordinates[0],
          address.customer_address.geo_coordinates[1],
          address.neighbourhood.id_neighbourhood,
          1,
          address?.customer_address?.id_customer_address
        )

        switch (responseDeliveryCalculate.status) {
          case 200:
            deliveryFee = parseFloat(responseDeliveryCalculate.data.val_fee.toFixed(2))
            deliveryTime = responseDeliveryCalculate.data.num_delivery_time

            if (!!order.payments) {
              const { discountType, discount } = getDiscountType(1, false)
              const discountOrder = getDiscountOrder({ ...order, discountType, discount }, order.subTotal, deliveryFee)
              const orderPayment = order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() !== "cashback")
              const cashBackPayment = order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")

              if (!!orderPayment) {
                if (
                  orderPayment.val_payment !== order.subTotal + deliveryFee - discountOrder - (!!cashBackPayment ? cashBackPayment.val_payment : 0) ||
                  orderPayment.id_payment_method === 1
                ) {
                  if (
                    orderPayment.id_payment_method !== 1 ||
                    (orderPayment.id_payment_method === 1 &&
                      (orderPayment.val_receivable >
                        order.subTotal + deliveryFee - discountOrder - (!!cashBackPayment ? cashBackPayment.val_payment : 0) ||
                        orderPayment.val_receivable === 0))
                  ) {
                    let payments = [
                      {
                        ...orderPayment,
                        val_payment: order.subTotal + deliveryFee - discountOrder - (!!cashBackPayment ? cashBackPayment.val_payment : 0),
                      },
                    ]
                    if (!!cashBackPayment) {
                      payments.push(cashBackPayment)
                    }

                    setOrder({
                      ...order,
                      discountType,
                      discount,
                      payments,
                    })
                  } else {
                    setOrder({ ...order, discountType, discount, payments: [] })
                    setSelectedPaymentMethod({
                      id_payment_method: -1,
                    })
                    setShowPaymentTypeSelector(true)
                    onMessageDialog()
                      .fire({
                        title: "Devido a alteração no valor do pedido, informe o novo valor de troco!",
                        icon: "info",
                        timer: 3000,
                        showConfirmButton: false,
                        showCancelButton: false,
                      })
                      .then()
                  }
                }
              }
            }

            setUserSelectedAddress({ ...address, deliveryTime, deliveryFee })
            setLocalItem("userSelectedAddress", { ...address, deliveryTime, deliveryFee }, true)
            userSelectedAddressRef.current = { ...address, deliveryTime, deliveryFee }
            newAddress = { ...address, deliveryTime, deliveryFee }
            return newAddress
          case 204:
            onMessageDialog("error")
              .fire({
                title: "Infelizmente não entregamos no seu endereço!",
                icon: "warning",
                timer: 3000,
                showConfirmButton: false,
                showCancelButton: false,
              })
              .then()
            return false
          default:
            onMessageDialog("error")
              .fire({
                title: "Ocorreu um erro ao consultar a taxa de entrega, entre em contato com o estabelecimento!",
                icon: "warning",
                timer: 3000,
                showConfirmButton: false,
                showCancelButton: false,
              })
              .then()
            return false
        }
        // })
      } catch (error) {
        console.info(error)
        return false
      }
    }
  }

  const getDeliveryFees = (settings, companyUid = null) => {
    // Pegar nas configurações

    // Obtendo dados das taxas e tempo de entrega e detalhes de endereços --------------------------------------------
    return DeliveryFeeController.fetchDeliveryFee(settings?.display_mode_fee, null, companyUid).then((responseFee) => {
      if (responseFee.status === 200) {
        if (companyUid === null) {
          deliveryFeesRef.current = responseFee.data.rows

          if (settings?.display_mode_fee === "neighborhood") {
            setNeighbourhoods(
              deliveryFeesRef.current.map(({ neighbourhood: { id_neighbourhood, nm_neighbourhood, uuid_neighbourhood, city } }) => {
                return { id_neighbourhood, nm_neighbourhood, uuid_neighbourhood, city }
              })
            )
          }
        }
        return responseFee.data.rows
      }
    })
  }

  const getDeliveryAreas = () => {
    return DeliveryAreasController.fetchAll().then((responseAreas) => {
      if (responseAreas.status === 200) {
        return responseAreas.data
      }
    })
  }

  const getStartHour = (workingHour) => {
    const startHour = workingHour.start_hour.toString().length === 1 ? "0" + workingHour.start_hour.toString() : workingHour.start_hour.toString()
    const startMinute =
      workingHour.start_minute.toString().length === 1 ? "0" + workingHour.start_minute.toString() : workingHour.start_minute.toString()
    return startHour + ":" + startMinute + ":00"
  }

  const getEndHour = (workingHour) => {
    const endHour = workingHour.end_hour.toString().length === 1 ? "0" + workingHour.end_hour.toString() : workingHour.end_hour.toString()
    const endMinute = workingHour.end_minute.toString().length === 1 ? "0" + workingHour.end_minute.toString() : workingHour.end_minute.toString()
    return endHour + ":" + endMinute + ":00"
  }

  const getSecondsOfHour = (hour) => {
    return hour.split(":").reduce((acc, time) => 60 * acc + +time)
  }

  const getWorkingHour = (date) => {
    try {
      if (!!companyRef.current && !!companyRef.current.company_working_hours) {
        let currentDate = new moment()
        let currentSecondsHour = getSecondsOfHour(getFormatDate(currentDate).getHour("HH:mm:ss"))
        const startHour = getStartHour(companyRef.current.company_working_hours)
        const startHourSeconds = getSecondsOfHour(startHour)
        const endHour = getEndHour(companyRef.current.company_working_hours)
        const endHourSeconds = getSecondsOfHour(endHour)
        let endDate = new moment()
        let startDate = new moment()

        if (!!date) {
          currentDate = new moment(date)
          currentSecondsHour = getSecondsOfHour(getFormatDate(currentDate).getHour("HH:mm:ss"))
        }
        if (startHourSeconds < currentSecondsHour) {
          startDate = moment(currentDate.format("YYYY-MM-DD " + startHour))
          if (endHourSeconds < currentSecondsHour && endHourSeconds < startHourSeconds) {
            endDate = moment(
              moment(currentDate)
                .add(1, "days")
                .format("YYYY-MM-DD " + endHour)
            )
          } else {
            endDate = moment(moment(currentDate).format("YYYY-MM-DD " + endHour))
          }
        } else {
          // if (currentSecondsHour < startHourSeconds && currentSecondsHour > endHourSeconds) {
          //   startDate = moment(currentDate.format("YYYY-MM-DD " + startHour))
          // } else {
          startDate = moment(
            moment(currentDate)
              .subtract(1, "days")
              .format("YYYY-MM-DD " + startHour)
          )
          // }
          if (endHourSeconds > currentSecondsHour && endHourSeconds > startHourSeconds) {
            endDate = moment(
              moment(currentDate)
                .add(1, "days")
                .format("YYYY-MM-DD " + endHour)
            )
          } else {
            endDate = moment(moment(currentDate).format("YYYY-MM-DD " + endHour))
          }
        }

        return { startDate, endDate }
      } else {
        return { startDate: null, endDate: null }
      }
    } catch (error) {
      const currentDate = new moment()
      const startDate = moment(moment(currentDate).format("YYYY-MM-DD " + "00:00:00"))
      const endDate = moment(
        moment(currentDate)
          .add(1, "days")
          .format("YYYY-MM-DD " + "04:00:00")
      )
      return { startDate, endDate }
    }
  }

  const getMercadoPagoPublicKey = async () => {
    await loadMercadoPago()

    setLoading(true)

    const publicKeyRequest = await MercadoPagoController.getPublicKey()

    if (publicKeyRequest.status === 200) {
      const mp = new window.MercadoPago(publicKeyRequest.data)

      setMercadoPagoInstance(mp)
    }

    setLoading(false)
  }

  const showPopupMessage = (popupData, popupStatus) => {
    if (!!popupData) {
      if (!!popupStatus) {
        if (popupStatus[popupData.uuid_popup] === true) {
          setPopup(popupData)
        } else if (popupStatus[popupData.uuid_popup] === false) {
          //
        } else {
          setPopup(popupData)
        }
      } else {
        setPopup(popupData)
      }
    }
  }

  //Função para logar no sistema
  const onSignIn = async (username, password = null, loginWhatsapp = false) => {
    setLoading(true)

    CustomerController.signIn(encrypt(username, "base64"), encrypt(password, "base64"), loginWhatsapp)
      .then((response) => {
        if (response.status === 200) {
          setModalSignIn({ status: false })
          const user = response.data

          // setAddressesLoading(true)
          setUser({ ...user, addresses: [], fidelities: [] })
          userRef.current = { ...user, addresses: [], fidelities: [] }
          setLocalItem("user", { ...user, addresses: [], fidelities: [], movements_account: [] }, true)

          CustomerController.fetchOne(user.uuid_customer, {
            withOrders: true,
            withStreets: true,
            withMovements: true,
            withFidelities: true,
            withContacts: true,
          }).then(async (responseCustomer) => {
            if (responseCustomer.status === 200) {
              const addresses = responseCustomer.data?.streets || []
              const fidelities = responseCustomer.data?.customer_fidelities_points || []
              const movements_account = responseCustomer.data?.movements_account || []

              const dt_birth = responseCustomer.data?.dt_birth || ""
              const customer_orders = responseCustomer.data?.customer_orders

              if (addresses.length > 0) {
                const defaultAddress =
                  addresses.find(({ customer_address: { st_primary, dt_deleted_at } }) => st_primary && !dt_deleted_at) || addresses[0]
                if (
                  !userSelectedAddress?.nm_street &&
                  modality !== 2 &&
                  modality !== 3 &&
                  !!companyRef.current?.company_modality?.find(({ fk_id_modality }) => fk_id_modality === 1)
                ) {
                  setModality(1)
                  setLocalItem("modality", 1)
                  onChangeUserSelectedAddress(defaultAddress).then()
                }
              }

              setUser({ ...user, customer_orders, addresses, dt_birth, fidelities, movements_account })
              userRef.current = { ...user, customer_orders, addresses, dt_birth, fidelities, movements_account }
              setLocalItem("user", { ...user, customer_orders, addresses, fidelities, movements_account }, true)
            }
          })
        } else {
          if (!loginWhatsapp) {
            onMessageDialog("success")
              .fire({
                title: `Usuário ou senha incorretos! 😟`,
                icon: "error",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChangeStatusCompany = (st_website) => {
    const storageCompany = getLocalItem("company", true)

    setCompany({ ...storageCompany, st_website })
    companyRef.current = { ...storageCompany, st_website }
    setLocalItem("company", { ...storageCompany, st_website }, true)
  }

  const onChangeOrder = (content) => {
    setSessionItem("order", content, true)
    const [total, subTotal, benefit] = getTotalOrder(content, userSelectedAddressRef.current?.deliveryFee)
    setOrder({
      ...order,
      ...content,
      total,
      subTotal: subTotal + benefit,
    })
  }

  const onChangeFavicon = (link) => {
    let favicon = document.querySelector('link[rel="icon"]')
    if (favicon !== null) {
      favicon.href = link
    } else {
      favicon = document.createElement("link")
      favicon.rel = "icon"
      favicon.href = link
      document.head.appendChild(favicon)
    }
  }

  const onChangeTitlePage = (title) => {
    document.title = title
  }

  const onPlaceSelected = async (place, feature) => {
    setLoading(true)
    if (!!place) {
      const addressInfo = place.coordinates || false

      if (addressInfo === false) {
        setLoading(false)
        onMessageDialog()
          .fire({
            title: "Não conseguimos identificar a sua localização, tente novamente!",
            icon: "info",
            timer: 3000,
            showConfirmButton: false,
            showCancelButton: false,
          })
          .then()
      } else {
        const { latitude, longitude } = addressInfo

        setModalMinimapAddress({ status: true, content: { latitude, longitude, feature } })
        setLoading(false)
      }
    } else {
      setLoading(false)
      onMessageDialog()
        .fire({
          title: "Não conseguimos identificar o endereço selecionado, tente novamente!",
          icon: "info",
          timer: 3000,
          showConfirmButton: false,
          showCancelButton: false,
        })
        .then()
    }
  }

  const onConfirmLocation = async (addressDetails) => {
    if (!!location) {
      try {
        if (!!addressDetails) {
          await onUpdateContentFullAddress(addressDetails)
        } else {
          setModalClientAddressNotFound({ status: true, address: { latitude, longitude } })
        }
      } catch (error) {
        console.error("Erro ao buscar endereço:", error)
      } finally {
        setLoading(false)
      }
    } else {
      setLoading(false)
      onMessageDialog()
        .fire({
          title: "Não conseguimos obter a sua localização, tente novamente!",
          icon: "info",
          timer: 3000,
          showConfirmButton: false,
          showCancelButton: false,
        })
        .then()
    }
  }

  const onConfirmNumber = (number, address) => {
    const addressUpdated = {
      ...address,
      customer_address: { ...address.customer_address, num_address: number },
    }

    let editNeighbourhood = false

    if (addressUpdated.neighbourhood.nm_neighbourhood === addressUpdated.neighbourhood.city.nm_city) {
      editNeighbourhood = true

      onMessageDialog()
        .fire({
          title: `Detectamos que o nome do seu bairro é igual ao nome da sua cidade, favor alterar o bairro.`,
          icon: "warning",
          timer: 0,
          showCancelButton: false,
          showConfirmButton: true,
        })
        .then()
    }

    setModalFinalAddress({ status: true, address: addressUpdated, editNeighbourhood })
  }

  const onConfirmAddress = async (addressConfirmed) => {
    const sourceAddress = modalInitialAddress?.content?.source
    switch (sourceAddress) {
      case "mainOptions":
        setModality(1)
        break
      case "orderFinisher":
        setModality(1)
        break
      case "orderPreview":
        setModality(1)
        break
      case "newAddress":
        onUpdateCustomerAddress({ ...addressConfirmed })
        break
      default:
        if (!!editAddressRef.current && editAddressRef.current?.source === "addressesInfoOptions") {
          onUpdateCustomerAddress({ ...addressConfirmed })
          setModality(1)
        }
        break
    }
    setModalInitialAddress({ status: false, content: {} })
    setModalMapAddress({ status: false, location: {} })
    setModalNumberAddress({ status: false, address: {} })
    setModalFinalAddress({ status: false, address: {}, editNeighbourhood: false })
    await onChangeUserSelectedAddress({ ...addressConfirmed })
  }

  // Método para atualizar o conteúdo de um endereço inteiro
  const onUpdateContentFullAddress = async (response) => {
    setLoading(true)
    try {
      const { street, neighbourhood, zipcode, city, state, country, coordinates, number } = response

      // Os dois estados são alimentados ao montar o componente.
      if (!!streetTypes && !!states) {
        const [nm_street, id_street_type, nm_type] = getStreetName(streetTypes, street)
        const [id_state, uuid_state, nm_state, ds_acronym] = getState(states, state)

        if (!!uuid_state) {
          const [id_city, uuid_city, nm_city] = await getCity(city, uuid_state)

          if (!!uuid_city) {
            const [id_neighbourhood, uuid_neighbourhood, nm_neighbourhood, neighbourhoodList] = await getNeighbourhood(neighbourhood, uuid_city)

            let editNeighbourhood = false
            // Verificando se endereço é atendido pelo estabelecimento
            const location = coordinates || {}

            const deliveryFeeType = settings?.display_mode_fee

            const address = {
              zipcode,
              number,
              id_street: null,
              nm_street,
              street_type: { id_street_type, nm_type },
              neighbourhood: {
                id_neighbourhood,
                uuid_neighbourhood,
                nm_neighbourhood,
                city: {
                  id_city,
                  uuid_city,
                  nm_city,
                  state: {
                    id_state,
                    uuid_state,
                    nm_state,
                    ds_acronym,
                  },
                },
              },
              customer_address: {
                geo_coordinates: [location.latitude, location.longitude],
                num_address: editAddressRef.current?.num_address || number || "",
                ds_address_additional_info: editAddressRef.current?.ds_address_additional_info || "",
                ds_observation: editAddressRef.current?.ds_observation || "",
                st_primary: true,
                ds_title: editAddressRef.current?.ds_title || "",
              },
            }

            if (nm_neighbourhood === nm_city) {
              editNeighbourhood = true
            }

            if (deliveryFeeType === "neighborhood" && editNeighbourhood) {
              delete address.neighbourhood.uuid_neighbourhood
              delete address.neighbourhood.id_neighbourhood
            } else {
              const formatedNeighbourhoodList = neighbourhoodList.map((neighbourhood) => ({
                ...neighbourhood,
                city: { id_city, uuid_city, nm_city, state: { id_state, uuid_state, nm_state, ds_acronym } },
              }))
              setNeighbourhoods(formatedNeighbourhoodList)
            }

            setModalFinalAddress({ status: true, address, editNeighbourhood })
            // const responseCalculateFee = await DeliveryFeeController.calculate(location.latitude, location.longitude, id_neighbourhood, 1, null)
            // if (responseCalculateFee.status === 200) {
            //   setModalNumberAddress({ status: true, address })
            // } else if (responseCalculateFee.status === 204) {
            //   onMessageDialog("warning")
            //     .fire({
            //       title: "Infelizmente não entregamos nessa localização!",
            //       icon: "warning",
            //       timer: 3000,
            //       showConfirmButton: false,
            //       showCancelButton: false,
            //     })
            //     .then()
            // } else {
            //   onMessageDialog("error")
            //     .fire({
            //       title: "Ocorreu um erro ao consultar a taxa de entrega, entre em contato com o estabelecimento!",
            //       icon: "warning",
            //       timer: 3000,
            //       showConfirmButton: false,
            //       showCancelButton: false,
            //     })
            //     .then()
            // }
          } else {
            onMessageDialog("info")
              .fire({
                title: "Não conseguimos identificar sua cidade, tente novamente!",
                icon: "info",
                timer: 3000,
                showConfirmButton: false,
                showCancelButton: false,
              })
              .then()
          }
        } else {
          onMessageDialog()
            .fire({
              title: "Não conseguimos identificar seu estado, tente novamente!",
              icon: "info",
              timer: 3000,
              showConfirmButton: false,
              showCancelButton: false,
            })
            .then()
        }
      }
    } catch (error) {
      onMessageDialog("info")
        .fire({
          title: "Não conseguimos identificar seu endereço, tente novamente!",
          icon: "info",
          timer: 3000,
          showConfirmButton: false,
          showCancelButton: false,
        })
        .then()
      console.error("error", error)
    } finally {
      setLoading(false)
    }
  }

  //Médoto para verificar a entraga das outras lojas do assinante
  const verifySubscriberDeliveryArea = async (address, location) => {
    //Caso não seja realizada entrega no endereço do cliente, verifica a área de entrega das demais lojas do assinante para redirecionar.
    let otherCompanyDeliveryFees = []
    let subscriberCompanies = []
    for (const otherCompany of companies) {
      if (otherCompany.id_company !== company.id_company) {
        otherCompanyDeliveryFees = await getDeliveryFees(settings, otherCompany.uuid_company)

        let onDeliveryArea = null
        const deliveryFeeType = settings?.display_mode_fee

        if (deliveryFeeType === "neighborhood") {
          onDeliveryArea = !!otherCompanyDeliveryFees
            ? otherCompanyDeliveryFees.find(({ neighbourhood }) => neighbourhood.uuid_neighbourhood === address.neighbourhood.uuid_neighbourhood)
            : null
        } else {
          const companyCoordinates =
            !!company.company_address && company.company_address.length > 0
              ? {
                  latitude: company.company_address[0].geo_coordinates[0],
                  longitude: company.company_address[0].geo_coordinates[1],
                }
              : { latitude: -27.582736076873427, longitude: -48.61364576177011 }

          const customerCoordinates = { latitude: location?.lat, longitude: location?.lng }

          const distance = getDistanceCoordinates(companyCoordinates, customerCoordinates)
          onDeliveryArea = otherCompanyDeliveryFees.find((deliveryFee) => deliveryFee.nro_km_fee >= Math.round(distance))
        }

        if (!!onDeliveryArea || (deliveryFeeType === "neighborhood" && !address.neighbourhood.nm_neighbourhood)) {
          // setModalNumberAddress({ status: true, address })
          subscriberCompanies.push(otherCompany)
        }
      }
    }

    return subscriberCompanies
  }

  const onUpdateCustomerAddress = (address) => {
    setAddressesLoading(true)

    let addresses = [...user.addresses]
    if (!!editAddressRef.current && editAddressRef.current?.source === "addressesInfoOptions") {
      addresses = user.addresses.filter(
        ({ customer_address }) => customer_address?.id_customer_address !== editAddressRef.current?.customer_address?.id_customer_address
      )
      editAddressRef.current = null
    }

    const originalListAddresses = addresses.map((address) => {
      return { ...address, st_primary: false }
    })

    CustomerController.update(user.uuid_customer, {
      addresses: [
        ...originalListAddresses,
        {
          ...address,
          st_primary: true,
        },
      ],
    })
      .then((responseAddressUpdate) => {
        if (responseAddressUpdate.status === 200) {
          const addresses = responseAddressUpdate.data?.streets || []
          if (addresses.length > 0) {
            const defaultAddress = addresses.find(({ st_primary }) => st_primary) || addresses[0]
            // if (!userSelectedAddress.nm_street) {
            onChangeUserSelectedAddress(defaultAddress).then()
            // }
          }

          setUser({ ...user, addresses })
          userRef.current = { ...user, addresses }
          setLocalItem("user", { ...user, addresses }, true)

          onMessageDialog("success")
            .fire({
              title: "Endereços atualizados com sucesso!",
              icon: "success",
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
        } else {
          onMessageDialog("danger")
            .fire({
              title: "Não conseguimos atualizar os seus endereços, tente novamente!",
              icon: "error",
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: "Confirmar",
              reverseButtons: true,
            })
            .then()
        }
      })
      .catch(() => {
        onMessageDialog("danger")
          .fire({
            title: "Não conseguimos atualizar os seus endereços, tente novamente!",
            icon: "error",
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Confirmar",
            reverseButtons: true,
          })
          .then()
      })
      .finally(() => {
        setAddressesLoading(false)
      })
  }

  const onConfirmOrderSchedule = (scheduleInfo) => {
    scheduleInfoRef.current = scheduleInfo
    setModalOrderFinisher(true)
  }

  const clearOrder = () => {
    setOrder({
      customer: null,
      combos: [],
      products: [],
      beverages: [],
      payments: [],
      benefits: [],
      total: 0.0,
      subTotal: 0.0,
      comments: "",
      discount: order?.discount || "0,00",
      discountType: order?.discountType || "R$",
      deliveryFee: 0,
      deliveryComments: "",
    })
    scheduleInfoRef.current = null
    finalizingOrderRef.current = false

    setModalOrderPreview(false)
    setModalOrderSchedule(false)
    setModalOrderFinisher(false)
    setModalPaymentMethods({ status: false, content: null })

    setFinalizingOrder(false)
    removeSessionItem("order")
  }

  const preFinishOrder = async (scheduleInfo = null) => {
    if (finalizingOrderRef.current === false) {
      finalizingOrderRef.current = true
      setFinalizingOrder(true)
      setModalOrderProcess({ status: true, resend: false, newRequest: false, scheduleInfo })

      const paymentType = order.payments[0].id_payment_method === 38 ? "pix" : order.payments[0].id_payment_method === 37 ? "creditcard" : null

      if (!!scheduleInfo) {
        finalizingOrderRef.current = false
        setFinalizingOrder(false)
        return finishOrder("schedule", scheduleInfo, false, [37, 38].includes(order.payments[0].id_payment_method), paymentType)
      }

      let { startDate, endDate } = await getWorkingHour()
      if (!!startDate && !!endDate) {
        // Verificando se o cliente já possui um pedido no dia de corrente
        OrderController.countOrdersByCustomer(user.uuid_customer, endDate.format("YYYY-MM-DD HH:mm:ss"), startDate.format("YYYY-MM-DD HH:mm:ss"))
          .then((response) => {
            if (response.status === 200) {
              if (response.data > 0) {
                finalizingOrderRef.current = false
                setFinalizingOrder(false)
                setModalOrderProcess({
                  status: true,
                  resend: true,
                  newRequest: true,
                  scheduleInfo,
                  content: {
                    onlinePayment: [37, 38].includes(order.payments[0].id_payment_method),
                    typeOnlinePayment: paymentType,
                  },
                })
              } else {
                finalizingOrderRef.current = false
                setFinalizingOrder(false)
                finishOrder("direct", null, false, [37, 38].includes(order.payments[0].id_payment_method), paymentType)
              }
            } else {
              onMessageDialog("danger")
                .fire({
                  title: response?.data?.message || "Ocorreu um erro ao processar o pedido, tente novamente!",
                  icon: "error",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  reverseButtons: true,
                })
                .then()
              finalizingOrderRef.current = false
              setFinalizingOrder(false)
              setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })
            }
          })
          .catch((error) => {
            console.error("error", error)
            onMessageDialog("danger")
              .fire({
                title: "Ocorreu uma falha ao processar o pedido, tente novamente!",
                icon: "error",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "Confirmar",
                reverseButtons: true,
              })
              .then()
            finalizingOrderRef.current = false
            setFinalizingOrder(false)
            setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })
          })
      } else {
        onMessageDialog("danger")
          .fire({
            title: "Erro ao processar o pedido, por favor, tente novamente!",
            icon: "error",
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
            reverseButtons: true,
          })
          .then()
        finalizingOrderRef.current = false
        setFinalizingOrder(false)
        setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })
      }
    }
  }

  //Função para finalizar o pedido
  const finishOrder = async (
    orderType = "direct",
    scheduleInfo = null,
    st_resended = false,
    onlinePayment = false,
    typeOnlinePayment = null,
    newRequest = false
  ) => {
    if (finalizingOrderRef.current === false) {
      finalizingOrderRef.current = true
      setFinalizingOrder(true)

      setModalOrderProcess({ status: true, resend: false, newRequest: false, scheduleInfo })

      if (modality !== 2 && modality !== 3) {
        if (!userSelectedAddressRef.current) {
          SupportController.throwNewLog({
            type: "warning",
            content: { customer: user },
            message: "Pedido não enviado, faltou informar o endereço",
          }).then()
          setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })

          onMessageDialog()
            .fire({
              title: "Estamos quase lá, só faltou informar o seu endereço...",
              icon: "info",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          finalizingOrderRef.current = false
          setFinalizingOrder(false)

          return false
        }

        //Verificando se entrega na área selecionada.
        let onDeliveryArea = null
        await DeliveryFeeController.calculate(
          userSelectedAddressRef.current.customer_address.geo_coordinates[0],
          userSelectedAddressRef.current.customer_address.geo_coordinates[1],
          userSelectedAddressRef.current.neighbourhood.id_neighbourhood,
          modality,
          userSelectedAddressRef.current.customer_address.id_customer_address
        ).then((response) => {
          switch (response.status) {
            case 200:
              onDeliveryArea = response.data
              break
            case 204:
              onMessageDialog("warning")
                .fire({
                  title: "Infelizmente não entregamos nessa localização!",
                  icon: "warning",
                  timer: 3000,
                  showConfirmButton: false,
                  showCancelButton: false,
                })
                .then()
              break
            default:
              onMessageDialog("error")
                .fire({
                  title: "Ocorreu um erro ao consultar a taxa de entrega, entre em contato com o estabelecimento!",
                  icon: "warning",
                  timer: 3000,
                  showConfirmButton: false,
                  showCancelButton: false,
                })
                .then()
          }
        })
        if (!onDeliveryArea) {
          SupportController.throwNewLog({
            type: "warning",
            content: { customer: user, deliveryFeesRef: deliveryFeesRef.current },
            message: "Pedido não enviado, endereço não atendido",
          }).then()
          setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })

          onMessageDialog()
            .fire({
              title: "Infelizmente não atendemos no seu endereço! 😕",
              icon: "warning",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          finalizingOrderRef.current = false
          setFinalizingOrder(false)

          return false
        }

        if (
          !!userSelectedAddressRef.current &&
          (!userSelectedAddressRef.current?.customer_address?.geo_coordinates[0] ||
            !userSelectedAddressRef.current?.customer_address?.geo_coordinates[1])
        ) {
          SupportController.throwNewLog({
            type: "warning",
            content: { customer: user, userSelectedAddressRef: userSelectedAddressRef.current },
            message: "Pedido não enviado, endereço não confirmado(sem coordenadas)",
          }).then()

          onMessageDialog("info")
            .fire({
              title: `Para prosseguir é necessário confirmar seu endereço!`,
              icon: "info",
              confirmButtonText: "Confirmar",
              showConfirmButton: true,
              reverseButtons: true,
              showCancelButton: false,
            })
            .then(async ({ isConfirmed }) => {
              if (isConfirmed) {
                setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })
                const addressSuggestionsByName = await getAddressSuggestionsByName(
                  getLabelAddress(userSelectedAddressRef.current, { withNumber: true }, company?.company_address[0]?.geo_coordinates)
                )
                if (!!addressSuggestionsByName) {
                  const addressCoordinates = await getAddressCoordinates(addressSuggestionsByName[0].mapbox_id)
                  if (!!addressCoordinates) {
                    const { latitude, longitude } = addressCoordinates
                    setModalMapAddress({
                      status: true,
                      location: { latitude, longitude },
                    })
                    editAddressRef.current = { ...userSelectedAddressRef.current?.customer_address }
                  } else {
                    setModalInitialAddress({ status: true, content: { source: "orderFinisher" } })
                  }
                } else {
                  setModalInitialAddress({ status: true, content: { source: "orderFinisher" } })
                }
              }
            })
          finalizingOrderRef.current = false
          setFinalizingOrder(false)
          setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })

          return false
        }
      }

      //Verificando se o total do pedido confere com o valor em estado para saber se os itens continuam corretos
      const [total, subTotal, benefitTotal] = getTotalOrder(order, userSelectedAddress?.deliveryFee)

      if (subTotal + benefitTotal !== order.subTotal) {
        SupportController.throwNewLog({
          type: "warning",
          content: { customer: user, subTotal, benefitTotal, orderSubTotal: order.subTotal },
          message: "Pedido não enviado, falha ao identificar valor total do pedido",
        }).then()
        setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })

        onMessageDialog()
          .fire({
            title: "Falha ao finalizar o pedido, selecione novamente seus itens e tente reenviar o pedido! 😕",
            icon: "warning",
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
          })
          .then()
        finalizingOrderRef.current = false
        setFinalizingOrder(false)

        return false
      }

      // Verificando se existe forma de pagamento selecionada e se o total do pagamento é o mesmo do pedido
      let orderInsert = { ...order }

      let totalPaymentValidation = order.payments.reduce((prev, current) => prev + current.val_payment, 0)

      const orderDiscount =
        modality === 2
          ? parseFloat(settings.pickup_discount_value || 0)
          : modality === 3
          ? parseFloat(settings.indoor_discount_value || 0)
          : parseFloat(settings.delivery_discount_value || 0)
      const orderDiscountType =
        modality === 2 ? settings.pickup_discount_type : modality === 3 ? settings.indoor_discount_type : settings.delivery_discount_type

      const subtotalOrder =
        order.subTotal + (![3, 2].includes(modality) ? userSelectedAddressRef.current?.deliveryFee || 0 : 0) - benefitTotal - orderDiscount

      if (subtotalOrder !== totalPaymentValidation) {
        const orderPayment = order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() !== "cashback")
        const cashBackPayment = order.payments.find(({ nm_payment_method }) => nm_payment_method.toLowerCase() === "cashback")

        let requestPayment = false

        if (!!orderPayment) {
          if (
            orderPayment.id_payment_method !== 1 ||
            (orderPayment.id_payment_method === 1 &&
              (orderPayment.val_receivable > subtotalOrder - (!!cashBackPayment ? cashBackPayment.val_payment : 0) ||
                orderPayment.val_receivable === 0))
          ) {
            let payments = [
              {
                ...orderPayment,
                val_payment: subtotalOrder - (!!cashBackPayment ? cashBackPayment.val_payment : 0),
              },
            ]
            if (!!cashBackPayment) {
              payments.push(cashBackPayment)
            }
            //Alterando o order
            orderInsert = {
              ...order,
              payments,
            }
          } else {
            requestPayment = true
          }
        } else {
          requestPayment = true
        }

        if (requestPayment) {
          setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })
          SupportController.throwNewLog({
            type: "warning",
            content: { customer: userRef.current, orderPayment },
            message: "Pedido não enviado, Falha na confirmação dos pagamentos",
          }).then()
          onMessageDialog()
            .fire({
              title: "Selecione novamente sua forma de pagamento e tente reenviar o pedido! 😕",
              icon: "warning",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
          finalizingOrderRef.current = false
          setFinalizingOrder(false)

          setOrder({ ...order, payments: order.payments.filter(({ nm_payment_method }) => nm_payment_method === "Cashback") })
          setSelectedPaymentMethod({
            id_payment_method: -1,
          })
          setShowPaymentTypeSelector(true)

          return false
        }
      }

      const cashbackPayment = order?.payments[1]?.val_payment || 0
      const deliveryFeeValue = modality === 1 ? userSelectedAddress?.deliveryFee || 0 : 0
      const discount = getDiscountOrder(order, subTotal, deliveryFeeValue, settings, modality)

      const orderTotal = total + deliveryFeeValue - benefitTotal - discount - cashbackPayment

      orderInsert = {
        ...orderInsert,
        orderTotal,
      }

      if (![3, 2].includes(modality)) {
        let addresses = [...userRef.current.addresses]

        if (!!editAddressRef.current) {
          addresses = userRef.current.addresses.filter(
            ({ customer_address }) => customer_address?.id_customer_address !== editAddressRef.current?.customer_address?.id_customer_address
          )
          editAddressRef.current = null
        } else {
          if (!!userSelectedAddressRef.current.customer_address.id_customer_address) {
            addresses = userRef.current.addresses.filter(
              ({ customer_address }) => customer_address?.id_customer_address !== userSelectedAddressRef.current.customer_address.id_customer_address
            )
          }
        }

        const originalListAddresses = addresses.map((address) => {
          return { ...address, st_primary: false }
        })

        if (!!userSelectedAddressRef.current.customer_address.dt_deleted_at) {
          userSelectedAddressRef.current = {
            ...userSelectedAddressRef.current,
            customer_address: { ...(userSelectedAddressRef?.current?.customer_address || {}), dt_deleted_at: null, id_customer_address: null },
            st_primary: true,
          }
        }
        CustomerController.update(userRef.current.uuid_customer, {
          addresses: [...originalListAddresses].concat([
            {
              ...userSelectedAddressRef.current,
              st_primary: true,
            },
          ]),
        })
          .then(async (responseAddressUpdate) => {
            if (responseAddressUpdate.status === 200) {
              const addresses = responseAddressUpdate.data?.streets || []
              const defaultAddress = addresses.find(
                ({ customer_address: { st_primary, dt_deleted_at }, nm_street }) =>
                  st_primary &&
                  !dt_deleted_at &&
                  escapeSpecialChart(nm_street).toLowerCase() === escapeSpecialChart(userSelectedAddressRef?.current?.nm_street).toLowerCase()
              )

              if (!!defaultAddress && defaultAddress.id_street && defaultAddress?.neighbourhood?.id_neighbourhood) {
                let updatedUser = { ...userRef.current, addresses }
                setUser(updatedUser)
                userRef.current = updatedUser
                const addressUpdated = await onChangeUserSelectedAddress(defaultAddress, true)
                if (!addressUpdated) {
                  SupportController.throwNewLog({
                    type: "warning",
                    content: { customer: userRef.current, addressUpdated, defaultAddress },
                    message: "Pedido não enviado, Erro na atualização do cliente",
                  }).then()
                  setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })

                  onMessageDialog()
                    .fire({
                      title: "Falha ao verificar o processamento do seu endereço, tente novamente!",
                      icon: "warning",
                      timer: 4000,
                      showCancelButton: false,
                      showConfirmButton: false,
                    })
                    .then()
                  finalizingOrderRef.current = false
                  setFinalizingOrder(false)

                  return false
                }

                setLocalItem("user", updatedUser, true)
                const customer = {
                  defaultAddress,
                  userSelectedAddress: {
                    ...addressUpdated,
                  },
                  uuid_customer: userRef.current.uuid_customer,
                  nm_customer: userRef.current.nm_customer,
                  id_street_customer: defaultAddress.id_street,
                  id_customer_address: defaultAddress?.customer_address?.id_customer_address,
                  id_neighbourhood: defaultAddress?.neighbourhood?.id_neighbourhood,
                }

                orderInsert = {
                  ...orderInsert,
                  discount: orderDiscount,
                  discountType: orderDiscountType,
                  id_modality: modality,
                  deliveryFee: !!addressUpdated.deliveryFee ? formatFloat(addressUpdated.deliveryFee) : 0,
                  deliveryTime: !!addressUpdated.deliveryTime ? addressUpdated.deliveryTime : Number(settings?.delivery_time) || 0,
                  st_resended,
                }
                insertOrder(orderInsert, customer, orderType, scheduleInfo, onlinePayment, typeOnlinePayment, newRequest)
              } else {
                SupportController.throwNewLog({
                  type: "warning",
                  content: { customer: userRef.current, defaultAddress },
                  message: "Pedido não enviado, Falha ao identificar o endereço",
                }).then()
                setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })

                onMessageDialog()
                  .fire({
                    title: "Não conseguimos identificar o seu endereço, por favor selecione novamente! 😕",
                    icon: "warning",
                    timer: 4000,
                    showCancelButton: false,
                    showConfirmButton: false,
                  })
                  .then()
                finalizingOrderRef.current = false
                setFinalizingOrder(false)
              }
            } else {
              setUser({ ...getLocalItem("user", true) })
              userRef.current = { ...getLocalItem("user", true) }
              SupportController.throwNewLog({
                type: "error",
                content: { customer: userRef.current, responseAddressUpdate },
                message: "Pedido não enviado, falha ao atualizar o endereço",
              }).then()
              setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })

              onMessageDialog("danger")
                .fire({
                  title: "Não conseguimos atualizar o seu endereço, por favor tente novamente! (1)",
                  icon: "error",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: "Confirmar",
                  reverseButtons: true,
                })
                .then()
              finalizingOrderRef.current = false
              setFinalizingOrder(false)
            }
          })
          .catch((error) => {
            console.error("error", error)

            SupportController.throwNewLog({
              type: "error",
              content: { customer: userRef.current, errorMessage: error.message },
              message: "Pedido não enviado, catch da atualização do cliente",
            }).then()
            setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })

            onMessageDialog("danger")
              .fire({
                title: "Não conseguimos atualizar os dados para realizar o pedido, tente novamente!",
                icon: "error",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
              })
              .then()
            finalizingOrderRef.current = false

            setFinalizingOrder(false)
          })
      } else if ([3, 2].includes(modality)) {
        const customer = {
          uuid_customer: userRef.current.uuid_customer,
          nm_customer: userRef.current.nm_customer,
          id_street_customer: null,
          id_customer_address: null,
          id_neighbourhood: null,
        }
        orderInsert = {
          ...orderInsert,
          discount: settings.pickup_discount_value,
          discountType: orderDiscountType,
          id_modality: modality,
          deliveryFee: 0,
          deliveryTime: modality === 2 ? Number(settings.pickup_time) : Number(settings.indoor_time),
          st_resended,
        }

        insertOrder(orderInsert, customer, orderType, scheduleInfo, onlinePayment, typeOnlinePayment, newRequest)
      }
    }
  }

  const insertOrder = async (orderInsert, customer, orderType, scheduleInfo, onlinePayment, typeOnlinePayment, newRequest, retry = false) => {
    if (onlinePayment) {
      switch (typeOnlinePayment) {
        case "pix":
          const isPixelInitialized = !!activePixelId.pixelId
          const parsedOnlinePaymentOrder = getParamsToCreateOrder(orderInsert, customer, isPixelInitialized)
          PaymentsController.createPayment(parsedOnlinePaymentOrder, null, customer, "pix", newRequest).then((response) => {
            if (response.status === 422) {
              setModalOrderProcess({ status: true, resend: true, newRequest: true, scheduleInfo, content: { onlinePayment, typeOnlinePayment } })
              finalizingOrderRef.current = false
              setFinalizingOrder(false)
            } else if (response.status === 200) {
              setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })
              setModalPixPayment({ status: true, content: response.data })
              modalPixPaymentContent.current = response.data
              clearOrder()
            } else {
              setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })
              finalizingOrderRef.current = false
              setFinalizingOrder(false)
              onMessageDialog("danger")
                .fire({
                  title: "Falha ao gerar o pagamento online, tente novamente!",
                  icon: "error",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  reverseButtons: true,
                })
                .then()
            }
          })
        default:
          break
      }
    } else {
      const content = { orderInsert, customer, orderType, scheduleInfo, onlinePayment, typeOnlinePayment, newRequest }

      OrderController.insert(orderInsert, customer, orderType, scheduleInfo)
        .then((response) => {
          if (response.status === 201) {
            const newOrder = response.data

            setModalOrderProcess({
              status: false,
              resend: false,
              newRequest: false,
              scheduleInfo: null,
              content: { onlinePayment, typeOnlinePayment },
            })

            //Atualizando usuário da sessão setUser(updatedUser)
            const updatedUser = {
              ...userRef.current,
              customer_orders: [
                ...userRef.current.customer_orders,
                {
                  fk_id_customer_address: response?.data?.customer_address_order?.id_customer_address,
                  id_order: response?.data?.id_order,
                  id_street_customer: response?.data?.id_street_customer,
                  st_confirmed: false,
                  dt_deleted_at: null,
                },
              ],
            }
            setUser(updatedUser)
            userRef.current = updatedUser
            setLocalItem("user", updatedUser, true)

            if (orderType === "schedule") {
              // Sinalizando o registro de um novo pedido agendado
              console.info("EMITINDO EVENTO [Home] [schedule_order]")
              !!socket &&
                socket.emit("schedule_order", {
                  origin: "site",
                  companyId: company.id_company,
                })

              console.info("EMITINDO EVENTO [Home] [signal_schedule_order]")
              !!socket &&
                socket.emit("signal_schedule_order", {
                  origin: "site",
                  companyId: company.id_company,
                })

              ReactPixel.track("Purchase", { value: newOrder.val_subtotal, currency: "BRL" })

              onMessageDialog("sucess")
                .fire({
                  title: "Pedido agendado com sucesso!",
                  icon: "success",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                })
                .then(({ isDismissed }) => {
                  if (isDismissed) {
                    // goToOrderProgressRef.current = { status: false, scheduleOrderUid: newOrder.uuid_schedule_order }
                    showOrderProgressContextRef.current = { status: false, scheduleOrderUid: newOrder.uuid_schedule_order }

                    setTabSelected("profile")
                  }
                })
            } else {
              ReactPixel.track("Purchase", { value: newOrder.val_subtotal, currency: "BRL" })

              onMessageDialog("sucess")
                .fire({
                  title: "Pedido realizado com sucesso!",
                  icon: "success",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                })
                .then(({ isDismissed }) => {
                  if (isDismissed) {
                    // goToOrderProgressRef.current = { status: true, orderUid: newOrder.uuid_order, order: newOrder }
                    showOrderProgressContextRef.current = { status: true, orderUid: newOrder.uuid_order, content: newOrder }

                    setTabSelected("profile")
                  }
                })
            }

            if (!!order.id_fidelity) {
              let localFidelitiesUsed = getSessionItem("fidelitiesUsed", true)

              if (!!localFidelitiesUsed) {
                localFidelitiesUsed.push(order.id_fidelity)
              } else {
                localFidelitiesUsed = [order.id_fidelity]
              }

              setSessionItem("fidelitiesUsed", localFidelitiesUsed, true)
            }
            const fidelityUsedIndex = fidelities.findIndex(({ id_fidelity }) => id_fidelity === order.id_fidelity)
            if (fidelityUsedIndex !== -1) {
              fidelities[fidelityUsedIndex].used = true
              setFidelities([...fidelities])
            }

            if (order.benefits.length > 0) {
              let localBenefitsUsed = getSessionItem("benefitsUsed", true)

              if (!!localBenefitsUsed) {
                localBenefitsUsed.push(order.id_benefit)
              } else {
                localBenefitsUsed = [order.id_benefit]
              }

              setSessionItem("benefitsUsed", localBenefitsUsed, true)
            }

            clearOrder()
          } else if (response.status === 200) {
            if (!retry) {
              onMessageDialog("danger")
                .fire({
                  title: response?.message || "Falha ao verificar se o pedido foi processado, tente novamente!",
                  icon: "error",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  reverseButtons: true,
                })
                .then()
            }
            finalizingOrderRef.current = false
            setFinalizingOrder(false)
            setModalOrderProcess({ status: true, resend: true, newRequest: false, scheduleInfo, content })
          } else if (response.status === 400) {
            if (!retry) {
              onMessageDialog("danger")
                .fire({
                  title: response?.data?.message || "Erro ao processar o pedido, tente novamente!",
                  icon: "error",
                  timer: 3000,
                  showCancelButton: false,
                  showConfirmButton: false,
                  reverseButtons: true,
                })
                .then()
            }
            finalizingOrderRef.current = false
            setFinalizingOrder(false)
            setModalOrderProcess({ status: true, resend: true, newRequest: false, scheduleInfo, content })
          } else {
            // if (!retry) {
            //   onMessageDialog("danger")
            //     .fire({
            //       title: response?.data?.message || response?.message || "Ocorreu um erro ao processar o pedido, tente novamente!",
            //       icon: "error",
            //       timer: 3000,
            //       showCancelButton: false,
            //       showConfirmButton: false,
            //       reverseButtons: true,
            //     })
            //     .then()
            // }
            finalizingOrderRef.current = false
            setFinalizingOrder(false)
            setModalOrderProcess({ status: true, resend: true, newRequest: false, scheduleInfo, content })
          }
        })
        .catch((error) => {
          console.error("error", error)
          SupportController.throwNewLog({
            type: "error",
            content: { customer: userRef.current, order, errorMessage: error.message },
            message: "Pedido não enviado, catch da inserção do pedido",
          }).then()
          if (!retry) {
            onMessageDialog("danger")
              .fire({
                title: "Houve uma falha ao processar o pedido, tente novamente!",
                icon: "error",
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "Confirmar",
                reverseButtons: true,
              })
              .then()
          }
          finalizingOrderRef.current = false
          setFinalizingOrder(false)

          setModalOrderProcess({ status: true, resend: true, newRequest: false, scheduleInfo, content })
        })
    }
  }

  const handleOutsideCoverageArea = (value) => {
    setIsOutsideCoverageArea(value)
  }

  const onAddBeverages = (beverages, selectedItem = null) => {
    const colors = [
      { id: 1, used: false, color: "#F2D7D5" },
      { id: 2, used: false, color: "#D4E6F1" },
      { id: 3, used: false, color: "#D4EFDF" },
      { id: 4, used: false, color: "#FDEBD0" },
      { id: 5, used: false, color: "#FADBD8" },
      { id: 6, used: false, color: "#EBDEF0" },
      { id: 7, used: false, color: "#D6EAF8" },
      { id: 8, used: false, color: "#D1F2EB" },
      { id: 9, used: false, color: "#D6DBDF" },
      { id: 10, used: false, color: "#F4ECF7" },
      { id: 11, used: false, color: "#FAE5D3" },
    ]

    if (!!selectedItem) {
      //verificando quantidade de produto com mesmo id
      const amountProduct =
        !!order.products &&
        order.products.reduce((acumulator, product) => {
          return product.uuid_product === selectedItem.products.uuid_product ? acumulator + 1 : acumulator
        }, 0)

      order.products.push({
        ...selectedItem.products,
        nro_product: amountProduct + 1,
        backgroundColor: colors[amountProduct].color,
      })

      for (const beverage of selectedItem.beverages) {
        order.beverages.push({
          ...beverage,
          amount: beverage.amount || 1,
          nro_product: amountProduct + 1,
          backgroundColor: colors[amountProduct].color,
        })
      }
    } else {
      for (const beverage of beverages) {
        const indexBeverage = order.beverages.findIndex(
          ({ id_beverage, id_product, nro_item }) => !nro_item && !id_product && id_beverage === beverage.id_beverage
        )

        if (indexBeverage === -1) {
          order.beverages.push({ ...beverage })
        }
      }
    }

    ReactPixel.track("AddToCart", {})
    onChangeOrder({ ...order })
    setModalBuilder({ ...modalBuilder, status: false, content: null, size: null })
    setModalBeverages({ status: false, content: null })
  }

  //CONTENT FUNCTIONS
  const onClickItem = (type, content) => {
    switch (type) {
      case "category":
        setModalBeverages({ status: true, category: content })
        break
      case "beverage":
        // Verificando se já existe a bebida na lista de pedido
        if (
          !order.beverages.find(
            ({ id_beverage, id_product, nro_product = null, nro_combo = null, nm_beverage }) =>
              !id_product && id_beverage === content.id_beverage && nro_product === null && nro_combo === null
          )
        ) {
          const newOrder = { ...order, beverages: [...order.beverages, { ...content, amount: 1 }] }

          const [total] = getTotalOrder(newOrder)
          setOrder({ ...newOrder, total, subTotal: total })

          ReactPixel.track("AddToCart", { nm_item: content?.nm_beverage })

          GeneralTools.swalMessage("success")
            .fire({
              title: `${getBeverageName(content)} inserida a sua sacola!`,
              icon: "success",
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
        } else {
          GeneralTools.swalMessage("success")
            .fire({
              title: `${getBeverageName(content)} já está em sua sacola!`,
              icon: "info",
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then()
        }
        break
      case "flavour":
        // const { uuid_product, flavour, productIndex } = content

        //Filtrando os tamanhos que não estão habilitados para o site
        const flavourSizes = content.flavour.sizes.filter(({ st_website }) => st_website).sort((a, b) => a.nro_priority - b.nro_priority)
        setModalBuilder({
          ...modalBuilder,
          type,
          status: true,
          content: {
            ...content,
            size: flavourSizes[0],
          },
        })

        break
      case "highlight":
        const highlightBeverage = content.highlight_beverages.length > 0
        const highlightCombo = content.highlight_combos.length > 0
        const highlightProduct = content.highlight_products.length > 0

        if (highlightBeverage) {
          const beverageSelected = beverages.find(({ id_beverage }) => id_beverage === content.highlight_beverages[0].id_beverage)
          onClickItem("beverage", beverageSelected)
        } else if (highlightCombo) {
          const comboSelected = combos.find(({ id_combo }) => id_combo === content.highlight_combos[0].id_combo)
          setModalBuilder({ status: true, type: "combo", content: { combo: comboSelected } })
        } else if (highlightProduct) {
          const product = content.highlight_products[0].highlights_products
          let productSelected = products.find(({ id_product }) => id_product === product.fk_id_product)

          if (productSelected.st_display_mode === "size") {
            productSelected = {
              ...productSelected,
              size: productSelected.sizes.find(({ id_size }) => id_size === product.fk_id_size),
            }
            delete productSelected.sizes
            setModalBuilder({ status: true, type: "size", content: productSelected })
          } else {
            const highlightFlavour = productSelected.flavours.find(({ id_flavour }) => id_flavour === product.fk_id_flavour)

            setModalBuilder({
              type: "flavour",
              status: true,
              content: {
                size: productSelected.sizes[0],
                flavour: highlightFlavour,
                uuid_product: productSelected.uuid_product,
              },
            })
          }
        }

        break
      default:
        setModalBuilder({ status: true, type, content })
        break
    }
  }

  const onShowModalSchedule = () => {
    if (!!userSelectedAddress) {
      setModalOrderSchedule(true)
    } else {
      onMessageDialog("info")
        .fire({
          title: "Para agendar um pedido é preciso escolher um endereço",
          icon: "info",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
        })
        .then()
    }
  }

  const onChangeAmountProduct = (amount, indexProduct) => {
    let products = order.products
    if (amount > 0) {
      let newProducts = products.map((product, index) => {
        if (index === indexProduct) {
          product.amount = amount
          return product
        }
        return product
      })

      let newBeverages = order.beverages.map((beverage, index) => {
        if (index === indexProduct) {
          beverage.amount = amount
          return beverage
        }
        return beverage
      })

      const newOrder = {
        ...order,
        products: newProducts,
        beverage: newBeverages,
      }

      const [total] = newOrder
      setOrder({ ...newOrder, total, subTotal: total })
    } else if (amount === 0) {
      GeneralTools.swalMessage("delete")
        .fire({
          title: "Deseja remover o item do carrinho?",
          icon: "question",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          showConfirmButton: true,
          reverseButtons: true,
        })
        .then((response) => {
          if (response.isConfirmed) {
            let newProducts = products
              .filter((product, index) => {
                if (index === indexProduct) {
                  return null
                }
                return product
              })
              .filter((eachElement) => Object.keys(eachElement).length > 0)

            setOrder({
              ...order,
              products: newProducts,
            })
          }
        })
    }
  }

  const onChangeOrderBuilder = (item, selectedItem, comboRemoved = null) => {
    if (!!item) {
      if ("id_combo" in item) {
        order.combos.push({ ...item, amount: selectedItem.amount, backgroundColor: getBackgroundColor(currentOrderCombo) })

        if (!!selectedItem.products) {
          for (const product of selectedItem.products) {
            order.products.push({
              ...product,
              amount: 1,
              id_combo: product.id_combo,
              nro_item: product.nro_item,
              nro_combo: product.nro_combo,
            })

            for (const beverage of product.beverages) {
              order.beverages.push({
                ...beverage,
                id_combo: item.id_combo,
                nro_combo: item.nro_combo,
                nro_item: product.nro_item,
              })
            }
          }
        }

        if (!!selectedItem.beverages) {
          for (const beverage of selectedItem.beverages) {
            order.beverages.push({
              ...beverage,
              id_combo: item.id_combo,
              nro_combo: item.nro_combo,
              nro_item: beverage.nro_item,
            })
          }
        }

        // Removendo os dados do combo antigo
        if (!!comboRemoved) {
          const comboIndex = order.combos.findIndex(
            ({ nro_combo, id_combo }) => nro_combo === comboRemoved?.comboNro && id_combo === comboRemoved?.comboId
          )
          if (comboIndex !== -1) {
            order.combos.splice(comboIndex, 1)
            order.products = order.products.filter(
              ({ nro_combo, id_combo }) => nro_combo !== comboRemoved?.comboNro || id_combo !== comboRemoved?.comboId
            )
            order.beverages = order.beverages.filter(
              ({ nro_combo, id_combo }) => nro_combo !== comboRemoved?.comboNro || id_combo !== comboRemoved?.comboId
            )
          }
        }
      } else {
        if (selectedItem.size.beverages.length > 0) {
          setModalBeverages({
            status: true,
            content: null,
            data: {
              isMandatory: selectedItem.size.st_required_beverage,
              maxBeverages: selectedItem.size.qty_beverages,
              selectedItem,
            },
          })
        } else {
          const amountProduct =
            !!order.products &&
            order.products.reduce((acumulator, product) => {
              return product.fk_id_product === selectedItem.fk_id_product ? acumulator + 1 : acumulator
            }, 0)

          order.products.push({ ...selectedItem, nro_product: amountProduct + 1 })
        }
      }

      const [total, subTotal] = getTotalOrder({ ...order })
      setSessionItem("order", { ...order, total, subTotal }, true)
      setOrder({ ...order, total, subTotal })
    } else {
      Message.info("Erro ao identificar o item escolhido")
    }
  }

  // Método para obter a cor de fundo de cada item do pedido
  const getBackgroundColor = (id = null) => {
    // Verificando se uma cor possui um identificador igual
    const indexID = colors.findIndex((item) => item.used === true && item.id === id)
    if (indexID !== -1) {
      return colors[indexID].color
    }

    // Buscando uma cor não utilizada
    const indexColor = colors.findIndex((item) => item.used === false && !!id)
    if (indexColor !== -1) {
      // Atualizando a lista de cores
      colors[indexColor] = { ...colors[indexColor], used: true }
      setColors([...colors])
      return colors[indexColor].color
    }

    // Atualizando a lista de cores
    colors[colors.length - 1] = { ...colors[colors.length - 1], used: true }
    return colors[colors.length - 1].color
  }

  const setLocalCompany = async (storageCompany, companyIds, whatsappCompanyId = null) => {
    let newCompany = storageCompany
    let setCompanyLocal = false
    let startLoad = false
    let refreshOrder = true
    let companies = getLocalItem("companies", true) || []

    if (companies.length > 0 && !!newCompany && !whatsappCompanyId) {
      setCompanyLocal = true
      refreshOrder = false
      startLoad = true
    } else if (companies.length > 0 && !!newCompany && !!whatsappCompanyId && newCompany.id_company === whatsappCompanyId) {
      setCompanyLocal = true
      refreshOrder = true
      startLoad = false
    } else {
      const responseCompanies = await CompanyController.fetchAll(true, companyIds)
      companies = responseCompanies?.data || []

      if (responseCompanies.status === 200) {
        const companies = responseCompanies?.data || []

        if (!!whatsappCompanyId) {
          newCompany = companies.find((company) => company.id_company === whatsappCompanyId)
        }

        if (!newCompany) {
          newCompany = companies[0]
        }

        if (!storageCompany && !whatsappCompanyId) {
          setModalCompanies({ status: true, content: companies })
        }

        setCompanyLocal = true
        startLoad = true
      }
    }

    if (setCompanyLocal) {
      // setCompany(newCompany)
      companyRef.current = newCompany
      // setLocalItem("company", newCompany, true)
      setCompanies(companies)
      setLocalItem("companies", companies, true)
      // Obtendo conteúdo do site
      // ----------------------------------------------------------------------
      getContent(newCompany, startLoad, refreshOrder, companies)
      // ----------------------------------------------------------------------
    }
  }

  const clearOrderProgressContext = () => {
    showOrderProgressContextRef.current = { status: false, orderUid: null, scheduleOrderUid: null }
  }

  let orderItems = []
  if (!!settings.site_show_order) {
    orderItems = JSON.parse(settings.site_show_order)
  }

  let siteContent = []
  if (orderItems.length > 0) {
    orderItems.forEach((eachItem) => {
      switch (eachItem.name) {
        case "destaques":
          siteContent.push(<Highlights onClickHighlight={onClickItem} key={eachItem.id} />)
          break
        case "combos":
          siteContent.push(<Combos onClickCombo={onClickItem} key={eachItem.id} />)
          break
        case "produtos":
          siteContent.push(<Products onClickProduct={onClickItem} key={eachItem.id} />)
          break
        case "bebidas":
          siteContent.push(<Beverages onClickBeverage={onClickItem} onClickCategory={onClickItem} key={eachItem.id} />)
          break
      }
    })
  } else {
    siteContent = [
      <Highlights onClickHighlight={onClickItem} key={"_highlight"} />,
      <Combos onClickCombo={onClickItem} key={"_combos"} />,
      <Products onClickProduct={onClickItem} key={"_products"} />,
      <Beverages onClickBeverage={onClickItem} onClickCategory={onClickItem} key={"_beverages"} />,
    ]
  }

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
      <div className="error-boundary">
        <div className="error-boundary--body">
          <img alt="" src={logoImage} />
          <h6>Erro ao carregar os dados do cardápio virtual.</h6>
          <h6>Favor entrar em contato com o estabelecimento urgente!</h6>
          <div>
            <Label>Descrição do erro</Label>
            <pre>{error.message}</pre>
            <Button onClick={resetErrorBoundary}>Recarregar</Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error) => {
        console.error("ERRO AO CARREGAR O SITE => ", error)
      }}
    >
      <SocketContext.Provider value={{ socket }}>
        <UserContext.Provider value={{ user, setUser, userSelectedAddress, setUserSelectedAddress, setEditAddressRef }}>
          <MenuContext.Provider
            value={{
              menuFixed,
              addMenuOption,
              updateMenu,
              setModalSignIn,
              setModalCompanies,
              modalPaymentMethods,
              modalBeverages,
              setModalBeverages,
              modalBuilder,
              setModalBuilder,
              modalInitialAddress,
              setModalInitialAddress,
              modalOrderPreview,
              setModalOrderPreview,
              setModalMapAddress,
              setModalPaymentMethods,
              setModalCompanyContacts,
              showPaymentTypeSelector,
              setShowPaymentTypeSelector,

              menuOptions: menuOptionsRef.current,
            }}
          >
            <CompanyContext.Provider value={{ company, companies }}>
              <SettingsContext.Provider
                value={{
                  version,
                  settings,
                  combos,
                  products,
                  highlights,
                  popup,
                  beverages,
                  schedules,
                  fidelities,
                  setFidelities,
                  benefits,
                  setBenefits,
                  neighbourhoods,
                  isOutsideCoverageArea,
                  setIsOutsideCoverageArea,
                  currentStep,
                  setCurrentStep,
                  deliveryMans,
                  modality,
                  setModality,
                  onChangeUserSelectedAddress,
                }}
              >
                <OrderContext.Provider
                  value={{
                    order,
                    currentOrderCombo,
                    deliveryType,
                    finalizingOrder,
                    setDeliveryType,
                    setCurrentOrderCombo,
                    selectedPaymentMethod,
                    setSelectedPaymentMethod,
                    finishOrder,
                    preFinishOrder,
                    clearOrder,
                    setOrder: onChangeOrder,
                    scheduleInfo: scheduleInfoRef.current,
                  }}
                >
                  <TabContext.Provider
                    value={{
                      tabSelected,
                      setTabSelected: (tab) => {
                        setSessionItem("order", order, true)
                        setTabSelected(tab)
                      },
                      hideTabs,
                      setHideTabs,
                      goToOrders: goToOrdersRef.current,
                      moveToOrders: (status) => {
                        goToOrdersRef.current = status
                      },
                      goToOrderProgress: goToOrderProgressRef.current,
                      setGoToOrderProgress: (value) => {
                        goToOrderProgressRef.current = value
                      },
                      backToOrderProgress: () => {
                        goToOrderProgressRef.current = { status: false, orderUid: null, scheduleOrderUid: null }
                      },
                      showOrderProgressContext: showOrderProgressContextRef.current,
                      goToFidelity: goToFidelityRef.current,
                      moveToFidelity: () => {
                        goToFidelityRef.current = true
                      },
                      setModalPixPayment: (value) => {
                        setModalPixPayment(value)
                        modalPixPaymentContent.current = value.content
                      },
                      goToOrderProggressAfterOnlinePayment: goToOrderProggressAfterOnlinePayment,
                      updatePendingOrdersRef: updateOrders,
                      clearOrderProgressContext,
                    }}
                  >
                    <LoadingContext.Provider value={{ loading, setLoading, addressesLoading, setAddressesLoading }}>
                      <MercadoPagoContext.Provider value={{ MercadoPagoInstance }}>
                        <section
                          className={
                            "section-home " +
                            (tabSelected === "home" ? "selected-home " : "") +
                            (!!order && (order.products?.length > 0 || order.beverages?.length > 0) && tabSelected === "home"
                              ? "selected-home-resume-bar "
                              : "")
                          }
                          id={"section-home"}
                        >
                          {/*<NavBar force={updateSidebar} status={statusSidebar} />*/}
                          {errorMessage.length > 0 ? (
                            <div
                              style={{
                                padding: 20,
                                marginTop: 40,
                                color: "#FF0000",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {errorMessage.map((message, key) => (
                                <h4 key={key}>{message}</h4>
                              ))}
                            </div>
                          ) : (
                            <>
                              {(() => {
                                switch (tabSelected) {
                                  case "home": {
                                    return (
                                      <>
                                        <Header
                                          setModalCompanies={() =>
                                            setModalCompanies({
                                              status: true,
                                              content: companies,
                                            })
                                          }
                                        />
                                        <Main setModalCompanies={setModalCompanies} />
                                        {siteContent.map((eachItem) => {
                                          return eachItem
                                        })}

                                        <ModalBuilder
                                          title="Faça suas escolhas"
                                          type={modalBuilder.type}
                                          status={modalBuilder.status}
                                          content={modalBuilder.content}
                                          onLoad={modalBuilder.onLoad}
                                          onChangeOrder={onChangeOrderBuilder}
                                          onChangeAmountProduct={onChangeAmountProduct}
                                          onClose={() => setModalBuilder({ ...modalBuilder, status: false, content: null, size: null })}
                                        />
                                      </>
                                    )
                                  }
                                  case "favorites": {
                                    return null
                                  }
                                  case "cart": {
                                    console.info("cart]")
                                    break
                                  }
                                  case "profile": {
                                    return (
                                      <Profile
                                        orderId={orderSuccessId.current}
                                        loading={loading}
                                        setLoading={setLoading}
                                        userConnected={userIsConnected()}
                                      />
                                    )
                                  }
                                  default: {
                                    return <p>Carregando site</p>
                                  }
                                }
                              })()}
                            </>
                          )}

                          {!!order && (order.products?.length > 0 || order.beverages?.length > 0) && tabSelected === "home" ? (
                            <OrderResumeBar
                              title="Meu pedido"
                              icon="shopping-bag"
                              order={getTotalOrder(order, userSelectedAddress?.deliveryFee)}
                              completeOrder={order}
                              deliveryFee={!!userSelectedAddress && ![3, 2].includes(modality) ? userSelectedAddress.deliveryFee || 0 : 0}
                              setModalSignIn={setModalSignIn}
                              setTab={() => setModalOrderPreview(true)}
                              setModalPaymentMethods={setModalPaymentMethods}
                            />
                          ) : (
                            ""
                          )}
                          {tabSelected !== "cart" ? <Footer setModalContentOrder={setModalContentOrder} /> : ""}

                          {/*Modal de lojas*/}
                          <ModalCompanies
                            title="Onde você está?"
                            content={modalCompanies.content}
                            status={modalCompanies.status}
                            earlyAddress={modalCompanies.earlyAddress}
                            onUpdate={(company) => {
                              // if (!!earlyAddress) {
                              setUserSelectedAddress(null)
                              removeLocalItem("userSelectedAddress")
                              // }
                              setModalCompanies({ status: false, content: null })
                              getContent(company, false, true)
                            }}
                            handleOutsideCoverageArea={handleOutsideCoverageArea}
                          />
                          {/*Modal de Login*/}
                          <ModalSignIn
                            status={modalSignIn.status}
                            onSignIn={onSignIn}
                            recoverySettings={recoverySettings}
                            onClose={() => {
                              setModalSignIn({ status: false })
                            }}
                          />
                          {!!modalSignIn ? "" : ""}
                          {/*Modal de Bebidas*/}
                          <ModalBeverages
                            title="Bebidas"
                            content={modalBeverages.content}
                            status={modalBeverages.status}
                            data={modalBeverages.data}
                            category={modalBeverages.category}
                            onClick={onAddBeverages}
                            onClose={() => setModalBeverages({ status: false, content: null })}
                          />
                          {/*Modal para vizualização do pedido*/}
                          <OrderPreview
                            status={modalOrderPreview}
                            openOrderSchedule={onShowModalSchedule}
                            openOrderFinisher={() => setModalOrderFinisher(true)}
                            onClose={() => setModalOrderPreview(false)}
                          />
                          {/*Modal para finalizar o pedido*/}
                          <ModalOrderFinisher status={modalOrderFinisher} finishOrder={finishOrder} onClose={() => setModalOrderFinisher(false)} />
                          {/*Modal de processamento do pedido*/}
                          <ModalOrderProcess
                            status={modalOrderProcess.status}
                            resend={modalOrderProcess.resend}
                            content={modalOrderProcess.content}
                            newRequest={modalOrderProcess.newRequest}
                            scheduleInfo={modalOrderProcess.scheduleInfo}
                            setTabSelected={setTabSelected}
                            finishOrder={finishOrder}
                            insertOrder={insertOrder}
                            onClose={() => {
                              setModalOrderProcess({ status: false, resend: false, newRequest: false, scheduleInfo: null })
                            }}
                          />

                          {/*Modal para finalizar o pedido*/}
                          <ModalOrderSchedule
                            status={modalOrderSchedule}
                            onConfirmOrder={onConfirmOrderSchedule}
                            onClose={() => setModalOrderSchedule(false)}
                          />

                          <ModalSuccessOrder
                            content={modalSuccessOrder.content}
                            status={modalSuccessOrder.status}
                            onClose={(orderId) => {
                              orderSuccessId.current = orderId
                              setModalOrderFinisher(false)
                              setModalOrderSchedule(false)
                              setTabSelected("profile")
                              setModalSuccessOrder({ status: false, content: null })
                            }}
                            onBack={() => setModalSuccessOrder({ status: false, content: null })}
                          />

                          {/* Modal para buscar o endereço do cliente ao acessar a página */}
                          <ModalInitialAddress
                            status={modalInitialAddress.status}
                            content={modalInitialAddress.content}
                            onPlaceSelected={onPlaceSelected}
                            onShowMap={() => setModalMapAddress({ status: true, location: {} })}
                            onClose={() => setModalInitialAddress({ status: false, title: "", content: null })}
                            setModalMinimapAddress={setModalMinimapAddress}
                          />
                          <ModalMapAddress
                            status={modalMapAddress.status}
                            location={modalMapAddress.location}
                            onConfirmLocation={onConfirmLocation}
                            onClose={() => setModalMapAddress({ status: false, location: {} })}
                          />
                          <ModalNumberAddress
                            status={modalNumberAddress.status}
                            address={modalNumberAddress.address}
                            onConfirmNumber={onConfirmNumber}
                            onClose={() => setModalNumberAddress({ status: false, address: {} })}
                          />
                          <ModalFinalAddress
                            status={modalFinalAddress.status}
                            address={modalFinalAddress.address}
                            editNeighbourhood={modalFinalAddress.editNeighbourhood}
                            onConfirmAddress={onConfirmAddress}
                            origin={modalFinalAddress.origin}
                            onClose={() => setModalFinalAddress({ status: false, address: {} })}
                          />

                          {/* Modal para os contatos do estabelecimento.*/}
                          {modalCompanyContacts ? <ModalCompanyContacts onClose={() => setModalCompanyContacts(false)} /> : ""}

                          {/* Modal do popup de mensagem */}
                          <ModalPopup status={modalPopup.status} content={popup} onClose={() => setModalPopup({ status: false })} />

                          {/* Modal de bloqueio devido a versão incorreta*/}
                          <ModalBlocked status={modalBlockByOutdatedVersion.status} content={modalBlockByOutdatedVersion.content} />

                          {loading || addressesLoading || forceLoading ? (
                            <FullContentLoading loading={true} type="fade" title="Aguarde, estamos carregando as informações..." />
                          ) : (
                            ""
                          )}

                          <ModalAddressNotFound
                            status={modalAddressNotFound.status}
                            title={modalAddressNotFound.title}
                            companies={modalAddressNotFound.companies}
                            onUpdate={(company) => {
                              setUserSelectedAddress(null)
                              removeLocalItem("userSelectedAddress")
                              setModalInitialAddress({ status: false, title: "", content: null })
                              setModalMapAddress({ status: false, location: {} })
                              setModalAddressNotFound({ status: false, title: "", companies: [] })
                              getContent(company, false, true)
                            }}
                            // onConfirmAddress={onConfirmAddress}
                            onClose={() => setModalAddressNotFound({ status: false, title: "", companies: [] })}
                          />

                          <ModalClientAddressNotFound
                            status={modalClientAddressNotFound.status}
                            address={modalClientAddressNotFound.address}
                            onClose={() => {
                              setModalClientAddressNotFound({ status: false, address: null })
                            }}
                            openAddressModal={(address) => {
                              setModalClientAddressNotFound({ status: false, address: null })

                              setModalFinalAddress({ status: true, address, editNeighbourhood: false, origin: "fromMap" })
                            }}
                          />

                          <ModalPixPayment
                            status={modalPixPayment.status}
                            content={modalPixPayment.content}
                            orderProgressRef={goToOrderProgressRef.current}
                            onClose={() => {
                              clearOrder()
                              setModalPixPayment({ status: false, content: null })
                              setTabSelected("profile")
                              goToOrdersRef.current = true
                            }}
                          />

                          {/* Modal para o componente do minimap do mapbox */}
                          <ModalMinimapAddress
                            status={modalMinimapAddress.status}
                            content={modalMinimapAddress.content}
                            onClose={() => {
                              setModalMinimapAddress({ status: false, content: null })
                            }}
                            onConfirmLocation={onConfirmLocation}
                            setModalMinimapAddress={setModalMinimapAddress}
                          />
                          {!!company.id_company && (
                            <FacebookPixelManager
                              key={company.id_company}
                              companyId={company.id_company}
                              activePixelId={activePixelId}
                              setActivePixelId={setActivePixelId}
                            />
                          )}

                          {/*/!*Componente de mensagem de alerta*!/*/}
                          {/*<Alert offset={0} stack={{ limit: 3, spacing: 10 }} contentTemplate={AlertTemplate} />*/}
                        </section>
                        <Toaster containerStyle={{ zIndex: 9999 }} />
                      </MercadoPagoContext.Provider>
                    </LoadingContext.Provider>
                  </TabContext.Provider>
                </OrderContext.Provider>
              </SettingsContext.Provider>
            </CompanyContext.Provider>
          </MenuContext.Provider>
        </UserContext.Provider>
      </SocketContext.Provider>
    </ErrorBoundary>
  )
}

export default App
